const HR_RESOURCE = {
  translation: {
    loading: "Učitavam",
    search: {
      cancel: "Otkazati",
      noResults:
        "Nema pronađenih rezultata pretraživanja, ali one u nastavku svakako vrijedi pogledati.",
    },
    menu: {
      switchProfiles: "Promjena profila",
      settings: "Postavke",
      close: "Zatvoriti",
      logOut: "Odjavite se",
    },
    button: {
      apply: "Primijeniti",
      continue: "Nastaviti",
      cancel: "Otkazati",
      confirm: "Potvrditi",
      play: "Igrati",
      addToWatchList: "Dodaj na popis gledanja",
      removeFromWatchList: "Ukloni s popisa ggledanja",
      moreInfo: "Više informacija",
      resume: "Sažetak",
      close: "Zatvoriti",
      discard: "Odbaci",
      save: "Spremi",
    },
    login: {
      header: "Prijava na račun",
      title: "Dobrodošli u",
      subTitle:
        "Izvucite maksimum iz svojih pretplata na streamingu uz Disney, Amazon i druge.",
      text: "Sve što želite gledati na jednom mjestu.",
      button: "Prijavite se",
      placeholder: {
        username: "Korisničko ime",
        password: "Zaporka",
      },
      errors: {
        email: {
          noValid: "E-pošta mora biti valjana e-pošta",
          required: "E-pošta je obvezno polje",
        },
        password: {
          required: "Zaporka je obvezno polje",
        },
        wrongPass: {
          first:
            "Ups, to nije bila prava zaporka. Pokušajte ponovno ili kliknite donji gumb ",
          second: "“Zaboravili ste zaporku”",
          third: "",
        },
      },
    },
    forgotPassword: {
      message:
        'Ne brinite Ponovno postavljanje zaporke je jednostavno. Samo unesite svoju adresu e-pošte ispod i kliknite "POŠALJI"',
      button: "POŠALJI",
      placeholder: {
        username: "Korisničko ime",
      },
      errors: {
        email: {
          required: "E-pošta je obvezno polje",
        },
        emailNotRegister: "E-mail adresa nije registrirana!",
        emailSent: "E-pošta za resetiranje zaporke poslana!",
      },
    },
    profilesPage: {
      title: "Tko gleda?",
      subTitle:
        "Svi u vašem automobilu mogu imati personalizirano TV iskustvo.",
      newProfile: "+ Dodaj novi profil",
    },
    newProfile: {
      title: "Novi profil",
      description: "Dodajte profil za drugu osobu koja gleda ScreenHits TV",
      isKid: "Dijete?",
      loading: "Učitavam",
      profileCreated: "Profil je uspješno kreiran!",
      profileSelection: "Odabir profila",
    },
    settings: {
      accountDetails: {
        primary: "Pojedinosti o računu",
        secondary: "Uredite ime vlasnika računa i e-poštu.",
      },
      manageProfiles: {
        primary: "Upravljanje profilima",
        secondary: "Uredi detalje profila",
        edit: "Uredi profil",
      },
      parentControl: {
        primary: "Roditeljska kontrola",
        secondary: "Stvori PIN / Promijeni PIN",
        instruction: {
          primary: "Kreiraj (ažuriraj) PIN za Roditeljsku kontrolu",
          secondary: "(Molimo unesite 4 znamenke dok je tipkovnica otvorena)",
        },
        recoverPin: "Ponovno postavi PIN",
        pinSentTo: "PIN poslan na",
        unlock: "Otključaj",
        pinUndefined: "PIN poslan na e-mail adresu"
      },
      membershipStatus: {
        primary: "Status članstva",
        secondary: "Aktivan",
      },
      deactivateAccount: {
        primary: "Deaktiviraj račun",
        secondary: "Zatvorite svoj račun i odvojite svoje uređaje.",
        enterPass: "Unesite svoju lozinku",
        discard: "Odbaci promjene",
      },
      activeLanguage: "Aktivni jezik",
      defaultLanguage: "Jezik sustava",
      changeDefaultLanguage: "Promijeni zadani jezik",
      privacyPolicy: {
        text: "Politika privatnosti",
      },
      settings: "Postavke",
      lang: {
        en: "Engleski",
        it: "Talijanski",
        de: "Njemački",
        esar: "Španjolski(Argentina)",
        fr: "Francuski",
        ja: "Japanski",
        ro: "Rumunjski",
        da: "Danski",
        nl: "Nizozemski",
        ca: "Katalonski",
        lb: "Luksemburški",
        no: "Norveški",
        ptbr: "Portugalski(Brazilski)",
        zhcn: "Kineski",
        ms: "Malajski",
        ta: "Tamilski",
        bs: "Bosanski",
        bg: "Bugarski",
        hr: "Hrvatski",
        tr: "Turski",
        cs: "Češki",
        et: "Estonski",
        fi: "Finski",
        el: "Grčki",
        hu: "Mađarski",
        ga: "Irski",
        is: "Islandski",
        lv: "Latvijski",
        lt: "Litvanski",
        mt: "Malteški",
        pl: "Poljski",
        pt: "Portugalski(Portugal)",
        ru: "Ruski",
        sr: "Srpski",
        sk: "Slovački",
        sl: "Slovenski",
        sv: "Švedski",
        mi: "Maorski",
        ar: "Arapski",
        af: "Afrikanerski",
        zhtw: "Kineski(Tradicionalni)",
        es: "Španjolski (Španjolska)",
        ko: "Korejski",
        enus: "English (United States)",
        deat: "German (Austria)",
        dech: "German (Switzerland)",
        deli: "German (Liechtenstein)",
        dede: "German (Deutschland)",
        enca: "English (Canada)",
        engb: "English",
        enie: "English (Ireland)",
        ennz: "English (New Zealand)",
        enza: "English (South Africa)",
        esmx: "Spanish (Mexico)",
        frca: "French (Canada)",
        frch: "French (Switzerland)",
        itch: "Italian (Switzerland)",
        jajp: "Japanese (Japan)",
        kokr: "Korean (South Korea)",
        nb: "Norwegian (Bokmål)",
        nn: "Norwegian (Nynorsk)",
        zhch: "Chinese (PRC)",
      },
      language: {
        primary: "Jezik",
      },
    },
    show: {
      more: " Prikaži više",
      less: " Prikaži manje",
    },
    showPage: {
      seasons: "Sezone",
      cast: "Glumačka ekipa",
      producers: "Producenti",
      genre: "Žanr",
      directedBy: "Redatelj",
      season: "Sezona",
    },
    accountDetails: {
      firstName: "Ime",
      lastName: "Prezime",
      email: "E-pošta",
      password: "Zaporka",
      changePassword: "Promjena zaporke",
      forgotPassword: "Zaboravili ste zaporku",
    },
    childPinPopup: {
      title: "Zaštita djece",
      subTitle: "Izradite (ažurirajte) PIN za zaštitu djece",
      pinUpdated: "PIN je uspješno ažuriran",
      pinIsIncorrect: "PIN nije točan",
    },
    deactivateAccount: {
      title: "Jeste li sigurni da želite deaktivirati svoj račun?",
      description:
        "Nakon što to učinite, bit ćete odjavljeni sa svih usluga i više nećete moći vidjeti sadržaj u svom automobilu",
      deactivateButton: "Deaktivirajte račun",
    },
    servicesPage: {
      add: "Dodajte uslugama",
      remove: "Uklonite iz usluga",
      overflow: {
        title: "Odabrani paketi",
        subtitle: "Odaberite svoje usluge",
        description: "Dodajte svaku uslugu koju koristite u svoj vodič.",
      },
    },
    manageProfile: {
      text: "Upravljanje profilima",
    },
    watchlist: {
      title:
        "Ovdje su svi filmovi i emisije koje ste dodali na svoj popis gledanja",
      noTitles: "Trenutačno nemate naslova na svom popisu gledanja",
      addText:
        "Kako biste dodali, kliknite na + Dodajte na popis gledanja na bilo kojoj emisiji kojoj se želite vratiti i pogledati je kasnije.",
      subtext: {
        start: "Za dodavanje kliknite na ",
        toWatchList: "Dodajte na popis gledanja",
        end: "ili bilo koju emisiju kojoj biste se htjeli vratiti i pogledati kasnije.",
      },
    },
    streamingService: {
      title: "Odaberite usluge strujanja",
      subTitle:
        "Dodajte svoje odabrane usluge kako bi se one pojavile u vašem TV vodiču",
      streamingServices: "Usluge strujanja",
    },
    profileUpdate: {
      backToSettings: "Natrag na postavke",
      updatedSuccess: "Korisnik je uspješno ažuriran",
      apply: "Primijenite",
      wrongPassword: "Pogrešna zaporka",
    },
    changePasswordPage: {
      backToSettings: "Natrag na postavke",
      currentPass: "Trenutna zaporka",
      newPass: "Nova zaporka",
      confirmPass: "Potvrdi zaporku",
      change: "Promijenite",
      changeUserInfo: "Promijenite podatke o korisniku",
      passwordDoNotMatch: "Zaporke se ne slažu ili su nevažeće",
      passwordUpdated: "Zaporka je uspješno ažurirana",
    },
    noServices: {
      title: "Trenutno nemate nijednu uslugu u svom TV vodiču",
      text: "Kako biste maksimalno iskoristili ScreenHits TV, morate dodati usluge. Kliknite ovdje da dodate davatelje usluga strujanja na koje ste već pretplaćeni bez dodatnih troškova. A ako trenutačno niste pretplaćeni ni na jednu uslugu strujanja, dodajte naš BESPLATNI paket ovdje i počnite otkrivati sjajan sadržaj od BBC-a, ITV-a, My5-a, Channel 4-a, UKTVa i STV-a.",
      button: "Dodajte usluge",
    },
    deleteProfile: {
      cantDeleteProfile: "Ne možete izbrisati svoj trenutni profil",
      areYouSure: "Jeste li sigurni da želite izbrisati ovaj profil?",
      delete: "Izbriši profil",
    },
    notFoundPage: {
      title: "Nešto je pošlo po zlu",
      text: "Ipak, ne brinite, sve je u redu. Vratit ćemo se s ovom stranicom.",
      home: "Početna stranica",
    },
    noInternet: {
      title: "Nema internetske veze",
      description:
        "Nemojte brinuti, sve je u redu. Vratit ćemo se s ovom stranicom.",
      button: "Ponovno učitaj",
    },
  },
};

export default HR_RESOURCE;
