export const PROFILE_PFX = "/profile";

const appLinks = {
  home: {
    name: "Home",
    link: "/",
  },
  login: {
    name: "Sign In",
    link: "/login",
  },
  loginWithQR: {
    name: "QR Login",
    link: "/login/qr-code",
  },
  loginWithQRCinemo: {
    name: "QR Login For Cinemo Test",
    link: "/login/qr-code/cinemo",
  },
  forgotPassword: {
    name: "Forgot password",
    link: "/forgot-password",
  },
  profiles: {
    name: "Browse profiles",
    link: "/profiles",
  },
  changePassword: {
    name: "Change Password",
    link: `${PROFILE_PFX}/change-password`,
  },
  profilesManage: {
    name: "Manage Profiles",
    link: `${PROFILE_PFX}/profiles/manage`,
  },
  profilesManageUpdate: {
    name: "Update Profile",
    link: `${PROFILE_PFX}/profiles/manage/:id`,
    asLink: (id: number): string => `${PROFILE_PFX}/profiles/manage/${id}`,
  },
  createNewProfile: {
    name: "Create new profile",
    link: "/profiles/new",
  },
  register: {
    name: "Registration",
    link: "/registration",
  },
  logout: {
    name: "Log out",
    link: "/logout",
  },
  profile: {
    name: "Profile",
    link: "/profile",
  },
  discover: {
    name: "Discover",
    link: `${PROFILE_PFX}/discover`,
  },
  watchlist: {
    name: "Watchlist",
    link: `${PROFILE_PFX}/watchlist`,
  },
  tvGuide: {
    name: "TV Guide",
    link: `${PROFILE_PFX}/guide`,
  },
  tvFriends: {
    name: "TV Friends",
    link: `${PROFILE_PFX}/tvfriends`,
  },
  liveTV: {
    name: "Live TV",
    link: `${PROFILE_PFX}/live`,
  },
  genres: {
    name: "Other Genres",
    link: `${PROFILE_PFX}/genres`,
  },
  settings: {
    name: "Settings",
    link: `${PROFILE_PFX}/settings`,
  },
  search: {
    name: "Search",
    link: `${PROFILE_PFX}/search`,
  },
  streamingServices: {
    name: "Streaming Services",
    link: `${PROFILE_PFX}/services`,
  },
  accountUpdate: {
    name: "Update Account",
    link: `${PROFILE_PFX}/update/account`,
  },
  singleMedia: {
    name: "Media",
    link: "media/:id",
    asLink: (id: number | string, channelId?: number): string =>
      `${PROFILE_PFX}/media/${id}${channelId ? `?channelId=${channelId}` : ""}`,
  },
  player: {
    name: "Video Player",
    link: `${PROFILE_PFX}/player/:id`,
    asLink: (id: number | undefined): string => `${PROFILE_PFX}/player/${id}`,
  },
  otherGenres: {
    name: "Other Genres",
    link: `${PROFILE_PFX}/other-genres`,
  },

  uidError: {
    name: "UID Error",
    link: "/error",
  },
};

const appPages = [
  { ...appLinks.login },
  { ...appLinks.loginWithQR },
  { ...appLinks.register },
  { ...appLinks.forgotPassword },
  { ...appLinks.profiles },
  { ...appLinks.tvGuide },
  { ...appLinks.discover },
  { ...appLinks.watchlist },
  { ...appLinks.liveTV },
  { ...appLinks.tvFriends },
  { ...appLinks.search },
  { ...appLinks.settings },
  { ...appLinks.genres },
  { ...appLinks.streamingServices },
];

export { appPages, appLinks };
