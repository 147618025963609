const FR_RESOURCE = {
  translation: {
    loading: "Chargement",
    search: {
      cancel: "Annuler",
      noResults:
        "Aucun résultat de recherche, mais vous devriez jeter un coup d’œil à ceux ci-dessous.",
    },
    menu: {
      switchProfiles: "Changer de profil",
      settings: "Paramètres",
      close: "Fermer",
      logOut: "Déconnexion",
    },
    button: {
      apply: "Appliquer",
      continue: "Continuer",
      cancel: "Annuler",
      confirm: "Confirmer",
      play: "Voir",
      addToWatchList: "Ajouter à la watchlist",
      removeFromWatchList: "Retirer de la watchlist",
      moreInfo: "Plus d’infos",
      resume: "Reprendre",
      close: "Fermer",
      discard: "Annuler",
      save: "Enregistrer",
    },
    login: {
      header: "Connexion au compte",
      title: "Bienvenue à",
      subTitle:
        "Tirez le meilleur parti de vos abonnements de streaming avec Netflix, Disney, Amazon et bien plus encore.",
      text: "Tout ce que vous voulez regarder en un seul endroit.",
      button: "Connectez-vous",
      placeholder: {
        username: "Nom d’utilisateur",
        password: "Mot de passe",
      },
      errors: {
        email: {
          noValid: "L’adresse e-mail saisie doit être valide",
          required: "L’e-mail est un champ obligatoire",
        },
        password: {
          required: "Le mot de passe est un champ obligatoire",
        },
        wrongPass: {
          first:
            "Oups, ce n’est pas le bon mot de passe. Réessayez ou cliquez sur le bouton ",
          second: "“Mot de passe oublié” ",
          third: "ci-dessous.",
        },
      },
    },
    forgotPassword: {
      message:
        'Ne vous inquiétez pas. Réinitialiser votre mot de passe est facile. Il suffit de saisir votre adresse e-mail ci-dessous et de cliquer sur "ENVOYER"',
      button: "ENVOYER",
      placeholder: {
        username: "Nom d’utilisateur",
      },
      errors: {
        email: {
          required: "L’e-mail est un champ obligatoire",
        },
        emailNotRegister: "Adresse e-mail non enregistrée!",
        emailSent: "E-mail de réinitialisation du mot de passe envoyé!",
      },
    },
    profilesPage: {
      title: "Qui regarde?",
      subTitle:
        "Chacun dans votre voiture peut avoir une expérience TV personnalisée.",
      newProfile: "+ Ajouter un nouveau profil",
    },
    newProfile: {
      title: "Nouveau profil",
      description: "Ajouter un profil pour une autre personne regardant ScreenHits TV",
      isKid: "Enfant ?",
      loading: "Chargement",
      profileCreated: "Profil créé avec succès!",
      profileSelection: "Sélection du profil",
    },
    settings: {
      accountDetails: {
        primary: "Détails du compte",
        secondary: "Éditer le nom du détenteur du compte et l’adresse e-mail.",
      },
      manageProfiles: {
        primary: "Gérer les profils",
        secondary: "Modifier les détails du profil",
        edit: "Modifier le profil",
      },
      parentControl: {
        primary: "Contrôle parental",
        secondary: "Créer PIN / Changer PIN",
        instruction: {
          primary:
            "Créer (mettre à jour) le code PIN pour le Contrôle Parental",
          secondary:
            "(Veuillez entrer 4 chiffres pendant que le clavier est ouvert)",
        },
        recoverPin: "Réinitialiser le PIN",
        pinSentTo: "PIN envoyé à",
        unlock: "Déverrouiller",
        pinUndefined: "PIN envoyé à l'adresse e-mail"
      },
      membershipStatus: {
        primary: "Statut de membre",
        secondary: "Actif",
      },
      deactivateAccount: {
        primary: "Désactiver le compte",
        secondary: "Fermez votre compte et déconnectez vos appareils.",
        enterPass: "Entrez votre mot de passe",
        discard: "Annuler les modifications",
      },
      activeLanguage: "Langue active",
      defaultLanguage: "Langue du système",
      changeDefaultLanguage: "Changer la langue par défaut",
      privacyPolicy: {
        text: "Politique de confidentialité",
      },
      settings: "Paramètres",
      lang: {
        en: "Anglais",
        it: "Italien",
        de: "Allemand",
        esar: "Espagnol(Argentine)",
        fr: "Français",
        ja: "Japonais",
        ro: "Roumain",
        da: "Danois",
        nl: "Néerlandais",
        ca: "Catalan",
        lb: " Luxembourgeois",
        no: "Norvégien",
        ptbr: "Portugais(Brésil)",
        zhcn: "Chinois",
        ms: "Malais",
        ta: "Tamoul",
        bs: "Bosniaque",
        bg: "Bulgare",
        hr: "Croate",
        tr: "Turc",
        cs: "Tchèque",
        et: "Estonien",
        fi: "Finlandais",
        el: "Grec",
        hu: "Hongrois",
        ga: "Irlandais",
        is: "Islandais",
        lv: "Letton",
        lt: "Lituanien",
        mt: "Maltais",
        pl: "Polonais",
        pt: "Portugais(Portugal)",
        ru: "Russe",
        sr: "Serbe",
        sk: "Slovaque",
        sl: "Slovène",
        sv: "Suédois",
        mi: "Māori",
        ar: "Arabe",
        af: "Afrikaans",
        zhtw: "Chinois (Traditionnel)",
        es: "Espagnol(Espagne)",
        ko: "Coréen",
        enus: "English (United States)",
        deat: "German (Austria)",
        dech: "German (Switzerland)",
        deli: "German (Liechtenstein)",
        dede: "German (Deutschland)",
        enca: "English (Canada)",
        engb: "English",
        enie: "English (Ireland)",
        ennz: "English (New Zealand)",
        enza: "English (South Africa)",
        esmx: "Spanish (Mexico)",
        frca: "French (Canada)",
        frch: "French (Switzerland)",
        itch: "Italian (Switzerland)",
        jajp: "Japanese (Japan)",
        kokr: "Korean (South Korea)",
        nb: "Norwegian (Bokmål)",
        nn: "Norwegian (Nynorsk)",
        zhch: "Chinese (PRC)",
      },
      language: {
        primary: "Langue",
      },
    },
    show: {
      more: " Afficher plus",
      less: " Afficher moins",
    },
    showPage: {
      seasons: "Saisons",
      cast: "Casting",
      producers: "Producteurs",
      genre: "Genre",
      directedBy: "Réalisé par",
      season: "Saison",
    },
    accountDetails: {
      firstName: "Prénom",
      lastName: "Nom de famille",
      email: "E-mail",
      password: "Mot de passe",
      changePassword: "Changer le mot de passe",
      forgotPassword: "Mot de passe oublié",
    },
    childPinPopup: {
      title: "Protection des enfants",
      subTitle: "Créer (actualiser) le code PIN pour la protection des enfants",
      pinUpdated: "Le code PIN a été mis à jour avec succès",
      pinIsIncorrect: "Le code PIN est incorrect",
    },
    deactivateAccount: {
      title: "Voulez-vous vraiment désactiver votre compte?",
      description:
        "Après sa désactivation, vous serez déconnecté(e) de tous les services et ne pourrez plus visualiser de contenu dans votre voiture",
      deactivateButton: "Désactiver le compte",
    },
    servicesPage: {
      add: "Ajouter aux services",
      remove: "Retirer des services",
      overflow: {
        title: "Packs sélectionnés",
        subtitle: "Sélectionnez vos services",
        description: "Ajoutez chaque service que vous utilisez à votre guide.",
      },
    },
    manageProfile: {
      text: "Gérer les profils",
    },
    watchlist: {
      title:
        "Voici tous les films et séries que vous avez ajoutés à votre watchlist.",
      noTitles: "Votre watchlist ne contient actuellement aucun titre",
      addText:
        "Pour ajouter, veuillez cliquer sur + Ajouter à la watchlist pour tous les titres que vous souhaitez visionner plus tard.",
      subtext: {
        start: "Ajoutez en cliquant sur",
        toWatchList: "Ajouter à la watchlist",
        end: "pour tous les titres que vous souhaitez visionner plus tard.",
      },
    },
    streamingService: {
      title: "Choisir des services de streaming",
      subTitle:
        "Ajoutez les services sélectionnés pour qu’ils apparaissent dans votre Guide TV",
      streamingServices: "Services de Streaming",
    },
    profileUpdate: {
      backToSettings: "Retour aux paramètres",
      updatedSuccess: "L’utilisateur a été mis à jour avec succès",
      apply: "Appliquer",
      wrongPassword: "Mot de passe erroné",
    },
    changePasswordPage: {
      backToSettings: "Retour aux paramètres",
      currentPass: "Mot de passe actuel",
      newPass: "Nouveau mot de passe",
      confirmPass: "Confirmer le mot de passe",
      change: "Modifier",
      changeUserInfo: "Modifier les infos d’utilisateur",
      passwordDoNotMatch:
        "Les mots de passe ne correspondent pas ou sont invalides",
      passwordUpdated: "Le mot de passe a été mis à jour avec succès",
    },
    noServices: {
      title: "Vous n’avez actuellement aucun service dans votre Guide TV",
      text: "Afin de tirer le meilleur parti de ScreenHits TV, vous devez ajouter des services. Cliquez ici pour ajouter gratuitement ceux auxquels vous êtes déjà abonné(e). Et si vous n’avez pas encore d’abonnement à un service de streaming, ajoutez notre pack GRATUIT ici et explorez le contenu de la BBC, d’ITV, de My5, de Channel 4, d’UKTV et de STV.",
      button: "Ajouter des services",
    },
    deleteProfile: {
      cantDeleteProfile: "Vous ne pouvez pas supprimer votre profil actuel",
      areYouSure: "Voulez-vous vraiment supprimer ce profil?",
      delete: "Supprimer le profil",
    },
    notFoundPage: {
      title: "Un problème est survenu",
      text: "Ne vous inquiétez pas cependant, tout va bien. Nous reviendrons avec cette page.",
      home: "Accueil",
    },
    noInternet: {
      title: "Pas de connexion Internet",
      description:
        "Ne vous inquiétez pas, tout va bien. Nous reviendrons avec cette page.",
      button: "Recharger",
    },
  },
};

export default FR_RESOURCE;
