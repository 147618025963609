const BS_RESOURCE = {
  translation: {
    loading: "Učitavanje",
    search: {
      cancel: "Otkaži",
      noResults:
        "Nisu pronađeni rezultati pretraživanja, ali njih u nastavku svakako vrijedi pogledati.",
    },
    menu: {
      switchProfiles: "Promijeni profil",
      settings: "Postavke",
      close: "Zatvori",
      logOut: "Odjavi se",
    },
    button: {
      apply: "Prijavi se",
      continue: "Nastavi",
      cancel: "Otkaži",
      confirm: "Potvrdi",
      play: "Igraj",
      addToWatchList: "Dodaj na listu za gledanje",
      removeFromWatchList: "Ukloni sa liste za gledanje",
      moreInfo: "Više informacija",
      resume: "Sažetak",
      close: "Zatvori",
      discard: "Odbaciti",
      save: "Sačuvaj",
    },
    login: {
      header: "Prijava na račun",
      title: "Dobrodošli",
      subTitle:
        "Iskoristi sve svoje pretplate na streaming uz Disney, Amazon i još mnogo toga.",
      text: "Sve što želite da gledate na jednom mestu.",
      button: "Prijavi se",
      placeholder: {
        username: "Korisničko ime",
        password: "Lozinka",
      },
      errors: {
        email: {
          noValid: "E-pošta mora biti važeća e-adresa",
          required: "Email je obavezno polje",
        },
        password: {
          required: "Lozinka je obavezno polje",
        },
        wrongPass: {
          first:
            "Ups, to nije bila prava lozinka. Molimo pokušajte ponovo ili kliknite na ",
          second: "“Zaboravili ste lozinku” ",
          third: "tipku ispod.",
        },
      },
    },
    forgotPassword: {
      message:
        'Ne brini. Poništavanje lozinke je jednostavno. Samo unesite svoju email adresu ispod i kliknite na "POŠALJI"',
      button: "POŠALJI",
      placeholder: {
        username: "Korisničko ime",
      },
      errors: {
        email: {
          required: "Email je obavezno polje",
        },
        emailNotRegister: "Email adresa nije registrovana!",
        emailSent: "E-mail za poništavanje lozinke je poslan!",
      },
    },
    profilesPage: {
      title: "Ko gleda?",
      subTitle:
        "Svi u vašem automobilu mogu imati personalizirano TV iskustvo.",
      newProfile: "+ Dodaj novi profil",
    },
    newProfile: {
      title: "Novi profil",
      description: "Dodajte profil za drugu osobu koja gleda ScreenHits TV",
      isKid: "Dijete?",
      loading: "Učitavanje",
      profileCreated: "Profil je uspješno kreiran!",
      profileSelection: "Odabir profila",
    },
    settings: {
      accountDetails: {
        primary: "Detalji računa",
        secondary: "Uredite ime vlasnika računa i email.",
      },
      manageProfiles: {
        primary: "Upravljanje profilima",
        secondary: "Uredi detalje profila",
        edit: "Uredi profil",
      },
      parentControl: {
        primary: "Roditeljska kontrola",
        secondary: "Kreiraj PIN / Promijeni PIN",
        instruction: {
          primary: "Kreirajte (ažurirajte) PIN za Roditeljska kontrola",
          secondary: "(Molimo unesite 4 cifre dok je tastatura otvorena)",
        },
        recoverPin: "Resetuj PIN",
        pinSentTo: "PIN poslan na",
        unlock: "Otključaj",
        pinUndefined: "PIN poslan na email adresu"
      },
      membershipStatus: {
        primary: "Status članstva",
        secondary: "Aktivan",
      },
      deactivateAccount: {
        primary: "Deaktiviraj račun",
        secondary: "Zatvorite svoj račun i odvojite svoje uređaje.",
        enterPass: "Unesite svoju lozinku",
        discard: "Obriši profil",
      },
      activeLanguage: "Aktivni jezik",
      defaultLanguage: "Sistemski jezik",
      changeDefaultLanguage: "Promijeni zadani jezik",
      privacyPolicy: {
        text: "Politika privatnosti",
      },
      settings: "Postavke",
      lang: {
        en: "Engleski",
        it: "Talijanski",
        de: "Njemački",
        esar: "Španski(Argentinski)",
        fr: "Francuski",
        ja: "Japanski",
        ro: "Rumunski",
        da: "Danski",
        nl: "Holandski ",
        ca: "Katalonski",
        lb: "Luksemburški",
        no: "Norveški",
        ptbr: "Portugalski(Brazil)",
        zhcn: "Kineski",
        ms: "Malajski",
        ta: "Tamilski",
        ba: "Bosanski",
        bg: "Bugarski",
        hr: "Hrvatski",
        tr: "Turski",
        cs: "Češki",
        et: "Estonski",
        fi: "Finski",
        el: "Grčki",
        hu: "Mađarski",
        ga: "Irski",
        is: "Islandski",
        lv: "Latvijski",
        lt: "Litvanski",
        mt: "Malteški",
        pl: "Poljski",
        pt: "Portugalski(Portugal)",
        ru: "Ruski",
        sr: "Srpski",
        sk: "Slovak",
        sl: "Slovenski",
        sv: "Švedski",
        mi: "Māori",
        ar: "Arapski",
        af: "Afrikaans",
        zhtw: "Kineski(Tradicionalni) ",
        es: "Španski (Španija)",
        ko: "Korejski",
        enus: "Engleski (Sjedinjene Američke Države)",
        deat: "Njemački (Austrija)",
        dech: "Njemački (Švicarska)",
        deli: "Njemački (Lihtenštajn)",
        dede: "Njemački (Njemačka)",
        enca: "Engleski (Kanada)",
        engb: "Engleski",
        enie: "Engleski (Irska)",
        ennz: "Engleski (Novi Zeland)",
        enza: "Engleski (Južna Afrika)",
        esmx: "Španski (Meksiko)",
        frca: "Francuski (Kanada)",
        frch: "Francuski (Švicarska)",
        itch: "Italijanski (Švicarska)",
        jajp: "Japanski (Japan)",
        kokr: "Korejski (Južna Koreja)",
        nb: "Norveški (Bokmål)",
        nn: "Norveški (Nynorsk)",
        zhch: "Kineski (NR Kina)",
      },
      language: {
        primary: "Jezik",
      },
    },
    show: {
      more: " Pokazati više",
      less: " Prikaži manje",
    },
    showPage: {
      seasons: "Godišnja doba",
      cast: "Cast",
      producers: "Proizvođači",
      genre: "Žanr",
      directedBy: "Režija:",
      season: "Sezona",
    },
    accountDetails: {
      firstName: "Ime",
      lastName: "Prezime",
      email: "Email",
      password: "Lozinka",
      changePassword: "Promijeni lozinku",
      forgotPassword: "Zaboravili ste lozinku",
    },
    childPinPopup: {
      title: "Zaštita djece",
      subTitle: "Kreirajte (ažurirajte) PIN za zaštitu djece",
      pinUpdated: "PIN je uspješno ažuriran",
      pinIsIncorrect: "PIN je netačan",
    },
    deactivateAccount: {
      title: "Jeste li sigurni da želite deaktivirati svoj račun?",
      description:
        "Kada to učinite, bit ćete odjavljeni sa svih usluga i više nećete moći gledati sadržaj u svom automobilu",
      deactivateButton: "Deaktivirati nalog",
    },
    servicesPage: {
      add: "Dodaj uslugama",
      remove: "Ukloni iz usluga",
      overflow: {
        title: "Odabrani paketi",
        subtitle: "Odaberite svoje usluge",
        description: "Dodajte svaku uslugu koju koristite u svoj vodič.",
      },
    },
    manageProfile: {
      text: "Upravljanje profilima",
    },
    watchlist: {
      title:
        "Ovdje su svi filmovi i emisije koje ste dodali na svoju listu za gledanje",
      noTitles: "Trenutno nemate nijedan naslov na Vašoj listi za praćenje",
      addText:
        "Da dodate, kliknite na + Dodaj na listu za gledanje na bilo kojoj emisiji na koju biste se željeli vratiti i gledati kasnije.",
      subtext: {
        start: "Za dodavanje, kliknite na ",
        toWatchList: "Dodaj na listu za gledanje",
        end: "ili bilo koju emisiju kojoj biste se htjeli vratiti i pogledati kasnije.",
      },
    },
    streamingService: {
      title: "Odaberite Streaming Services",
      subTitle: "Dodajte svoje odabrane usluge da se pojave u vašem TV vodiču",
      streamingServices: "Usluge Striminga",
    },
    profileUpdate: {
      backToSettings: "Povratak na postavke",
      updatedSuccess: "Korisnik je uspješno ažuriran",
      apply: "Prijavite se",
      wrongPassword: "Pogrešna lozinka",
    },
    changePasswordPage: {
      backToSettings: "Povratak na postavke",
      currentPass: "Trenutna šifra",
      newPass: "Nova šifra",
      confirmPass: "Potvrdi šifru",
      change: "Promjena",
      changeUserInfo: "Promjena korisničkih informacija",
      passwordDoNotMatch: "Lozinke se ne podudaraju ili su nevažeće",
      passwordUpdated: "Lozinka je uspješno ažurirana",
    },
    noServices: {
      title: "Trenutno nemate nijednu uslugu u svom TV vodiču",
      text: "Da biste maksimalno iskoristili ScreenHits TV, morate dodati usluge. Molimo kliknite ovdje da dodate streamere na koje ste se već pretplatili bez dodatnih troškova. A ako se trenutno niste pretplatili ni na jedan streaming servis, dodajte naš BESPLATNI paket i počnite otkrivati sjajan sadržaj iz programa BBC, ITV, My5, Channel 4, UKTV i STV.",
      button: "Dodaj usluge",
    },
    deleteProfile: {
      cantDeleteProfile: "Ne možete izbrisati svoj trenutni profil",
      areYouSure: "Jeste li sigurni da želite izbrisati ovaj profil?",
      delete: "Izbriši",
    },
    notFoundPage: {
      title: "Nešto je pošlo po zlu",
      text: "Ipak, ne brinite, sve je u redu. Vratit ćemo se sa ovom stranicom.",
      home: "Početna stranica",
    },
    noInternet: {
      title: "Nema internet veze",
      description:
        "Nemojte se brinuti, sve je u redu. Vratit ćemo se s ovom stranicom.",
      button: "Ponovo učitaj",
    },
  },
};

export default BS_RESOURCE;
