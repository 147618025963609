const LB_RESOURCE = {
  translation: {
    loading: "Gëtt gelueden",
    search: {
      cancel: "Ofbriechen",
      noResults:
        "Keng Sichresultater fonnt, awer déi ënnen opgelëscht sinn et definitiv derwäert, se unzekucken.",
    },
    menu: {
      switchProfiles: "Profiler wiesselen",
      settings: "Astellungen",
      close: "Zoumaachen",
      logOut: "Ofmellen",
    },
    button: {
      apply: "Uwenden",
      continue: "Virufueren",
      cancel: "Ofbriechen",
      confirm: "Bestätegen",
      play: "Ofspillen",
      addToWatchList: "Op d’Kucklëscht derbäisetzen.",
      removeFromWatchList: "Vun der Kucklëscht ewechhuelen",
      moreInfo: "Méi Informatiounen",
      resume: "Weiderkucken",
      close: "Zoumaachen",
      discard: "Verwerfen",
      save: "Späicheren",
    },
    login: {
      header: "Kontumeldung",
      title: "Wëllkomm op",
      subTitle:
        "Notzt Äre Streamingabonnementer mat Disney, Amazon a méi optimal.",
      text: "Alles, wat Dir kucke wëllt, op enger Plaz.",
      button: "Umellen",
      placeholder: {
        username: "Benotzernumm",
        password: "Passwuert",
      },
      errors: {
        email: {
          noValid: "D’E‑Mail-Adress muss eng gülteg E‑Mail-Adress sinn",
          required: "E‑Mail-Adress ass e Flichtfeld",
        },
        password: {
          required: "Passwuert ass e Flichtfeld",
        },
        wrongPass: {
          first:
            "Ups, dat war net dat richtegt Passwuert. Probéiert et wgl. erneit oder klickt d’Schaltfläch ",
          second: '“Passwuert vergiess"',
          third: "ënnen un.",
        },
      },
    },
    forgotPassword: {
      message:
        "Maacht Iech keng Suergen. Äert Passwuert zeréckzesetzen ass einfach. Gitt einfach Är E‑Mail-Adress ënnen an a klickt op „SCHÉCKEN“",
      button: "SCHÉCKEN",
      placeholder: {
        username: "Benotzernumm",
      },
      errors: {
        email: {
          required: "E‑Mail-Adress ass e Flichtfeld",
        },
        emailNotRegister: "E‑Mail-Adress net registréiert!",
        emailSent: "Passwuertzerécksetzungs-E‑Mail geschéckt!",
      },
    },
    profilesPage: {
      title: "Wien kuckt?",
      subTitle:
        "Jiddereen an ärem Auto kann eng personaliséiert TV Erfahrung hunn.",
      newProfile: "+ Neien Profil derbäisetzen",
    },
    newProfile: {
      title: "Neie Profil",
      description: "Eng Profil fir eng aner Persoun derbäisetzen, déi ScreenHits TV kuckt",
      isKid: "Kand?",
      loading: "Gëtt gelueden",
      profileCreated: "Profil erfollegräich erstallt!",
      profileSelection: "Profilauswiel",
    },
    settings: {
      accountDetails: {
        primary: "Kontdetailer",
        secondary: "Éditéiert den Numm vum Kontbesëtzer an d’E‑Mail-Adress.",
      },
      manageProfiles: {
        primary: "Profilen verwalten",
        secondary: "Profildetailer änneren",
        edit: "Profil änneren",
      },
      parentControl: {
        primary: "Elterenkontroll",
        secondary: "PIN erstellen / PIN änneren",
        instruction: {
          primary: "Erstellt (aktualiséiert) PIN fir d'Elterekontroll",
          secondary: "(Gitt w.e.g. 4 Zuelen an während d'Tastatur op ass)",
        },
        recoverPin: "PIN zrécksetzen",
        pinSentTo: "PIN geschéckt un",
        unlock: "Entrée",
        pinUndefined: "PIN un d'E-Mail-Adress geschéckt"
      },
      membershipStatus: {
        primary: "Memberschaftsstatus",
        secondary: "Aktiv",
      },
      deactivateAccount: {
        primary: "Kont Deaktivéieren",
        secondary: "Schléiss Ären Kont a trenn Är Geräter.",
        enterPass: "Gitt Äre Passwuert an",
        discard: "Ännerunge verwerfen",
      },
      activeLanguage: "Aktiv Sprooch",
      defaultLanguage: "Systemsprooch",
      changeDefaultLanguage: "Standard Sprooch änneren",
      privacyPolicy: {
        text: "Dateschutzerklärung",
      },
      settings: "Astellungen",
      lang: {
        en: "Englesch",
        it: "Italieenesch",
        de: "Däitsch",
        esar: "Spuenesch (Argentina)",
        fr: "Franséisch",
        ja: "Japanesch",
        ro: "Rumänesch",
        da: "Dänesch",
        nl: "Hollännesch",
        ca: "Katalanesch",
        lb: "Lëtzebuergesch",
        no: "Norwegesch",
        ptbr: "Portugisesch(Brasilien)",
        zhcn: "Chineesesch",
        ms: "Malaiesch",
        ta: "Tamilesch",
        bs: "Bosnesch",
        bg: "Bulgaresch",
        hr: "Kroatesch",
        tr: "Tierkesch",
        cs: "Tschechesch",
        et: "Estnesch",
        fi: "Finnesch",
        el: "Griichesch",
        hu: "Ungaresch",
        ga: "Iresch",
        is: "Islännesch",
        lv: "Lettesch",
        lt: "Litauesch",
        mt: "Malteesesch",
        pl: "Polnesch",
        pt: "Portugisesch(Portugal)",
        ru: "Russesch",
        sr: "Serbesch",
        sk: "Slowakesch",
        sl: "Sloweenesch",
        sv: "Schweedesch",
        mi: "Maori",
        ar: "Arabesch",
        af: "Afrikaans",
        zhtw: "Chineesesch(Traditionell)",
        es: "Spuenesch(Spuenien)",
        ko: "Koreanesch",
        enus: "Englesch (Vereenegt Staaten)",
        deat: "Däitsch (Éisträich)",
        dech: "Däitsch (Schwäiz)",
        deli: "Däitsch (Liechtenstein)",
        dede: "Däitsch (Däitschland)",
        enca: "Englesch (Kanada)",
        engb: "Englesch",
        enie: "Englesch (Irland)",
        ennz: "Englesch (Neiséiland)",
        enza: "Englesch (Südafrika)",
        esmx: "Spuenesch (Mexiko)",
        frca: "Franséisch (Kanada)",
        frch: "Franséisch (Schwäiz)",
        itch: "Italienesch (Schwäiz)",
        jajp: "Japanesch (Japan)",
        kokr: "Koreanesch (Südkorea)",
        nb: "Norwegesch (Bokmål)",
        nn: "Norwegesch (Nynorsk)",
        zhch: "Chinesesch (V.R. China)",
      },
      language: {
        primary: "Sprooch",
      },
    },
    show: {
      more: " Méi uweisen",
      less: " Manner uweisen",
    },
    showPage: {
      seasons: "Staffelen",
      cast: "Besetzung",
      producers: "Produzenten",
      genre: "Genre",
      directedBy: "Regie",
      season: "Staffel",
    },
    accountDetails: {
      firstName: "Virnumm",
      lastName: "Familljennumm",
      email: "E‑Mail-Adress",
      password: "Passwuert",
      changePassword: "Passwuert änneren",
      forgotPassword: "Passwuert vergiess",
    },
    childPinPopup: {
      title: "Kannerschutz",
      subTitle: "PIN fir Kannerschutz erstellen (aktualiséieren)",
      pinUpdated: "PIN erfollegräich aktualiséiert",
      pinIsIncorrect: "PIN ass falsch",
    },
    deactivateAccount: {
      title: "Sidd Dir sécher, datt Dir Äre Kont desaktivéiere wëllt?",
      description:
        "Esoubal Dir dat maacht, gitt Dir vun alle Servicer ofgemellt an Dir kënnt keng Inhalter méi an Ärem Auto kucken.",
      deactivateButton: "Kont desaktivéieren",
    },
    servicesPage: {
      add: "Bei Servicer derbäisetzen",
      remove: "Vu Servicer ewechhuelen",
      overflow: {
        title: "Ausgewielt Päck",
        subtitle: "Är Servicer auswielen",
        description:
          "Setzt jiddwer vun Iech genotzte Service bei Är Mediesäit derbäi.",
      },
    },
    manageProfile: {
      text: "Profiler verwalten",
    },
    watchlist: {
      title:
        "Hei fannt Dir all d’Filmer a Sendungen, déi Dir bei Är Kucklëscht derbäigesat hutt.",
      noTitles: "Dir hutt den Ament keng Titelen an Ärer Kucklëscht.",
      addText:
        "Fir derbäizesetzen, klickt bei jiddwer Sendung, déi Dir Iech spéider ukucke wëllt, op + Op d’Kucklëscht derbäisetzen.",
      subtext: {
        start:
          "Fir derbäizesetzen, klickt bei jiddwer Sendung, déi Dir Iech spéider ukucke wëllt op ",
        toWatchList: "Op d’Kucklëscht derbäisetzen.",
        end: "",
      },
    },
    streamingService: {
      title: "Streamingservicer auswielen",
      subTitle:
        "Är ausgewielt Servicer derbäisetzen, déi an Ärer Fernseemediesäit ugewise solle ginn",
      streamingServices: "Streamingservicer",
    },
    profileUpdate: {
      backToSettings: "Zeréck op d’Astellungen",
      updatedSuccess: "Benotzer gouf erfollegräich aktualiséiert",
      apply: "Uwenden",
      wrongPassword: "Falscht Passwuert",
    },
    changePasswordPage: {
      backToSettings: "Zeréck op d’Astellungen",
      currentPass: "Aktuellt Passwuert",
      newPass: "Neit Passwuert",
      confirmPass: "Passwuert bestätegen",
      change: "Änneren",
      changeUserInfo: "Benotzerinformatiounen änneren",
      passwordDoNotMatch:
        "D’Passwierder stëmmen net iwwereneen oder sinn ongülteg",
      passwordUpdated: "Passwuert erfollegräich aktualiséiert",
    },
    noServices: {
      title: "Dir hutt den Ament keng Servicer op Ärer Mediesäit.",
      text: "Fir ScreenHits TV optimal notzen ze kënnen, musst Dir Servicer derbäisetzen. Klickt wgl. hei, fir d’Streamere ouni Zousazkäschten derbäizesetzen, déi Dir schonn abonéiert hutt. A wann Dir den Ament kee Streamingservice abonéiert hutt, da setzt hei eise GRATIS Pak derbäi a leet lass, fir groussaarteg Inhalter vu BBC, ITV, My5, Channel 4, UKTV an STV ze entdecken.",
      button: "Servicer derbäisetzen",
    },
    deleteProfile: {
      cantDeleteProfile: "Dir kënnt Ären aktuelle Kont net läschen",
      areYouSure: "Sidd Dir sécher, datt Dir dëse Profil läsche wëllt?",
      delete: "Profil läschen",
    },
    notFoundPage: {
      title: "Et ass eppes schif gelaf",
      text: "Maacht Iech awer keng Suergen, alles ass ok. Mir kommen zréck mat dëser Säit.",
      home: "Startsäit",
    },
    noInternet: {
      title: "Keng Internet Verbindung",
      description:
        "Keng Angscht, alles ass an der Rei. Mir kommen mat dëser Säit zréck.",
      button: "Reload",
    },
  },
};

export default LB_RESOURCE;
