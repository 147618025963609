const ENDPOINTS = {
  AUTH_USER: "/user",
  AUTH_USER_V2: "/User",
  PROFILE: "/profile",
  LINEAR: "/linear",
  MEDIA: "/media",
  GENRE: "/genre",
  CHANNEL: "/channel",
  MISC: "/misc",
  TV_FRIENDS: "/tvfriends",
  STATS: "/stats/recordevent",
};

export default ENDPOINTS;
