const FI_RESOURCE = {
  translation: {
    loading: "Ladataan",
    search: {
      cancel: "Peruuta",
      noResults:
        "Hakutuloksia ei löytynyt, mutta alla olevat ovat ehdottomasti tutustumisen arvoisia.",
    },
    menu: {
      switchProfiles: "Vaihda profiilia",
      settings: "Asetukset",
      close: "Sulje",
      logOut: "Kirjaudu ulos",
    },
    button: {
      apply: "Käytä",
      continue: "Jatka",
      cancel: "Peruuta",
      confirm: "Vahvista",
      play: "Toista",
      addToWatchList: "Lisää katselulistalle",
      removeFromWatchList: "Poista katselulistalta",
      moreInfo: "Lisätietoja",
      resume: "Jatka",
      close: "Sulje",
      discard: "Hylkää",
      save: "Tallenna",
    },
    login: {
      header: "Kirjaudu tilille",
      title: "Tervetuloa",
      subTitle:
        "Ota kaikki irti suoratoistotilauksistasi muun muassa Netflixillä, Disneyllä ja Amazonilla.",
      text: "Kaikki mitä haluat katsoa, yhdessä paikassa.",
      button: "Kirjaudu sisään",
      placeholder: {
        username: "Käyttäjänimi",
        password: "Salasana",
      },
      errors: {
        email: {
          noValid: "Sähköpostiosoitteen on oltava olemassa",
          required: "Sähköpostiosoite on pakollinen kenttä",
        },
        password: {
          required: "Salasana on pakollinen kenttä",
        },
        wrongPass: {
          first: "Väärä salasana. Yritä uudelleen tai napsauta ",
          second: "“Unohtunut salasana” ",
          third: "-painiketta alla.",
        },
      },
    },
    forgotPassword: {
      message:
        'Ei huolta. Salasanan nollaaminen on helppoa. Syötä sähköpostiosoitteesi alle ja napsauta "Lähetä"',
      button: "LÄHETÄ",
      placeholder: {
        username: "Käyttäjänimi",
      },
      errors: {
        email: {
          required: "Sähköpostiosoite on pakollinen kenttä",
        },
        emailNotRegister: "Sähköpostiosoitetta ei ole rekisteröity!",
        emailSent: "Salasanan nollausta koskeva sähköposti on lähetetty!",
      },
    },
    profilesPage: {
      title: "Kuka katsoo?",
      subTitle: "Kaikki autossasi voivat saada personoidun TV-kokemuksen.",
      newProfile: "+ Lisää uusi profiili",
    },
    newProfile: {
      title: "Uusi profiili",
      description: "Lisää profiili toiselle henkilölle, joka katsoo ScreenHits TV:tä",
      isKid: "Lapsi?",
      loading: "Ladataan",
      profileCreated: "Profiilin luominen onnistui!",
      profileSelection: "Profiilin valinta",
    },
    settings: {
      accountDetails: {
        primary: "Tilin tiedot",
        secondary: "Muokkaa tilinomistajan nimeä ja sähköpostia.",
      },
      manageProfiles: {
        primary: "Hallitse profiileja",
        secondary: "Muokkaa profiilitietoja",
        edit: "Muokkaa profiilia",
      },
      parentControl: {
        primary: "Vanhempien valvonta",
        secondary: "Luo PIN / Vaihda PIN",
        instruction: {
          primary: "Luo (päivitä) PIN-suojakoodi Lapsilukkoa varten",
          secondary: "(Syötä 4 numeroa näppäimistön ollessa avoinna)",
        },
        recoverPin: "Palauta PIN-koodi",
        pinSentTo: "PIN lähetetty osoitteeseen",
        unlock: "Avaa",
        pinUndefined: "PIN lähetetty sähköpostiosoitteeseen"
      },
      membershipStatus: {
        primary: "Jäsenyysstatus",
        secondary: "Aktiivinen",
      },
      deactivateAccount: {
        primary: "Poista käyttäjätili",
        secondary: "Sulje tilisi ja irrota laitteet.",
        enterPass: "Syötä salasanasi",
        discard: "Hylkää muutokset",
      },
      activeLanguage: "Aktiivinen kieli",
      defaultLanguage: "Järjestelmän kieli",
      changeDefaultLanguage: "Vaihda oletuskieli",
      privacyPolicy: {
        text: "Tietosuojakäytäntö",
      },
      settings: "Asetukset",
      lang: {
        en: "Englanti",
        it: "Italia",
        de: "Saksa",
        esar: "Espanja(Argentiina)",
        fr: "Ranska",
        ja: "Japani",
        ro: "Romania",
        da: "Tanska",
        nl: "Hollanti",
        ca: "Katalaani",
        lb: "Luxemburg",
        no: "Norja",
        ptbr: "Portugali(Portugali)",
        zhcn: "Kiina",
        ms: "Malaji",
        ta: "Tamili",
        bs: "Bosnia",
        bg: "Bulgaria",
        hr: "Kroatia",
        tr: "Turkki",
        cs: "Tšekki",
        et: "Viro",
        fi: "Suomi",
        el: "Kreikka",
        hu: "Unkari",
        ga: "Irlanti",
        is: "Islanti",
        lv: "Latvia",
        lt: "Liettua",
        mt: "Malta",
        pl: "Puola",
        pt: "Portugali(Portugali)",
        ru: "Venäjä",
        sr: "Serbia",
        sk: "Slovakki",
        sl: "Sloveeni",
        sv: "Ruotsi",
        mi: "Maori",
        ar: "Arabia",
        af: "Afrikaans",
        zhtw: "Kiina(Perinteinen)",
        es: "Espanja(Espanja)",
        ko: "Korea",
        enus: "English (United States)",
        deat: "German (Austria)",
        dech: "German (Switzerland)",
        deli: "German (Liechtenstein)",
        dede: "German (Deutschland)",
        enca: "English (Canada)",
        engb: "English",
        enie: "English (Ireland)",
        ennz: "English (New Zealand)",
        enza: "English (South Africa)",
        esmx: "Spanish (Mexico)",
        frca: "French (Canada)",
        frch: "French (Switzerland)",
        itch: "Italian (Switzerland)",
        jajp: "Japanese (Japan)",
        kokr: "Korean (South Korea)",
        nb: "Norwegian (Bokmål)",
        nn: "Norwegian (Nynorsk)",
        zhch: "Chinese (PRC)",
      },
      language: {
        primary: "Kieli",
      },
    },
    show: {
      more: " Näytä lisää",
      less: " Näytä vähemmän",
    },
    showPage: {
      seasons: "Kaudet",
      cast: "Näyttelijät",
      producers: "Tuottajat",
      genre: "Tyylilaji",
      directedBy: "Ohjaus",
      season: "Kausi",
    },
    accountDetails: {
      firstName: "Etunimi",
      lastName: "Sukunimi",
      email: "Sähköpostiosoite",
      password: "Salasana",
      changePassword: "Vaihda salasana",
      forgotPassword: "Unohtunut salasana",
    },
    childPinPopup: {
      title: "Lasten suojelu",
      subTitle: "Luo (päivitä) lasten suojelun PIN",
      pinUpdated: "PIN päivitetty onnistuneesti",
      pinIsIncorrect: "PIN on virheellinen",
    },
    deactivateAccount: {
      title: "Haluatko varmasti poistaa tilisi käytöstä?",
      description:
        "Kun teet näin, sinut kirjataan ulos kaikista palveluista, etkä voi enää katsella sisältöjä autossasi",
      deactivateButton: "Poista tili käytöstä",
    },
    servicesPage: {
      add: "Lisää palveluihin",
      remove: "Poista palveluista",
      overflow: {
        title: "Valitut paketit",
        subtitle: "Valitse palvelut",
        description: "Lisää jokainen käyttämäsi palvelu oppaaseen.",
      },
    },
    manageProfile: {
      text: "Profiilien hallinta",
    },
    watchlist: {
      title:
        "Tässä ovat kaikki elokuvat ja ohjelmat, jotka olet lisännyt katselulistallesi.",
      noTitles: "Katselulistasi on tällä hetkellä tyhjä.",
      addText:
        'Lisää kohteita kohdasta "+ Lisää katselulistalle", kun haluat tallentaa jotakin katsottavaksi myöhemmin.',
      subtext: {
        start: "Lisää napsauttamalla ",
        toWatchList: "Lisää katselulistalle",
        end: "tai mitä tahansa ohjelmaa, jonka haluaisit katsoa myöhemmin.",
      },
    },
    streamingService: {
      title: "Valitse suoratoistopalvelut",
      subTitle: "Lisää valitut palvelut TV-oppaaseen.",
      streamingServices: "Suoratoistopalvelut",
    },
    profileUpdate: {
      backToSettings: "Palaa asetuksiin",
      updatedSuccess: "Käyttäjä päivitettiin onnistuneesti",
      apply: "Käytä",
      wrongPassword: "Väärä salasana",
    },
    changePasswordPage: {
      backToSettings: "Palaa asetuksiin",
      currentPass: "Nykyinen salasana",
      newPass: "Uusi salasana",
      confirmPass: "Vahvista salasana",
      change: "Muuta",
      changeUserInfo: "Muuta käyttäjätietoja",
      passwordDoNotMatch: "Salasanat eivät täsmää tai eivät kelpaa",
      passwordUpdated: "Salasana päivitettiin onnistuneesti",
    },
    noServices: {
      title: "Sinulla ei ole tällä hetkellä palveluita TV-oppaassa",
      text: "Käyttääksesi ScreenHits TV -palvelua parhaalla mahdollisella tavalla sinun täytyy lisätä palveluja. Napsauta tästä lisätäksesi jo tilaamasi suoratoistopalvelut ilman lisämaksua. Jos et tällä hetkellä tilaa mitään suoratoistopalvelua, lisää ILMAINEN pakettimme tästä ja löydä loistavaa sisältöä BBC-, ITV-, My5-, Channel 4, UKTV- ja STV-palveluista.",
      button: "Lisää palveluita",
    },
    deleteProfile: {
      cantDeleteProfile: "Et voi poistaa nykyistä profiiliasi",
      areYouSure: "Haluatko varmasti poistaa tämän profiilin?",
      delete: "Poista profiili",
    },
    notFoundPage: {
      title: "Jokin meni pieleen",
      text: "Älä kuitenkaan huoli, kaikki on hyvin. Palaamme tämän sivun kanssa.",
      home: "Etusivu ",
    },
    noInternet: {
      title: "Ei internetyhteyttä",
      description: "Älä huoli, kaikki on kunnossa. Palaamme tällä sivulla.",
      button: "Lataa uudelleen",
    },
  },
};

export default FI_RESOURCE;
