const SV_RESOURCE = {
  translation: {
    loading: "Laddar ",
    search: {
      cancel: "Avbryt",
      noResults:
        "Inga sökresultat hittades, men det är definitivt värt att kolla in dessa nedan.",
    },
    menu: {
      switchProfiles: "Ändra profil",
      settings: "Inställningar",
      close: "Stäng",
      logOut: "Logga ut",
    },
    button: {
      apply: "Ansök på",
      continue: "Fortsätt",
      cancel: "Avbryt",
      confirm: "Bekräfta",
      play: "Spela",
      addToWatchList: "Lägg till i bevakningslistan",
      removeFromWatchList: "Ta bort från bevakningslistan",
      moreInfo: "Mer information",
      resume: "Återuppta",
      close: "Stäng",
      discard: "Släng",
      save: "Spara",
    },
    login: {
      header: "Logga in på kontot",
      title: "Välkommen till",
      subTitle:
        "Få ut det mesta av dina streamingabonnemang med Disney+, Amazon med flera.",
      text: "Allt du vill se på ett och samma ställe.",
      button: "Logga in",
      placeholder: {
        username: "Användarnamn",
        password: "Lösenord",
      },
      errors: {
        email: {
          noValid: "E-postadressen måste vara en giltig e-postadress.",
          required: "E-post är ett obligatoriskt fält",
        },
        password: {
          required: "Lösenordet är ett obligatoriskt fält",
        },
        wrongPass: {
          first:
            "Hoppsan, det var inte rätt lösenord. Försök igen eller klicka på ",
          second: "“Glömt lösenordet” ",
          third: "knappen nedan.",
        },
      },
    },
    forgotPassword: {
      message:
        'Oroa dig inte. Det är enkelt att återställa ditt lösenord. Ange bara din e-postadress nedan och klicka på "Sänd".',
      button: "Sänd",
      placeholder: {
        username: "Användarnamn",
      },
      errors: {
        email: {
          required: "E-post är ett obligatoriskt fält",
        },
        emailNotRegister: "E-postadress inte registrerad!",
        emailSent: "E-postmeddelande om återställning av lösenord skickat!",
      },
    },
    profilesPage: {
      title: "Välj profil",
      subTitle: "Alla i din bil kan ha en personlig TV-upplevelse.",
      newProfile: "+ Lägg till ny profil",
    },
    newProfile: {
      title: "Nytt profil",
      description: "Lägg till ett profil för en annan person som tittar på ScreenHits TV",
      isKid: "Barn?",
      loading: "Laddar",
      profileCreated: "Profilen har skapats med framgång!",
      profileSelection: "Val av profil",
    },
    settings: {
      accountDetails: {
        primary: "Kontouppgifter",
        secondary: "Redigera kontoinnehavarens namn och e-post.",
      },
      manageProfiles: {
        primary: "Hantera profiler",
        secondary: "Redigera profilinformation",
        edit: "Redigera profil",
      },
      parentControl: {
        primary: "Föräldrakontroll",
        secondary: "Skapa PIN / Ändra PIN",
        instruction: {
          primary: "Skapa (uppdatera) PIN-kod för föräldrakontroll",
          secondary: "(Vänligen ange 4 siffror medan tangentbordet är öppet)",
        },
        recoverPin: "Återställ PIN",
        pinSentTo: "PIN skickad till",
        unlock: "Lås upp",
        pinUndefined: "PIN skickad till e-postadressen"
      },
      membershipStatus: {
        primary: "Status för medlemskap",
        secondary: "Aktiv",
      },
      deactivateAccount: {
        primary: "Inaktivera kontot",
        secondary: "Stäng ditt konto och koppla bort dina enheter.",
        enterPass: "Ange ditt lösenord",
      },
      activeLanguage: "Aktivt språk",
      defaultLanguage: "Systemspråk",
      changeDefaultLanguage: "Ändra standardspråk",
      privacyPolicy: {
        text: "Integritetspolicy",
      },
      settings: "Inställningar",
      lang: {
        en: "Engelska",
        it: "Italienska",
        de: "Tyska",
        esar: "Spanska(Argentina)",
        fr: "Franska",
        ja: "Japanska",
        ro: "Rumänska",
        da: "Danska",
        nl: "Holländska",
        ca: "Katalanska",
        lb: "Luxemburgiska",
        no: "Norska",
        ptbr: "Portugisiska(Brasilien)",
        zhcn: "Kinesiska",
        ms: "Malajiska",
        ta: "Tamil",
        bs: "Bosniska",
        bg: "Bulgariska",
        hr: "Kroatiska",
        tr: "Turkiska",
        cs: "Tjeckien",
        et: "Estniska",
        fi: "Finska",
        el: "Grekiska",
        hu: "Ungerska",
        ga: "Irländska",
        is: "Isländska",
        lv: "Lettiska",
        lt: "Litauiska",
        mt: "Maltesiska",
        pl: "Polska",
        pt: "Portugisiska(Portugal)",
        ru: "Ryska",
        sr: "Serbiska",
        sk: "Slovakiska",
        sl: "Slovenska",
        sv: "Svenska",
        mi: "Maori",
        ar: "Arabiska",
        af: "Afrikaans",
        zhtw: "Kinesiska(Traditionell)",
        es: "Spanska (Spanien)",
        ko: "Koreanska",
        enus: "Engelska (USA)",
        deat: "Tyska (Österrike)",
        dech: "Tyska (Schweiz)",
        deli: "Tyska (Liechtenstein)",
        dede: "Tyska (Tyskland)",
        enca: "Engelska (Kanada)",
        engb: "Engelska",
        enie: "Engelska (Irland)",
        ennz: "Engelska (Nya Zeeland)",
        enza: "Engelska (Sydafrika)",
        esmx: "Spanska (Mexiko)",
        frca: "Franska (Kanada)",
        frch: "Franska (Schweiz)",
        itch: "Italienska (Schweiz)",
        jajp: "Japanska (Japan)",
        kokr: "Koreanska (Sydkorea)",
        nb: "Norska (Bokmål)",
        nn: "Norska (Nynorska)",
        zhch: "Kinesiska (Kina)",
      },
      language: {
        primary: "Språk",
      },
    },
    show: {
      more: " Visa mer",
      less: " Visa mindre",
    },
    showPage: {
      seasons: "Säsonger",
      cast: "Kasta",
      producers: "Producenter",
      genre: "Genre",
      directedBy: "Regisserad av",
      season: "Säsong",
    },
    accountDetails: {
      firstName: "Förnamn",
      lastName: "Efternamn",
      email: "E-post",
      password: "Lösenord",
      changePassword: "Ändra lösenord",
      forgotPassword: "Glömt ditt lösenord",
    },
    childPinPopup: {
      title: "Barnskydd",
      subTitle: "Skapa (uppdatera) PIN-kod för barnskydd",
      pinUpdated: "PIN-koden har uppdaterats",
      pinIsIncorrect: "PIN-koden är inkorrekt",
    },
    deactivateAccount: {
      title: "Är du säker på att du vill inaktivera ditt konto?",
      description:
        "När du gör det kommer du att loggas ut från alla tjänster och kommer inte längre att kunna se innehållet i din bil",
      deactivateButton: "Inaktivera konto",
      discard: "Discard Changes",
    },
    servicesPage: {
      add: "Lägg till tjänster",
      remove: "Ta bort från tjänster",
      overflow: {
        title: "Utvalda paket",
        subtitle: "Välj dina tjänster",
        description: "Lägg till alla tjänster du använder i din guide.",
      },
    },
    manageProfile: {
      text: "Hantera profiler",
    },
    watchlist: {
      title:
        "Här är alla filmer och program som du har lagt till på din bevakningslista",
      noTitles: "Du har för närvarande inga titlar i din bevakningslista",
      addText:
        "För att lägga till, vänligen klicka på + Lägg till i bevakningslista på alla program du vill komma tillbaka till och titta på vid ett senare tillfälle.",
      subtext: {
        start: "För att lägga till, klicka på ",
        toWatchList: "Lägg till i tittalistan",
        end: "det program du vill komma tillbaka till och se vid ett senare tillfälle.",
      },
    },
    streamingService: {
      title: "Streamingtjänster",
      subTitle: "Lägg till dina valda tjänster för att visas i din Tv-guide",
      streamingServices: "Streamingtjänster",
    },
    profileUpdate: {
      backToSettings: "Tillbaka till Inställningar",
      updatedSuccess: "Användaren uppdaterades framgångsrikt",
      apply: "Tillämpa",
      wrongPassword: "Fel lösenord",
    },
    changePasswordPage: {
      backToSettings: "Tillbaka till Inställningar",
      currentPass: "Nuvarande lösenord",
      newPass: "Nytt lösenord",
      confirmPass: "Bekräfta lösenord",
      change: "Förändra",
      changeUserInfo: "Ändra användarinformation",
      passwordDoNotMatch: "Lösenord stämmer inte överens eller är ogiltiga",
      passwordUpdated: "Lösenordet har uppdaterats",
    },
    noServices: {
      title: "Du har för närvarande inga tjänster i din Tv-guide",
      text: "För att få ut det mesta av ScreenHits TV måste du lägga till tjänster. Klicka här för att lägga till streamingtjänster du redan prenumererar på utan extra kostnad. Och om du för närvarande inte prenumererar på någon streamingtjänst, lägg till vårt GRATIS-paket och börja upptäcka fantastiskt innehåll.",
      button: "Lägg till tjänster",
    },
    deleteProfile: {
      cantDeleteProfile: "Du kan inte ta bort din nuvarande profil",
      areYouSure: "Är du säker på att du vill ta bort den här profilen?",
      delete: "Ta bort profil",
    },
    notFoundPage: {
      title: "Något gick fel",
      text: "Men oroa dig inte, allt är okej. Vi återkommer med denna sida.",
      home: "Hemsida",
    },
    noInternet: {
      title: "Ingen internetanslutning",
      description:
        "Var inte orolig, allt är okej. Vi kommer tillbaka med denna sida.",
      button: "Ladda om",
    },
  },
};

export default SV_RESOURCE;
