const BG_RESOURCE = {
  translation: {
    loading: "Зареждане",
    search: {
      cancel: "Отмяна",
      noResults:
        "Няма намерени резултати от търсенето, но тези по-долу определено си заслужават да бъдат разгледани.",
    },
    menu: {
      switchProfiles: "Смени профила",
      settings: "Настройки",
      close: "Затвори",
      logOut: "Излизане от системата",
    },
    button: {
      apply: "Нанеси",
      continue: "Продължи",
      cancel: "Отмяна",
      confirm: "Потвърди",
      play: "Играй",
      addToWatchList: "Добави в списъка за гледане",
      removeFromWatchList: "Премахни от списъка за гледане",
      moreInfo: "Повече информация",
      resume: "Продължи",
      close: "Затвори",
      discard: "Отхвърляне",
      save: "Запази",
    },
    login: {
      header: "Вход в профила",
      title: "Добре дошъл в",
      subTitle:
        "Възползвай се максимално от абонаментите си за стрийминг с Disney, Amazon и др.",
      text: "Всичко, което искаш да гледаш, на едно място.",
      button: "Влез в системата",
      placeholder: {
        username: "Потребителско име",
        password: "Парола",
      },
      errors: {
        email: {
          noValid: "Имейлът трябва да бъде валиден",
          required: "Имейлът е задължително поле",
        },
        password: {
          required: "Паролата е задължително поле",
        },
        wrongPass: {
          first:
            "О не, това не беше правилната парола. Моля, опитай отново или кликни върху  ",
          second: "“Забравена парола” ",
          third: "бутона по-долу.",
        },
      },
    },
    forgotPassword: {
      message:
        'Не се притеснявай. Възстановяването на паролата ти е лесно. Просто въведи имейл адреса си по-долу и кликни върху "ИЗПРАТИ"',
      button: "ИЗПРАТИ",
      placeholder: {
        username: "Потребителско име",
      },
      errors: {
        email: {
          required: "Имейлът е задължително поле",
        },
        emailNotRegister: "Имейл адресът не е регистриран!",
        emailSent: "Изпратен имейл за нулиране на паролата!",
      },
    },
    profilesPage: {
      title: "Кой гледа сега?",
      subTitle:
        "Всеки в колата ви може да има персонализирано телевизионно изживяване.",
      newProfile: "+ Добавяне на нов профил",
    },
    newProfile: {
      title: "Нов профил",
      description: "Добавете профил за друг човек, гледащ ScreenHits TV",
      isKid: "Дете?",
      loading: "Зареждане",
      profileCreated: "Успешно създаден профил!",
      profileSelection: "Избор на профил",
    },
    settings: {
      accountDetails: {
        primary: "Данни за сметката",
        secondary: "Редактиране на името на притежателя на акаунта и имейл.",
      },
      manageProfiles: {
        primary: "Управление на профилите",
        secondary: "Редактиране на детайлите на профила",
        edit: "Редактиране на профил",
      },
      parentControl: {
        primary: "Родителски контрол",
        secondary: "Създаване / Промяна на ПИН",
        instruction: {
          primary: "Създайте (актуализирайте) ПИН за Защита на Децата",
          secondary: "(Моля, въведете 4 цифри, докато клавиатурата е отворена)",
        },
        recoverPin: "Нулиране на ПИН",
        pinSentTo: "ПИН е изпратен на",
        unlock: "Отключи",
        pinUndefined: "PIN кодът е изпратен на имейл адреса"
      },
      membershipStatus: {
        primary: "Статус на членство",
        secondary: "Активен",
      },
      deactivateAccount: {
        primary: "Деактивиране на профила",
        secondary: "Затворете профила си и разкачете устройствата си.",
        enterPass: "Въведете паролата си",
        discard: "Отхвърляне на промените",
      },
      activeLanguage: "Активен език",
      defaultLanguage: "Системен език",
      changeDefaultLanguage: "Смени език по подразбиране",
      privacyPolicy: {
        text: "Политика за поверителност",
      },
      settings: "Настройки",
      lang: {
        en: "Английски",
        it: "Италиански",
        de: "Немски",
        esar: "Испански език(Аржентина)",
        fr: "Френски",
        ja: "Японски",
        ro: "Румънски",
        da: "Датски",
        nl: "Холандски",
        ca: "Каталонски",
        lb: "Луксембургски",
        no: "Норвежки",
        ptbr: "Португалски(Бразилия)",
        zhcn: "Китайски",
        ms: "Малайски",
        ta: "Тамил",
        bs: "Босненски",
        bg: "Български",
        hr: "Хърватски",
        tr: "Турски",
        cs: "Чешки",
        et: "Естонски",
        fi: "Финландски",
        el: "Гръцки",
        hu: "Унгарски",
        ga: "Ирландски",
        is: "Исландски",
        lv: "Латвийски",
        lt: "Литовски",
        mt: "Малтийски",
        pl: "Полски",
        pt: "Португалски(Португалия)",
        ru: "Руски",
        sr: "Сръбски",
        sk: "Словашки",
        sl: "Словенски",
        sv: "Шведски",
        mi: "Маори",
        ar: "Арабски",
        af: "Африканс",
        zhtw: "Китайски(традиционен)",
        es: "Испански език(Испания)",
        ko: "Корейски",
        enus: "Английски (Съединени щати)",
        deat: "Немски (Австрия)",
        dech: "Немски (Швейцария)",
        deli: "Немски (Лихтенщайн)",
        dede: "Немски (Германия)",
        enca: "Английски (Канада)",
        engb: "Английски",
        enie: "Английски (Ирландия)",
        ennz: "Английски (Нова Зеландия)",
        enza: "Английски (Южна Африка)",
        esmx: "Испански (Мексико)",
        frca: "Френски (Канада)",
        frch: "Френски (Швейцария)",
        itch: "Италиански (Швейцария)",
        jajp: "Японски (Япония)",
        kokr: "Корейски (Южна Корея)",
        nb: "Норвежки (Букмол)",
        nn: "Норвежки (Нюнорск)",
        zhch: "Китайски (КНР)",
      },
      language: {
        primary: "Език",
      },
    },
    show: {
      more: " Покажи повече",
      less: " Покажи по-малко",
    },
    showPage: {
      seasons: "Сезони",
      cast: "Артисти",
      producers: "Продуценти",
      genre: "Жанр",
      directedBy: "Режисьор",
      season: "Сезон",
    },
    accountDetails: {
      firstName: "Първо име",
      lastName: "Фамилия",
      email: "Имейл",
      password: "Парола",
      changePassword: "Промяна на паролата",
      forgotPassword: "Забравена парола",
    },
    childPinPopup: {
      title: "Закрила на детето",
      subTitle: "Създаване (актуализиране) на ПИН за защита на детето",
      pinUpdated: "ПИН кодът е актуализиран успешно",
      pinIsIncorrect: "ПИН кодът е неправилен",
    },
    deactivateAccount: {
      title: "Сигурен/а ли си, че искаш да деактивираш акаунта си?",
      description:
        "След като го направиш, ще бъдеш изключен от всички услуги и вече няма да можеш да разглеждаш съдържанието в автомобила си.",
      deactivateButton: "Деактивиране на акаунт",
    },
    servicesPage: {
      add: "Добави към услугите",
      remove: "Премахни от услугите",
      overflow: {
        title: "Избрани пакети",
        subtitle: "Избери своите услуги",
        description:
          "Добави всяка използвана от теб услуга в ръководството си.",
      },
    },
    manageProfile: {
      text: "Управление на профили",
    },
    watchlist: {
      title:
        "Ето всички филми и предавания, които сте добавил в списъка си за гледане",
      noTitles: "В момента нямаш предавания в списъка си за гледане",
      addText:
        "За да добавиш предаване към списъка, моля кликни върху „+ Добави в списъка за гледане“ за всяко предаване, към което искаш да се върнеш и да гледаш по-късно.",
      subtext: {
        start: "За да добавиш предаване, моля кликни върху ",
        toWatchList: "Добави в списъка за гледане",
        end: "за някое предаване, към което искаш да се върнеш и да го гледаш на по-късна дата.",
      },
    },
    streamingService: {
      title: "Изберете услуги за стрийминг",
      subTitle:
        "Добави избраните си услуги, които да се покажат в телевизионния справочник",
      streamingServices: "Услуги за стрийминг",
    },
    profileUpdate: {
      backToSettings: "Обратно към Настройки",
      updatedSuccess: "Потребителят е актуализиран успешно",
      apply: "Нанеси",
      wrongPassword: "Грешна парола",
    },
    changePasswordPage: {
      backToSettings: "Обратно към Настройки",
      currentPass: "Текуща парола",
      newPass: "Нова парола",
      confirmPass: "Потвърди паролата",
      change: "Промяна",
      changeUserInfo: "Промяна на информацията за потребителя",
      passwordDoNotMatch: "Паролите не съвпадат или са невалидни",
      passwordUpdated: "Паролата е актуализирана успешно",
    },
    noServices: {
      title: "В момента нямаш услуги в ТВ справочника",
      text: "За да извлечеш максимума от ScreenHits TV, трябва да добавиш услуги. Моля кликни тук, за да добавиш стриймърите, за които вече си се абонирал/а, без допълнителен разход. А ако в момента не сте абониран/а за нито една услуга за стрийминг, добави си нашия БЕЗПЛАТЕН пакет и ще откриеш страхотното съдържание на BBC, ITV, My5, Channel 4, UKTV и STV.",
      button: "Добави услуги",
    },
    deleteProfile: {
      cantDeleteProfile: "Не можеш да изтриеш текущия си профил",
      areYouSure: "Сигурен ли си, че искаш да изтриеш този профил?",
      delete: "Изтрий профил",
    },
    notFoundPage: {
      title: "Изтрий",
      text: "Нещо не е наред",
      home: "Начална страница",
    },
    noInternet: {
      title: "Няма интернет връзка",
      description:
        "Не се притеснявайте, всичко е наред. Ще се върнем с тази страница.",
      button: "Презареди",
    },
  },
};

export default BG_RESOURCE;
