const ZH_CN_RESOURCE = {
  translation: {
    loading: "正在加载",
    search: {
      cancel: "取消",
      noResults: "未找到匹配的搜索结果，但以下结果绝对值得一看。",
    },
    menu: {
      switchProfiles: "切换个人资料",
      settings: "设置",
      close: "关闭",
      logOut: "退出",
    },
    button: {
      apply: "应用",
      continue: "继续",
      cancel: "取消",
      confirm: "确认",
      play: "播放",
      addToWatchList: "添加到播放列表",
      removeFromWatchList: "从播放列表中移除",
      moreInfo: "更多信息",
      resume: "恢复",
      close: "关闭",
      discard: "丢弃",
      save: "保存",
    },
    login: {
      header: "账户登录",
      title: "欢迎来到",
      subTitle: "充分利用您的流媒体订阅，包括 Disney、Amazon等。",
      text: "看您所想，一步到位。",
      button: "登录",
      placeholder: {
        username: "账号",
        password: "密码",
      },
      errors: {
        email: {
          noValid: "电子邮件必须填写一个有效的电子邮件地址",
          required: "电子邮件必填",
        },
        password: {
          required: "密码必填",
        },
        wrongPass: {
          first: "哎呀，密码不正确。请再试一次或点击下方的 ",
          second: '"忘记密码" ',
          third: "按钮。",
        },
      },
    },
    forgotPassword: {
      message:
        "不要担心。重置密码很容易。只要在下面输入您的电子邮件地址，然后点击“发送”即可。",
      button: "发送",
      placeholder: {
        username: "用户名",
      },
      errors: {
        email: {
          required: "电子邮件必填",
        },
        emailNotRegister: "电子邮件地址未注册!",
        emailSent: "重置密码的电子邮件已发出！",
      },
    },
    profilesPage: {
      title: "谁在观看",
      subTitle: "您车上的每个人都可以享受个性化的电视体验。",
      newProfile: "+ 添加新配置文件",
    },
    newProfile: {
      title: "新建档案",
      description: "为另一个观看 ScreenHits TV 的人添加档案",
      isKid: "孩子？",
      loading: "正在加载",
      profileCreated: "个人资料创建成功！!",
      profileSelection: "个人资料选择",
    },
    settings: {
      accountDetails: {
        primary: "账户详情",
        secondary: "编辑账户持有人的姓名和电子邮件。",
      },
      manageProfiles: {
        primary: "管理配置文件",
        secondary: "编辑配置文件详细信息",
        edit: "编辑配置文件",
      },
      parentControl: {
        primary: "家长控制",
        secondary: "创建PIN / 修改PIN",
        instruction: {
          primary: "创建（更新）家长控制PIN码",
          secondary: "（请输入4位数字，键盘打开时）",
        },
        recoverPin: "重置PIN",
        pinSentTo: "PIN已发送至",
        unlock: "解锁",
        pinUndefined: "PIN 发送到电子邮件地址"
      },
      membershipStatus: {
        primary: "会员状态",
        secondary: "活跃",
      },
      deactivateAccount: {
        primary: "停用账户",
        secondary: "关闭您的账户并解除您的设备关联。",
        enterPass: "输入您的密码",
        discard: "放弃更改",
      },
      activeLanguage: "惯用语言",
      defaultLanguage: "系统语言",
      changeDefaultLanguage: "更改默认语言",
      privacyPolicy: {
        text: "隐私政策",
      },
      settings: "设置",
      lang: {
        en: "英语",
        it: "意大利语",
        de: "德语",
        esar: "西班牙语（阿根廷）",
        fr: "法语",
        ja: "日语",
        ro: "罗马尼亚语",
        da: "丹麦语",
        nl: "荷兰语",
        ca: "加泰罗尼亚语",
        lb: "卢森堡语",
        no: "挪威语",
        ptbr: "葡萄牙语（巴西）",
        zhcn: "中文",
        ms: "马来语",
        ta: "泰米尔语",
        bs: "波斯尼亚语",
        bg: "保加利亚语",
        hr: "克罗地亚语",
        tr: "土耳其语",
        cs: "捷克语",
        et: "爱沙尼亚语",
        fi: "芬兰语",
        el: "希腊语",
        hu: "匈牙利语",
        ga: "爱尔兰语",
        is: "冰岛语",
        lv: "拉脱维亚语",
        lt: "立陶宛语",
        mt: "马耳他语",
        pl: "波兰语",
        pt: "葡萄牙语（葡萄牙）",
        ru: "俄罗斯",
        sr: "塞尔维亚语",
        sk: "斯洛伐克语",
        sl: "斯洛文尼亚语",
        sv: "瑞典语",
        mi: "毛利语",
        ar: "阿拉伯语",
        af: "南非荷兰语",
        zhtw: "中文（繁體）",
        es: "西班牙语（西班牙）",
        ko: "韩语",
        enus: "英语（美国）",
        deat: "德语（奥地利）",
        dech: "德语（瑞士）",
        deli: "德语（列支敦士登）",
        dede: "德语（德国）",
        enca: "英语（加拿大）",
        engb: "英语",
        enie: "英语（爱尔兰）",
        ennz: "英语（新西兰）",
        enza: "英语（南非）",
        esmx: "西班牙语（墨西哥）",
        frca: "法语（加拿大）",
        frch: "法语（瑞士）",
        itch: "意大利语（瑞士）",
        jajp: "日语（日本）",
        kokr: "韩语（韩国）",
        nb: "挪威语（书面挪威语）",
        nn: "挪威语（新挪威语）",
        zhch: "中文（中华人民共和国）",
      },
      language: {
        primary: "语言",
      },
    },
    show: {
      more: " 显示更多",
      less: " 显示更少",
    },
    showPage: {
      seasons: "季",
      cast: "演员",
      producers: "制作人",
      genre: "类型",
      directedBy: "导演",
      season: "季",
    },
    accountDetails: {
      firstName: "名字",
      lastName: "姓氏",
      email: "电子邮件",
      password: "密码",
      changePassword: "更改密码",
      forgotPassword: "忘记密码",
    },
    childPinPopup: {
      title: "儿童保护",
      subTitle: "创建（更新）儿童保护密码",
      pinUpdated: "密码更新成功",
      pinIsIncorrect: "密码不正确",
    },
    deactivateAccount: {
      title: "您确定要停用您的账户吗？",
      description:
        "一旦您这样做，您将注销所有服务，并将无法继续在您的汽车中观看内容。",
      deactivateButton: "停用账户",
    },
    servicesPage: {
      add: "添加到服务中",
      remove: "从服务中删除",
      overflow: {
        title: "已选套餐",
        subtitle: "选择您的服务",
        description: "将您使用的每项服务添加到您的指南中。",
      },
    },
    manageProfile: {
      text: "管理个人资料",
    },
    watchlist: {
      title: "这里是您添加到播放列表中的所有电影和节目",
      noTitles: "您的播放列表中目前没有任何标题",
      addText: "如需添加，请在您想要日后回来观看的节目上点击+添加到播放列表。",
      subtext: {
        start: "如需添加，请在您想要日后回来观看的节目上点击",
        toWatchList: "添加到播放列表",
        end: "",
      },
    },
    streamingService: {
      title: "选择流媒体服务",
      subTitle: "添加您选择的服务，使其出现在您的电视指南中",
      streamingServices: "流媒体服务",
    },
    profileUpdate: {
      backToSettings: "返回到设置",
      updatedSuccess: "用户已成功更新",
      apply: "应用",
      wrongPassword: "密码错误",
    },
    changePasswordPage: {
      backToSettings: "返回到设置",
      currentPass: "当前密码",
      newPass: "新密码",
      confirmPass: "确认密码",
      change: "更改",
      changeUserInfo: "更改用户信息",
      passwordDoNotMatch: "密码不匹配或无效",
      passwordUpdated: "密码更新成功",
    },
    noServices: {
      title: "您的电视指南中目前没有任何服务",
      text: "要充分利用 ScreenHits TV，您需要添加服务。 请单击此处添加您已订阅的主播，无需额外付费。如果您目前没有订阅任何流媒体服务，请在此处添加我们的免费包并开始发现来自BBC、ITV、My5、Channel 4、UKTV 和 STV的精彩内容。",
      button: "添加服务",
    },
    deleteProfile: {
      cantDeleteProfile: "您无法删除当前的个人资料",
      areYouSure: "您确定要删除此个人资料吗？",
      delete: "删除资料",
    },
    notFoundPage: {
      title: "出了点问题",
      text: "不过别担心，一切都很好。 我们将返回此页面。",
      home: "首页",
    },
    noInternet: {
      title: "无网络连接",
      description: "不用担心，一切都好。我们将返回此页面。",
      button: "重新加载",
    },
  },
};

export default ZH_CN_RESOURCE;
