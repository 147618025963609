const SK_RESOURCE = {
  translation: {
    loading: "Načítanie",
    search: {
      cancel: "Zrušiť",
      noResults:
        "Nenašli sa žiadne výsledky vyhľadávania, ale tie uvedené nižšie určite stoja za prezretie.",
    },
    menu: {
      switchProfiles: "Zmeniť profil",
      settings: "Nastavenia",
      close: "Zatvoriť",
      logOut: "Odhlásenie",
    },
    button: {
      apply: "Aplikovať",
      continue: "Pokračovať",
      cancel: "Zrušiť",
      confirm: "Potvrdiť",
      play: "Prehrať video",
      addToWatchList: "Pridať do zoznamu sledovaných zariadení",
      removeFromWatchList: "Odstrániť zo zoznamu sledovaných zariadení",
      moreInfo: "Viac informácií",
      resume: "Obnoviť",
      close: "Zavrieť",
      discard: "Zahodiť",
      save: "Uložiť",
    },
    login: {
      header: "Prihlásenie do účtu",
      title: "Vitajte v",
      subTitle:
        "Využite svoje predplatné streamovania s Disney, Amazonom a ďalšími službami naplno.",
      button: "Prihlásenie",
      placeholder: {
        username: "Používateľské meno",
        password: "Heslo",
      },
      errors: {
        email: {
          noValid: "E-mail musí byť platný",
          required: "E-mail je povinné pole",
        },
        password: {
          required: "Heslo je povinné pole",
        },
        wrongPass: {
          first:
            "-Ale nie, to nebolo správne heslo. Skúste to prosím znova alebo kliknite na tlačidlo ",
          second: "“Zabudnuté heslo” ",
          third: "nižšie.",
        },
      },
    },
    forgotPassword: {
      message:
        "Nemajte obavy. Obnovenie hesla je jednoduché. Stačí zadať svoju e-mailovú adresu nižšie a kliknúť na tlačidlo „ODOSLAŤ“",
      button: "ODOSLAŤ",
      placeholder: {
        username: "Používateľské meno",
      },
      errors: {
        email: {
          required: "E-mail je povinné pole",
        },
        emailNotRegister: "E-mailová adresa nie je zaregistrovaná!",
        emailSent: "Odoslanie e-mailu s obnovením hesla!",
      },
    },
    profilesPage: {
      title: "Kto sleduje?",
      subTitle:
        "Každý vo vašom aute môže mať personalizovaný televízny zážitok.",
      newProfile: "+ Pridať nový profil",
    },
    newProfile: {
      title: "Nový profil",
      description: "Pridať profil pre inú osobu, ktorá sleduje ScreenHits TV",
      isKid: "Dieťa?",
      loading: "Načítavanie",
      profileCreated: "Úspešne vytvorený profil!",
      profileSelection: "Výber profilu",
    },
    settings: {
      accountDetails: {
        primary: "Podrobnosti o účte",
        secondary: "Upraviť meno držiteľa účtu a e-mail.",
      },
      manageProfiles: {
        primary: "Spravovať profily",
        secondary: "Upraviť detaily profilu",
        edit: "Upraviť profil",
      },
      parentControl: {
        primary: "Rodičovská kontrola",
        secondary: "Vytvoriť PIN / Zmeniť PIN",
        instruction: {
          primary: "Vytvoriť (aktualizovať) PIN pre rodičovskú kontrolu",
          secondary: "(Prosím, zadajte 4 číslice, kým je klávesnica otvorená)",
        },
        recoverPin: "Obnoviť PIN",
        pinSentTo: "PIN odoslaný na",
        unlock: "Odblokovať",
        pinUndefined: "PIN bol odoslaný na e-mailovú adresu"
      },
      membershipStatus: {
        primary: "Stav členstva",
        secondary: "Aktívne",
      },
      deactivateAccount: {
        primary: "Deaktivácia účtu",
        secondary: "Zatvorte svoje konto a zrušte prepojenie zariadení.",
        enterPass: "Zadajte svoje heslo",
        discard: "Zahodiť zmeny",
      },
      activeLanguage: "Aktívny jazyk",
      defaultLanguage: "Systémový jazyk",
      changeDefaultLanguage: "Zmeniť predvolený jazyk",
      privacyPolicy: {
        text: "Zásady ochrany osobných údajov",
      },
      settings: "Nastavenia",
      lang: {
        en: "Angličtina",
        it: "Taliančina Nemčina",
        de: "Nemčina",
        esar: "Španielčina(Argentina)",
        fr: "Francúzština",
        ja: "Japončina",
        ro: "Rumunčina",
        da: "Dánčina",
        nl: "Holandčina",
        ca: "Katalánčina",
        lb: "Luxemburčina",
        no: "Nórčina",
        ptbr: "Portugalčina(BR)",
        zhcn: "Čínština",
        ms: "Malajčina",
        ta: "Tamilština",
        bs: "Bosenčina",
        bg: "Bulharčina",
        hr: "Chorvátčina",
        tr: "Turečtina",
        cs: "Čeština",
        et: "Estónčina",
        fi: "Fínčina",
        el: "Gréčtina",
        hu: "Maďarčina",
        ga: "Írčina",
        is: "Islandčina",
        lv: "Lotyčina",
        lt: "Litovčina",
        mt: "Malčina",
        pl: "Poľština",
        pt: "Portugalčina",
        ru: "Ruština",
        sr: "Srbčina",
        sk: "Slovenčina(SK)",
        sl: "Slovenčina(SL)",
        sv: "Švédčina",
        mi: "Maurčina",
        ar: "Arabčina",
        af: "Africké dialekty",
        zhtw: "Čínština (Tradičná)",
        es: "Španielčina (Španielsko)",
        ko: "Kórejčina",
        enus: "Angličtina (Spojené štáty)",
        deat: "Nemčina (Rakúsko)",
        dech: "Nemčina (Švajčiarsko)",
        deli: "Nemčina (Lichtenštajnsko)",
        dede: "Nemčina (Nemecko)",
        enca: "Angličtina (Kanada)",
        engb: "Angličtina",
        enie: "Angličtina (Írsko)",
        ennz: "Angličtina (Nový Zéland)",
        enza: "Angličtina (Južná Afrika)",
        esmx: "Španielčina (Mexiko)",
        frca: "Francúzština (Kanada)",
        frch: "Francúzština (Švajčiarsko)",
        itch: "Taliančina (Švajčiarsko)",
        jajp: "Japončina (Japonsko)",
        kokr: "Kórejčina (Južná Kórea)",
        nb: "Nórčina (Bokmål)",
        nn: "Nórčina (Nynorsk)",
        zhch: "Čínština (Čínska ľudová republika)",
      },
      language: {
        primary: "Jazyk",
      },
    },
    show: {
      more: " Zobraziť viac",
      less: " Zobraziť menej",
    },
    showPage: {
      seasons: "Sezóny",
      cast: "Obsadenie",
      producers: "Výrobcovia",
      genre: "Žáner",
      directedBy: "Réžia",
      season: "Sezóna",
    },
    accountDetails: {
      firstName: "Krstné meno",
      lastName: "Priezvisko",
      email: "E-mail",
      password: "Heslo",
      changePassword: "Zmena hesla",
      forgotPassword: "Zabudnuté heslo",
    },
    childPinPopup: {
      title: "Ochrana detí",
      subTitle: "Vytvorenie (aktualizácia) kódu PIN pre ochranu detí",
      pinUpdated: "PIN kód bol úspešne aktualizovaný",
      pinIsIncorrect: "PIN je nesprávny",
    },
    deactivateAccount: {
      title: "Ste si istí, že chcete deaktivovať svoje konto?",
      description:
        "Keď tak urobíte, budete odhlásení zo všetkých služieb a nebudete môcť prezerať obsah vo svojom aute.",
      deactivateButton: "Deaktivovať účet",
    },
    servicesPage: {
      add: "Pridať do služieb",
      remove: "Odstrániť zo služieb",
      overflow: {
        title: "Vybrané balíky",
        subtitle: "Vyberte svoje služby",
        description: "Pridajte do sprievodcu všetky služby, ktoré používate.",
      },
    },
    manageProfile: {
      text: "Správa profilov",
    },
    watchlist: {
      title:
        "Tu sú všetky filmy a seriály, ktoré ste pridali do svojho zoznamu sledovaných",
      noTitles: "Momentálne nemáte v zozname sledovaných titulov žiadne tituly",
      addText:
        "Ak chcete pridať, kliknite na + Pridať do zoznamu sledovaných relácií, ku ktorým sa chcete vrátiť a pozrieť si ich neskôr..",
      subtext: {
        start: "Ak chcete pridať, kliknite na ",
        toWatchList: "Pridať do zoznamu sledovaných zariadení",
        end: "alebo akýkoľvek program, ku ktorému by ste sa chceli vrátiť a pozrieť si ho neskôr.",
      },
    },
    streamingService: {
      title: "Výber streamovacích služieb",
      subTitle:
        "Pridanie vybraných služieb, ktoré sa zobrazia v televíznom sprievodcovi",
      streamingServices: "Streamovacie služby",
    },
    profileUpdate: {
      backToSettings: "Späť na nastavenia",
      updatedSuccess: "Používateľ bol úspešne aktualizovaný",
      apply: "Aplikovať",
      wrongPassword: "Nesprávne heslo",
    },
    changePasswordPage: {
      backToSettings: "Späť na nastavenia",
      currentPass: "Aktuálne heslo",
      newPass: "Nové heslo",
      confirmPass: "Potvrdenie hesla",
      change: "Zmena",
      changeUserInfo: "Zmena informácií o používateľovi",
      passwordDoNotMatch: "Heslá sa nezhodujú alebo sú neplatné",
      passwordUpdated: "Heslo bolo úspešne aktualizované",
    },
    noServices: {
      title: "Momentálne nemáte vo svojom TV programe žiadne služby",
      text: "Ak chcete zo ScreenHits TV vyťažiť maximum, musíte pridať služby. Kliknite sem a pridajte streamery, ktoré už odoberáte, a to bez ďalších poplatkov. A ak momentálne nie ste predplatiteľom žiadnej streamovacej služby, pridajte si náš BEZPLATNÝ balík a začnite objavovať skvelý obsah.",
      button: "Pridať služby",
    },
    deleteProfile: {
      cantDeleteProfile: "Nemôžete odstrániť svoj aktuálny profil",
      areYouSure: "Naozaj chcete odstrániť tento profil?",
      delete: "Odstrániť profil",
    },
    notFoundPage: {
      title: "Niečo sa pokazilo",
      text: "Nebojte sa však, všetko je v poriadku. Vrátime sa s touto stránkou.",
      home: "Úvodná stránka",
    },
    noInternet: {
      title: "Žiadne pripojenie k internetu",
      description:
        "Nemajte obavy, všetko je v poriadku. Vrátime sa s touto stránkou.",
      button: "Obnoviť",
    },
  },
};

export default SK_RESOURCE;
