import React, { FC, ReactNode, useEffect, useState, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "store";
import { setAuthorizationHeader } from "api/apiClient";
import { authApiUrl } from "api/authApi";
import {
  getUserDataFromLocalStorage,
  setUserDataToLocalStorage,
} from "helpers/localStorage";
import { appLinks } from "routes/routes";
import { setUserData } from "store/actions";
import { UserData } from "types/Auth";
import BackdropLoader from "components/Loaders/BackdropLoader";
import axios from "axios";
import Cookies from "js-cookie";

interface Props {
  children: ReactNode;
}

// Create an Axios instance for authentication API calls
const authInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const AuthWithHash: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const { dispatch } = useAppContext();
  const currentUser = getUserDataFromLocalStorage();

  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deviceHash, setDeviceHash] = useState<string | undefined>(undefined);
  const [isUidExists, setIsUidExist] = useState(false);

  const uidValue = searchParams.get(
    process.env.REACT_APP_UID_COOKIE_NAME || "uid"
  );

  useEffect(() => {   
    if (uidValue) {
      setIsUidExist(true);
      authInstance.defaults.headers.common["uid"] = uidValue;
    } else {
      if (!currentUser) {       
        navigate(appLinks.uidError.link, { replace: true });
        setIsUidExist(false);
      }    
    }
  }, []);

  // Fetch device hash from the server
  const getDeviceHash = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await authInstance.get<{ guid: string }>(
        authApiUrl.getDeviceHash
      );
      setDeviceHash(response.data.guid);
    } catch (error) {
      console.error("Failed to fetch device hash:", error);
      navigate(appLinks.uidError.link, { replace: true });
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);

  // Authenticate the user using the device hash
  const authenticateUser = useCallback(async () => {
    if (!deviceHash) return;
    setIsLoading(true);
    try {
      const response = await authInstance.post<UserData>(
        authApiUrl.authenticateV2,
        { hash: deviceHash }
      );

      if (response.data) {
        const userData = response.data;
        setAuthorizationHeader(userData.token);
        dispatch(setUserData(userData));
        setUserDataToLocalStorage(userData);
        navigate(appLinks.profiles.link, { replace: true });
      } else {
        console.warn("No user data received after authentication.");
        navigate(appLinks.uidError.link, { replace: true });
      }
    } catch (error) {
      console.error("Authentication failed:", error);
      navigate(appLinks.uidError.link, { replace: true });
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, navigate, deviceHash]);

  useEffect(() => {   
    if (!currentUser && isUidExists) {
      getDeviceHash();
    }
  }, [getDeviceHash, currentUser, isUidExists]);

  useEffect(() => {      
    if (deviceHash && !currentUser) {
      authenticateUser();
    }
  }, [deviceHash, authenticateUser, currentUser]);

  return isLoading && !currentUser ? <BackdropLoader /> : <>{children}</>;
};

export default AuthWithHash;
