const PT_BR_RESOURCE = {
  translation: {
    loading: "Carregando",
    search: {
      cancel: "Cancelar",
      noResults:
        "Nenhum resultado encontrado, mas vale a pena conferir as sugestões abaixo.",
    },
    menu: {
      switchProfiles: "Alterar perfil",
      settings: "Ajustes",
      close: "Fechar",
      logOut: "Desconectar",
    },
    button: {
      apply: "Aplicar",
      continue: "Continuar",
      cancel: "Cancelar",
      confirm: "Confirmar",
      play: "Reproduzir",
      addToWatchList: "Adicionar aos meus favoritos",
      removeFromWatchList: "Remover dos meus favoritos",
      moreInfo: "Mais informações",
      resume: "Retomar",
      close: "Fechar",
      discard: "Descartar",
      save: "Salvar",
    },
    login: {
      header: "Login de conta",
      title: "Bem-vindo a",
      subTitle:
        "Obtenha o máximo das suas assinaturas de streaming com Netflix, Disney, Amazon e muito mais.",
      text: "Tudo o que você quer ver em um só lugar.",
      button: "Entrar",
      placeholder: {
        username: "Nome de usuário",
        password: "Senha",
      },
      errors: {
        email: {
          noValid: "O e-mail deve ser um e-mail válido.",
          required: "É obrigatório fornecer um e-mail.",
        },
        password: {
          required: "É obrigatório fornecer uma senha.",
        },
        wrongPass: {
          first:
            "Ops, essa não é a senha correta. Tente novamente ou clique em ",
          second: "“Esqueci minha senha” ",
          third: "abaixo.",
        },
      },
    },
    forgotPassword: {
      message:
        'Não se preocupe. É fácil recuperar sua senha. Basta digitar seu e-mail abaixo e clicar em "ENVIAR"',
      button: "ENVIAR",
      placeholder: {
        username: "Nome de usuário",
      },
      errors: {
        email: {
          required: "O e-mail é um campo obrigatório!",
        },
        emailNotRegister: "E-mail não registrado!",
        emailSent: "E-mail de redefinição de senha enviado!",
      },
    },
    profilesPage: {
      title: "Quem está assistindo?",
      subTitle:
        "Todos no seu carro podem ter uma experiência de TV personalizada.",
      newProfile: "+ Adicionar novo perfil",
    },
    newProfile: {
      title: "Novo perfil",
      description: "Adicione um perfil para outra pessoa que está assistindo à ScreenHits TV",
      isKid: "Criança?",
      loading: "Está carregando",
      profileCreated: "O perfil foi criado!",
      profileSelection: "Seleção do perfil",
    },
    settings: {
      accountDetails: {
        primary: "Detalhes da conta",
        secondary: "Edite o nome e o e-mail do do titular da conta.",
      },
      manageProfiles: {
        primary: "Gerenciar perfis",
        secondary: "Editar detalhes do perfil",
        edit: "Editar perfil",
      },
      parentControl: {
        primary: "Controle dos pais",
        secondary: "Criar PIN / Alterar PIN",
        instruction: {
          primary: "Criar (atualizar) PIN para Controle Parental",
          secondary:
            "(Por favor, insira 4 dígitos enquanto o teclado está aberto)",
        },
        recoverPin: "Recuperar PIN",
        pinSentTo: "PIN enviado para",
        unlock: "Desbloquear",
        pinUndefined: "PIN enviado para o endereço de e-mail"
      },
      membershipStatus: {
        primary: "Status de associação",
        secondary: "Ativo",
      },
      deactivateAccount: {
        primary: "Desativar conta",
        secondary: "Feche sua conta e desvincule seus dispositivos.",
        enterPass: "Digite sua senha",
        discard: "Descartar alterações",
      },
      activeLanguage: "Idioma ativo",
      defaultLanguage: "Idioma do sistema",
      changeDefaultLanguage: "Alterar idioma padrão",
      privacyPolicy: {
        text: "Política de Privacidade",
      },
      settings: "Ajustes",
      lang: {
        en: "Inglês",
        it: "Italiano",
        de: "Alemão",
        esar: "Espanhol(Argentina)",
        fr: "Francês",
        ja: "Japonês",
        ro: "Romeno",
        da: "Dinamarquês",
        nl: "Holandês",
        ca: "Catalão",
        lb: "Luxemburguês",
        no: "Norueguês",
        ptbr: "Português(BR)",
        zhcn: "Chinês",
        ms: "Malaio",
        ta: "Tâmil",
        bs: "Bósnio",
        bg: "Búlgaro",
        hr: "Croata",
        tr: "Turco",
        cs: "Tcheca",
        et: "Estoniano",
        fi: "Finlandês",
        el: "Grego",
        hu: "Húngaro",
        ga: "Irlandês",
        is: "Islandês",
        lv: "Letão",
        lt: "Lituano",
        mt: "Maltês",
        pl: "Polonês",
        pt: "Português(PT)",
        ru: "Russo",
        sr: "Sérvio",
        sk: "Eslovaco",
        sl: "Esloveno",
        sv: "Sueco",
        mi: "Māori",
        ar: "Árabe",
        af: "Afrikaans",
        zhtw: "Chinês (Tradicional)",
        es: "Espanhol (Espanha)",
        ko: "Coreano",
        enus: "Inglês (Estados Unidos)",
        deat: "Alemão (Áustria)",
        dech: "Alemão (Suíça)",
        deli: "Alemão (Liechtenstein)",
        dede: "Alemão (Alemanha)",
        enca: "Inglês (Canadá)",
        engb: "Inglês",
        enie: "Inglês (Irlanda)",
        ennz: "Inglês (Nova Zelândia)",
        enza: "Inglês (África do Sul)",
        esmx: "Espanhol (México)",
        frca: "Francês (Canadá)",
        frch: "Francês (Suíça)",
        itch: "Italiano (Suíça)",
        jajp: "Japonês (Japão)",
        kokr: "Coreano (Coreia do Sul)",
        nb: "Norueguês (Bokmål)",
        nn: "Norueguês (Nynorsk)",
        zhch: "Chinês (RPC)",
      },
      language: {
        primary: "Idioma",
      },
    },
    show: {
      more: " Mostre mais",
      less: " Mostre menos",
    },
    showPage: {
      seasons: "Temporadas",
      cast: "Elenco",
      producers: "Produtores",
      genre: "Gênero",
      directedBy: "Direção",
      season: "Temporada",
    },
    accountDetails: {
      firstName: "Nome",
      lastName: "Sobrenome",
      email: "E-mail",
      password: "Senha",
      changePassword: "Mudar senha",
      forgotPassword: "Esqueceu a senha?",
    },
    childPinPopup: {
      title: "Proteção infantil",
      subTitle: "Criar PIN/Alterar PIN",
      pinUpdated: "O PIN foi atualizado",
      pinIsIncorrect: "PIN está incorreto",
    },
    deactivateAccount: {
      title: "Você tem certeza de que deseja desativar sua conta?",
      description:
        "Depois disso, você será desconectado de todos os serviços, e não poderá mais visualizar o conteúdo do seu veículo.",
      deactivateButton: "Desativar conta",
    },
    servicesPage: {
      add: "Adicionar aos seus serviços de streaming",
      remove: "Remover dos seus serviços de streaming",
      overflow: {
        title: "Pacotes selecionados",
        subtitle: "Selecionar serviços de streaming",
        description: "Adicione cada um dos serviços de streaming que você usa",
      },
    },
    manageProfile: {
      text: "Gerenciar perfis",
    },
    watchlist: {
      title:
        "Aqui estão todos os filmes e programas que você adicionou aos seus favoritos.",
      noTitles: "Você não tem títulos nos favoritos.",
      addText:
        "Para adicionar, clique em +Adicionar a favoritos o filme ou série que você deseja assistir mais tarde.",
      subtext: {
        start: "Para adicionar, clique em  ",
        toWatchList: "Adicionar aos favoritos",
        end: "o filme ou série que você deseja assistir mais tarde.",
      },
    },
    streamingService: {
      title: "Escolher serviços de streaming",
      subTitle:
        "Adicione os serviços selecionados para aparecer no seu Guia de TV",
      streamingServices: "Serviços de streaming",
    },
    profileUpdate: {
      backToSettings: "Voltar para ajustes",
      updatedSuccess: "O usuário foi atualizado",
      apply: "Aplicar",
      wrongPassword: "Senha incorreta",
    },
    changePasswordPage: {
      backToSettings: "Voltar para ajustes",
      currentPass: "Senha atual",
      newPass: "Nova senha",
      confirmPass: "Confirmar senha",
      change: "Alterar",
      changeUserInfo: "Alterar informações do usuário",
      passwordDoNotMatch: "As senhas não correspondem ou são inválidas",
      passwordUpdated: "Senha atualizada",
    },
    noServices: {
      title: "No momento, você não tem nenhum serviço no seu Guia de TV",
      text: "Para tirar o máximo proveito do ScreenHits TV, você precisa adicionar serviços.  Clique aqui para adicionar os canais de streaming que você já assina sem nenhum custo extra. E se você ainda não assina nenhum serviço de streaming, adicione nosso pacote GRATUITO e comece a descobrir um ótimo conteúdo de BBC, ITV, My5, Channel 4, UKTV e STV.",
      button: "Adicionar serviços de streaming",
    },
    deleteProfile: {
      cantDeleteProfile: "Você não pode excluir seu perfil atual",
      areYouSure: "Tem certeza de que deseja excluir este perfil?",
      delete: "Excluir perfil",
    },
    notFoundPage: {
      title: "Algo deu errado",
      text: "Não se preocupe, porém, está tudo bem. Voltaremos com esta página.",
      home: "Pagina inicial",
    },
    noInternet: {
      title: "Sem Conexão com a Internet",
      description:
        "Não se preocupe, tudo está bem. Voltaremos com esta página.",
      button: "Recarregar",
    },
  },
};

export default PT_BR_RESOURCE;
