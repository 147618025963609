import { Box, Typography } from "@mui/material";
import AlertIcon from "components/icons/AlertIcon";
import { FC } from "react";

const AuthErrorAlert: FC = (): JSX.Element => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundImage:
          "url(https://siteassets.blob.core.windows.net/devassets/Images/background_image_1800_720.jpg)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        padding: 3,
        "& p": {
          maxWidth: 903,
          fontSize: 33,
          fontFamily: "NouvelR", // Corrected CSS syntax
          fontWeight: "normal",
          lineHeight: 1.24,
          textAlign: "left",
          color: "#ebebeb",
        },
        "& a": {
          color: "#0e79f9",
          textDecoration: "none",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "40px",
          alignItems: "center",
          mb: "20px",
        }}
      >
        <AlertIcon
          sx={{
            fontSize: "38px",
          }}
        />
        <Typography variant="h3" fontSize={38} fontWeight="bold">
          Error
        </Typography>
      </Box>
      <Typography component="p">
        We are currently unable to connect to the ScreenHits TV. Please contact
        us at <a href="mailto:support@renault.com">support@renault.com</a> for
        assistance.
      </Typography>
    </Box>
  );
};

export default AuthErrorAlert;
