import {
  DeactivateUserParams,
  UserUpdateParams,
  UpdateUserPasswordParams,
} from "./../types/Auth";
import { apiClient, publicApiClient } from "./apiClient";
import {
  LoginParams,
  LoginPromise,
  UserData,
  RememberPasswordPromise,
  RememberPasswordResponse,
  ParentProtectionPromise,
  ParentControlResponse,
  QRDetailsPromise,
  QRDetailsResponse,
} from "types/Auth";
import ENDPOINTS from "./endpoints";
import { CurrentUserProfileResponse, CurrentUserPromise } from "types/Profile";

const endpoint = ENDPOINTS.AUTH_USER;
const endpointV2 = ENDPOINTS.AUTH_USER_V2;

const authApiUrl = {
  signIn: `${endpoint}/authenticate`,
  setProfile: `${endpoint}/setprofile`,
  rememberPassword: `${endpoint}/forgotpassword`,
  checkPin: `${endpoint}/checkpin`,
  qrDetails: `${endpoint}/getqrdetails`,
  tryAuth: (guid: string): string => `${endpoint}/tryauthenticate?g=${guid}`,
  deactivateUser: `${endpoint}/deactivate`,
  setPin: `${endpoint}/setpin`,
  recoverPin: `${endpoint}/pinrecover`,
  userUpdate: `${endpoint}/update`,
  updatePassword: `${endpoint}/updatepassword`,
  logout: `${endpoint}/logout`,
  getDeviceHash: `${endpointV2}/getDeviceHash`,
  authenticateV2: `${endpointV2}/v2/authenticate`,
};

const getDeviceHash = (): Promise<{ guid: string }> =>
  publicApiClient
    .get<{ guid: string }>(authApiUrl.getDeviceHash)
    .then((res) => res.data);

const signIn = ({ Email, Password }: LoginParams): LoginPromise =>
  apiClient
    .post<UserData>(authApiUrl.signIn, {
      Email,
      Password,
    })
    .then((res) => res.data);

const setCurrentProfile = (id: number): CurrentUserPromise =>
  apiClient
    .post<CurrentUserProfileResponse>(authApiUrl.setProfile, { ProfileId: id })
    .then((res) => res.data);

const rememberPassword = (Email: string): RememberPasswordPromise =>
  apiClient
    .post<RememberPasswordResponse>(authApiUrl.rememberPassword, { Email })
    .then((res) => res.data);

const checkParentControlPassword = (
  pin: number | string
): ParentProtectionPromise =>
  apiClient
    .post<ParentControlResponse>(authApiUrl.checkPin, { pin })
    .then((res) => res.data);

const setParentControlPassword = (pin: number, email: string): ParentProtectionPromise =>
  apiClient
    .post<ParentControlResponse>(authApiUrl.setPin, { pin, email })
    .then((res) => res.data);

const recoverPin = (): Promise<void> =>
  apiClient.post<void>(authApiUrl.recoverPin).then((res) => res.data);

const getQrDetails = (): QRDetailsPromise =>
  publicApiClient
    .get<QRDetailsResponse>(authApiUrl.qrDetails)
    .then((res) => res.data);

const tryAuthenticate = (guid: string): LoginPromise =>
  publicApiClient
    .get<UserData>(authApiUrl.tryAuth(guid), {
      params: {
        q: guid,
      },
    })
    .then((res) => res.data);

const deactivateUser = (params: DeactivateUserParams): Promise<void> =>
  apiClient
    .post<void>(authApiUrl.deactivateUser, params)
    .then((res) => res.data);

const userUpdate = (params: UserUpdateParams): LoginPromise =>
  apiClient
    .post<UserData>(authApiUrl.userUpdate, params)
    .then((res) => res.data);

const updatePassword = (params: UpdateUserPasswordParams): Promise<void> =>
  apiClient
    .post<void>(authApiUrl.updatePassword, params)
    .then((res) => res.data);

const logout = (sessionId: string): Promise<void> =>
  apiClient
    .post<void>(authApiUrl.logout, { SessionId: sessionId })
    .then((res) => res.data);

export {
  authApiUrl,
  signIn,
  setCurrentProfile,
  rememberPassword,
  checkParentControlPassword,
  setParentControlPassword,
  recoverPin,
  getQrDetails,
  tryAuthenticate,
  deactivateUser,
  userUpdate,
  updatePassword,
  logout,
  getDeviceHash,
};
