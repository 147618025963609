const RO_RESOURCE = {
  translation: {
    loading: "Se incarcă",
    search: {
      cancel: "Anulare",
      noResults:
        "Căutarea nu a generat niciun rezultat, dar cele de mai jos merită cu siguranță vizionate.",
    },
    menu: {
      switchProfiles: "Schimbare profil",
      settings: "Setări",
      close: "Închidere",
      logOut: "Ieșire din cont",
    },
    button: {
      apply: "Aplicare",
      continue: "Continuare",
      cancel: "Anulare",
      confirm: "Confirmare",
      play: "Redare",
      addToWatchList: "Adăugare la Lista de urmărire",
      removeFromWatchList: "Ștergere din Lista de urmărire",
      moreInfo: "Mai multe informații",
      resume: "Reluare vizionare",
      close: "Închidere",
      discard: "Renunță",
      save: "Salvează",
    },
    login: {
      header: "Autentificare",
      title: "Bine ai venit pe",
      subTitle:
        "Profită la maximum de abonamentele tale de streaming cu Disney, Amazon și multe altele.",
      text: "Tot ce dorești să urmărești într-un singur loc.",
      button: "Conectare",
      placeholder: {
        username: "Nume utilizator",
        password: "Parolă",
      },
      errors: {
        email: {
          noValid: "E-mailul trebuie să fie un e-mail valid",
          required: "E-mail-ul este un câmp obligatoriu",
        },
        password: {
          required: "Parola este un câmp obligatoriu",
        },
        wrongPass: {
          first:
            "Ne pare rău, parola nu este corectă. Te rugăm să încerci din nou sau fă clic pe butonul",
          second: "“Am uitat parola” ",
          third: "de mai jos.",
        },
      },
    },
    forgotPassword: {
      message:
        "Nu îți fă griji. Resetarea parolei este ușoară. Trebuie doar să îți introduci adresa de e-mail mai jos și să faci clic pe „ TRIMITE”.",
      button: "TRIMITE",
      placeholder: {
        username: "Nume utilizator",
      },
      errors: {
        email: {
          required: "E-mail-ul este un câmp obligatoriu",
        },
        emailNotRegister: "Adresa de e-mail nu este înregistrată!",
        emailSent: "S-a trimis e-mail de resetare a parolei!",
      },
    },
    profilesPage: {
      title: "Cine se uită?",
      subTitle: "Toți din mașina ta pot avea o experiență TV personalizată.",
      newProfile: "+ Adaugă un profil nou",
    },
    newProfile: {
      title: "Profil Nou",
      description: "Adaugă un profil pentru o altă persoană care urmărește Screen Hits TV",
      isKid: "Copil?",
      loading: "În curs de încărcare",
      profileCreated: "Profil creat cu succes!",
      profileSelection: "Selectarea profilului",
    },
    settings: {
      accountDetails: {
        primary: "Detalii cont",
        secondary: "Editează numele titularului de cont și adresa de e-mail.",
      },
      manageProfiles: {
        primary: "Administrare profiluri",
        secondary: "Editare detalii profil",
        edit: "Editare profil",
      },
      parentControl: {
        primary: "Control Parental",
        secondary: "Creează PIN / Schimbă PIN",
        instruction: {
          primary: "Creează (actualizează) PIN pentru Controlul Parental",
          secondary:
            "(Vă rugăm să introduceți 4 cifre cât timp tastatura este deschisă)",
        },
        recoverPin: "Resetează PIN-ul",
        pinSentTo: "PIN trimis la",
        unlock: "Deblochează",
        pinUndefined: "PIN trimis la adresa de e-mail"
      },
      membershipStatus: {
        primary: "Statutul de membru",
        secondary: "Activ",
      },
      deactivateAccount: {
        primary: "Dezactivare cont",
        secondary: "Închideți contul și deconectați dispozitivele.",
        enterPass: "Introduceți parola dvs.",
        discard: "Renunțați la modificări",
      },
      activeLanguage: "Limba activă",
      defaultLanguage: "Limba sistemului",
      changeDefaultLanguage: "Schimbă limba implicită",
      privacyPolicy: {
        text: "Politica de confidențialitate",
      },
      settings: "Setări",
      lang: {
        en: "Engleză",
        it: "Italiană",
        de: "Germană",
        esar: "Spaniolă (Argentina)",
        fr: "Franceză",
        ja: "Japoneză",
        ro: "Română",
        da: "Daneză",
        nl: "Olandeză",
        ca: "Catalană",
        lb: "Luxemburgheză",
        no: "Norvegiană",
        ptbr: "Portugheză(BR)",
        zhcn: "Chineză",
        ms: "Malay",
        ta: "Tamil",
        bs: "Bosniacă",
        bg: "Bulgară",
        hr: "Croată",
        tr: "Turcă",
        cs: "Cehă",
        et: "Estoniană",
        fi: "Finlandeză",
        el: "Greacă",
        hu: "Maghiară",
        ga: "Irlandeză",
        is: "Islandeză",
        lv: "Letonă",
        lt: "Lituaniană",
        mt: "Malteză",
        pl: "Poloneză",
        pt: "Portugheză(PT)",
        ru: "Rusă",
        sr: "Sârbă",
        sk: "Slovacă",
        sl: "Slovenă",
        sv: "Suedeză",
        mi: "Māori",
        ar: "Arabă",
        af: "Afrikaans",
        zhtw: "Chineză (tradițională)",
        es: "Spaniolă(Spania)",
        ko: "Coreeană",
        enus: "Engleză (Statele Unite)",
        deat: "Germană (Austria)",
        dech: "Germană (Elveția)",
        deli: "Germană (Liechtenstein)",
        dede: "Germană (Germania)",
        enca: "Engleză (Canada)",
        engb: "Engleză",
        enie: "Engleză (Irlanda)",
        ennz: "Engleză (Noua Zeelandă)",
        enza: "Engleză (Africa de Sud)",
        esmx: "Spaniolă (Mexic)",
        frca: "Franceză (Canada)",
        frch: "Franceză (Elveția)",
        itch: "Italiană (Elveția)",
        jajp: "Japoneză (Japonia)",
        kokr: "Coreeană (Coreea de Sud)",
        nb: "Norvegiană (Bokmål)",
        nn: "Norvegiană (Nynorsk)",
        zhch: "Chineză (RPC)",
      },
      language: {
        primary: "Limba",
      },
    },
    show: {
      more: " Arată mai mult",
      less: " Arată mai puțin",
    },
    showPage: {
      seasons: "Sezoane",
      cast: "Casting",
      producers: "Producători",
      genre: "Gen",
      directedBy: "Regizat de",
      season: "Sezon",
    },
    accountDetails: {
      firstName: "Prenume",
      lastName: "Nume",
      email: "E-mail",
      password: "Parola",
      changePassword: "Schimbă parola",
      forgotPassword: "Am uitat parola",
    },
    childPinPopup: {
      title: "Control Parental",
      subTitle: "Crearea (actualizarea) PIN-ului pentru Control Parental",
      pinUpdated: "PIN actualizat cu succes",
      pinIsIncorrect: "PIN-ul este incorect",
    },
    deactivateAccount: {
      title: "Ești sigur că dorești să îți dezactivezi contul?",
      description:
        "Dacă îl dezactivezi, vei fi deconectat (ă) de la toate serviciile și nu vei mai putea vizualiza conținutul când te afli în mașină",
      deactivateButton: "Dezactivarea contului",
    },
    servicesPage: {
      add: "Adăugare la servicii",
      remove: "Eliminare din servicii",
      overflow: {
        title: "Pachete selectate",
        subtitle: "Selectează serviciile tale",
        description:
          "Adăugă fiecare serviciu pe care îl utilizezi la ghidul tău.",
      },
    },
    manageProfile: {
      text: "Gestionarea profilurilor",
    },
    watchlist: {
      title:
        "Iată toate filmele și emisiunile pe care le-ai adăugat la Lista de urmărire",
      noTitles: "În prezent nu ai niciun titlu în Lista de urmărire",
      addText:
        "Pentru a adăuga, te rugăm să faci clic pe + Adaugă la Lista de urmărire pe orice show la care dorești să revii pentru a îl urmări mai târziu.",
      subtext: {
        start: "Pentru a adăuga, te rugăm să faci clic pe ",
        toWatchList: "Adaugă la Lista de urmărire",
        end: "pe orice show la care dorești să revii pentru a îl urmări mai târziu.",
      },
    },
    streamingService: {
      title: "Alege Serviciile de Streaming",
      subTitle: "Adaugă serviciile selectate pentru a apărea în Ghidul TV",
      streamingServices: "Servicii de Streaming",
    },
    profileUpdate: {
      backToSettings: "Revenire la Setări",
      updatedSuccess: "Utilizatorul a fost actualizat cu succes",
      apply: "Aplicare",
      wrongPassword: "Parolă greșită",
    },
    changePasswordPage: {
      backToSettings: "Revenire la Setări",
      currentPass: "Parola actuală",
      newPass: "Parolă nouă",
      confirmPass: "Confirmă parola",
      change: "Schimbă",
      changeUserInfo: "Modifică informațiile utilizatorului",
      passwordDoNotMatch: "Parolele nu se potrivesc sau nu sunt valide",
      passwordUpdated: "Parola a fost actualizată cu succes",
    },
    noServices: {
      title: "Nu aveți servicii în Ghidul TV",
      text: "Pentru a profita la maximum de ScreenHits TV, trebuie să adăugi servicii. Fă clic aici pentru a adăuga streamerii la care ești deja abonat(ă), fără costuri suplimentare. Și dacă nu dorești să te abonezi la niciun serviciu de streaming, adaugă pachetul nostru GRATUIT pentru a începe să descoperi un conținut grozav oferit de BBC, ITV, My5, Channel 4, UKTV și STV.",
      button: "Adăugare servicii",
    },
    deleteProfile: {
      cantDeleteProfile: "Nu poți șterge profilul actual",
      areYouSure: "Sigur dorești să ștergi acest profil?",
      delete: "Șterge profilul",
    },
    notFoundPage: {
      title: "Ceva nu a mers bine",
      text: "Nu-ți face griji, totuși, totul este în regulă. Vom reveni cu această pagină.",
      home: "Acasă",
    },
    noInternet: {
      title: "Nicio conexiune la internet",
      description:
        "Nu vă faceți griji, totul este în regulă. Vom reveni cu această pagină.",
      button: "Reîncarcă",
    },
  },
};

export default RO_RESOURCE;
