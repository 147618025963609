const MS_RESOURCE = {
  translation: {
    loading: "Pemuatan",
    search: {
      cancel: "Batal",
      noResults:
        "Tiada hasil carian ditemui, tetapi hasil carian di bawah pasti berbaloi untuk diperiksa.",
    },
    menu: {
      switchProfiles: "Tukar Profil",
      settings: "Tetapan",
      close: "Tutup",
      logOut: "Log keluar",
    },
    button: {
      apply: "Aplikasikan",
      continue: "Teruskan",
      cancel: "Batal",
      confirm: "Sahkan",
      play: "Main",
      addToWatchList: "Tambah kepada senarai tontonan",
      removeFromWatchList: "Hapus dari Senarai tontonan",
      moreInfo: "Maklumat lanjut",
      resume: "Sambung",
      close: "Tutup",
      discard: "Buang",
      save: "Simpan",
    },
    login: {
      header: "Log Masuk Akaun",
      title: "Selamat datang ke",
      subTitle:
        "Manfaatkan sepenuhnya langganan penstriman anda dengan Disney, Amazon dan banyak lagi.",
      text: "Semua yang anda mahu tonton di satu tempat.",
      button: "Log masuk",
      placeholder: {
        username: "Nama pengguna",
        password: "Kata laluan",
      },
      errors: {
        email: {
          noValid: "E-mel mestilah alamat e-mel yang sah",
          required: "E-mel ialah medan yang diperlukan",
        },
        password: {
          required: "Kata laluan ialah medan yang diperlukan",
        },
        wrongPass: {
          first:
            "Maaf, itu bukan kata laluan yang betul. Sila cuba lagi atau klik pada butang ",
          second: '"Lupa Kata Laluan"',
          third: "di bawah..",
        },
      },
    },
    forgotPassword: {
      message:
        'Jangan risau. Menetapkan semula kata laluan anda adalah mudah. Hanya masukkan alamat e-mel anda di bawah dan klik "HANTAR"',
      button: "HANTAR",
      placeholder: {
        username: "Nama pengguna",
      },
      errors: {
        email: {
          required: "E-mel ialah medan yang diperlukan",
        },
        emailNotRegister: "Alamat E-mel tidak terdaftar!",
        emailSent: "E-mel untuk tetapkan semula kalaa lalauan telah dihantar!",
      },
    },
    profilesPage: {
      title: "Siapa Yang Menonton?",
      subTitle:
        "Setiap orang dalam kereta anda boleh memiliki pengalaman TV yang dipersonalisasikan.",
      newProfile: "+ Tambah profil baru",
    },
    newProfile: {
      title: "Profil Baru",
      description: "Tambah profil untuk orang lain yang menonton ScreenHits TV",
      isKid: "Kanak-kanak?",
      loading: "Pemuatan",
      profileCreated: "Profil berjaya dibuat!",
      profileSelection: "Pemilihan Profil",
    },
    settings: {
      accountDetails: {
        primary: "Butiran akaun",
        secondary: "Edit nama pemegang akaun dan e-mel.",
      },
      manageProfiles: {
        primary: "Urus Profil",
        secondary: "Edit butiran profil",
        edit: "Edit Profil",
      },
      parentControl: {
        primary: "Kawalan Ibu Bapa",
        secondary: "Cipta PIN / Tukar PIN",
        instruction: {
          primary: "Cipta (kemaskini) PIN untuk Kawalan Ibu Bapa",
          secondary: "(Sila masukkan 4 digit semasa papan kekunci dibuka)",
        },
        recoverPin: "Set Semula PIN",
        pinSentTo: "PIN dihantar ke",
        unlock: "Buka kunci",
        pinUndefined: "PIN dihantar ke alamat e-mel"
      },
      membershipStatus: {
        primary: "Status Keahlian",
        secondary: "Aktif",
      },
      deactivateAccount: {
        primary: "Nyahaktif akaun",
        secondary: "Tutup akaun anda dan putuskan hubungan peranti anda.",
        enterPass: "Masukkan kata laluan anda",
        discard: "Buang perubahan",
      },
      activeLanguage: " Bahasa Aktif",
      defaultLanguage: " Bahasa Sistem",
      changeDefaultLanguage: "Tukar bahasa lalai",
      privacyPolicy: {
        text: "Dasar Privasi",
      },
      settings: "Tetapan",
      lang: {
        en: "Inggeris",
        it: "Itali",
        de: "Jerman",
        esar: "Sepanyol(Argentina)",
        fr: "Perancis",
        ja: "Jepun",
        ro: "Romania",
        da: "Denmark",
        nl: "Belanda",
        ca: "Catalan",
        lb: "Luxembourg",
        no: "Norway",
        ptbr: "Portugis(BR)",
        zhcn: "Cina",
        ms: "Melayu",
        ta: "Tamil",
        bs: "Bosnia",
        bg: "Bulgaria",
        hr: "Croatia",
        tr: "Turki",
        cs: "Czech",
        et: "Estonia",
        fi: "Finland",
        el: "Yunani",
        hu: "Hungary",
        ga: "Ireland",
        is: "Iceland",
        lv: "Latvia",
        lt: "Lithuania",
        mt: "Malta",
        pl: "Poland",
        pt: "Portugis(PT)",
        ru: "Rusia",
        sr: "Serbia",
        sk: "Slovak",
        sl: "Slovenia",
        sv: "Sweden",
        mi: "Māori",
        ar: "Arab",
        af: "Afrikaans",
        zhtw: "Cina(Tradisional)",
        es: "Sepanyol(Sepanyol)",
        ko: "Korea",
        enus: "Inggeris (Amerika Syarikat)",
        deat: "Jerman (Austria)",
        dech: "Jerman (Switzerland)",
        deli: "Jerman (Liechtenstein)",
        dede: "Jerman (Jerman)",
        enca: "Inggeris (Kanada)",
        engb: "Inggeris",
        enie: "Inggeris (Ireland)",
        ennz: "Inggeris (New Zealand)",
        enza: "Inggeris (Afrika Selatan)",
        esmx: "Sepanyol (Mexico)",
        frca: "Perancis (Kanada)",
        frch: "Perancis (Switzerland)",
        itch: "Itali (Switzerland)",
        jajp: "Jepun (Jepun)",
        kokr: "Korea (Korea Selatan)",
        nb: "Norway (Bokmål)",
        nn: "Norway (Nynorsk)",
        zhch: "Cina (PRC)",
      },
      language: {
        primary: "Bahasa",
      },
    },
    show: {
      more: " Tunjukkan lebih lagi",
      less: " Tunjukkan sedikit",
    },
    showPage: {
      seasons: "Musim",
      cast: "Pelakon",
      producers: "Penerbit",
      genre: "Genre",
      directedBy: "Diarahkan oleh",
      season: "Musim",
    },
    accountDetails: {
      firstName: "Nama pertama",
      lastName: "Nama keluarga",
      email: "E-mel",
      password: "Kata laluan",
      changePassword: "Tukar kata laluan",
      forgotPassword: "Lupa kata laluan",
    },
    childPinPopup: {
      title: "Perlindungan Kanak-kanak",
      subTitle: "Buat (kemas kini) PIN untuk Perlindungan Kanak-kanak",
      pinUpdated: "PIN berjaya dikemas kini",
      pinIsIncorrect: "PIN tidak betul",
    },
    deactivateAccount: {
      title: "Adakah anda pasti anda mahu menyahaktifkan akaun anda?",
      description:
        "Sebaik sahaja anda melakukannya, anda akan dilog keluar daripada semua perkhidmatan dan tidak akan dapat melihat kandungan dalam kereta anda lagi",
      deactivateButton: "Nyahaktifkan Akaun",
    },
    servicesPage: {
      add: "Tambah kepada perkhidmatan",
      remove: "Hapus daripada perkhidmatan",
      overflow: {
        title: "Pek Terpilih",
        subtitle: "Pilih Perkhidmatan anda",
        description:
          "Tambahkan setiap perkhidmatan yang anda gunakan pada panduan anda.",
      },
    },
    manageProfile: {
      text: "Urus Profil",
    },
    watchlist: {
      title:
        "Berikut adalah semua filem & rancangan yang telah anda tambahkan pada Senarai Tontonan anda",
      noTitles:
        "Pada masa ini anda tidak mempunyai tajuk dalam Senarai Tontonan anda",
      addText:
        "Untuk menambah, sila klik pada + Tambah kepada Senarai Tontonan pada mana-mana rancangan yang anda ingin kembali untuk tonton di kemudian hari.",
      subtext: {
        start: "Untuk menambah, sila klik pada ",
        toWatchList: "Tambah kepada senarai tontonan",
        end: "atau mana-mana rancangan yang anda ingin kembali untuk tonton di kemudian hari.",
      },
    },
    streamingService: {
      title: "Pilih Perkhidmatan Penstriman",
      subTitle:
        "Tambahkan perkhidmatan pilihan anda untuk dipaparkan dalam Panduan TV anda",
      streamingServices: "Perkhidmatan Penstriman",
    },
    profileUpdate: {
      backToSettings: "Kembali ke Tetapan",
      updatedSuccess: "Pengguna telah berjaya dikemas kini",
      apply: "Aplikasikan",
      wrongPassword: "Salah kata laluan",
    },
    changePasswordPage: {
      backToSettings: "Kembali ke Tetapan",
      currentPass: "Kata Laluan Semasa",
      newPass: "Kata laluan baru",
      confirmPass: "Sahkan Kata Laluan",
      change: "Ubah",
      changeUserInfo: "Tukar Maklumat Pengguna",
      passwordDoNotMatch: "Kata laluan tidak sepadan atau tidak sah",
      passwordUpdated: "Kata laluan berjaya dikemas kini",
    },
    noServices: {
      title:
        "Buat masa ini, anda tidak mempunyai perkhidmatan dalam Panduan TV anda",
      text: "Untuk memanfaatkan ScreenHits TV sepenuhnya, anda perlu menambah perkhidmatan. Sila klik di sini untuk menambah strim yang telah anda langgan tanpa sebarang kos tambahan. Dan jika anda tidak melanggan sebarang perkhidmatan penstriman pada masa ini, tambahkan himpunan PERCUMA kami dan mula terokai kandungan hebat dari BBC, ITV, My5, Channel 4, UKTV dan STV.",
      button: "Tambah Perkhidmatan",
    },
    deleteProfile: {
      cantDeleteProfile: "Anda tidak boleh padam profil semasa anda",
      areYouSure: "Adakah anda pasti anda mahu padam profil ini?",
      delete: "Padam Profil",
    },
    notFoundPage: {
      title: "Sesuatu telah berlaku",
      text: "Jangan risau, semuanya ok. Kami akan kembali dengan halaman ini.",
      home: "Halaman utama",
    },
    noInternet: {
      title: "Tiada Sambungan Internet",
      description:
        "Jangan risau, segala-galanya baik. Kami akan kembali dengan halaman ini.",
      button: "Muat Semula",
    },
  },
};

export default MS_RESOURCE;
