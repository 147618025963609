const ET_RESOURCE = {
  translation: {
    loading: "Laeb",
    search: {
      cancel: "Tühista",
      noResults:
        "Otsingutulemusi ei leitud, kuid allolevad vasted on kindlasti vaatamist väärt.",
    },
    menu: {
      switchProfiles: "Vaheta profiilid",
      settings: "Seaded",
      close: "Sulge",
      logOut: "Logi välja",
    },
    button: {
      apply: "Rakenda",
      continue: "Jätka",
      cancel: "Tühista",
      confirm: "Kinnita",
      play: "Mängi",
      addToWatchList: "Lisa vaatamisnimekirja",
      removeFromWatchList: "Eemalda vaatamisnimekirjast",
      moreInfo: "Rohkem infot",
      resume: "Jätka",
      close: "Sulge",
      discard: "Loobu",
      save: "Salvesta",
    },
    login: {
      header: "Kontole sisselogimine",
      title: "Tere tulemast",
      subTitle:
        "Saage oma voogedastuse tellimustest Netflixi, Disney, Amazoni ja muude kanalite kaudu kõige rohkem hüvesid.",
      text: "Kõik, mida soovid vaadata ühes kohas.",
      button: "Logi sisse",
      placeholder: {
        username: "Kasutajanimi",
        password: "Parool",
      },
      errors: {
        email: {
          noValid: "E-post peab olema kehtiv e-posti aadress",
          required: "E-post on kohustuslik",
        },
        password: {
          required: "Parool on kohustuslik",
        },
        wrongPass: {
          first:
            "Oi, see ei olnud õige parool. Palun proovi uuesti või klõpsa ",
          second: "“Unustatud salasõna” ",
          third: "nuppu allpool.",
        },
      },
    },
    forgotPassword: {
      message:
        "Ära muretse. Parooli lähtestamine on lihtne. Sisesta lihtsalt oma e-posti aadress allpool ja vajuta nuppu ”SAADA”.",
      button: "SAADA",
      placeholder: {
        username: "Kasutajanimi",
      },
      errors: {
        email: {
          required: "E-post on kohustuslik",
        },
        emailNotRegister: "E-posti aadress ei ole registreeritud!",
        emailSent: "Parooli lähtestamise e-kiri on saadetud!",
      },
    },
    profilesPage: {
      title: "Kes vaatab?",
      subTitle: "Igaüks teie autos saab isikupärastatud TV-kogemuse.",
      newProfile: "+ Lisa uus profiil",
    },
    newProfile: {
      title: "Uus profiil",
      description: "Lisage profiil teisele inimesele, kes vaatab ScreenHits TV-d",
      isKid: "Kas laps?",
      loading: "Laeb",
      profileCreated: "Profiili loomine õnnestus!",
      profileSelection: "Profiili valik",
    },
    settings: {
      accountDetails: {
        primary: "Konto andmed",
        secondary: "Muuda kontoomaniku nime ja e-posti aadressi.",
      },
      manageProfiles: {
        primary: "Halda profiile",
        secondary: "Muuda profiili detaile",
        edit: "Muuda profiili",
      },
      parentControl: {
        primary: "Lapsevanemate kontroll",
        secondary: "PIN-koodi loomine / PIN-koodi muutmine",
        instruction: {
          primary: "Loo (uuenda) PIN-kood Lapsevanemate kontroll",
          secondary: "(Palun sisestage 4 numbrit, kui klaviatuur on avatud)",
        },
        recoverPin: "Taasta PIN-kood",
        pinSentTo: "PIN-kood saadeti",
        unlock: "Ava",
        pinUndefined: "PIN saadeti e-posti aadressile"
      },
      membershipStatus: {
        primary: "Liikmelisuse staatus",
        secondary: "Aktiivne",
      },
      deactivateAccount: {
        primary: "Konto deaktiveerimine",
        secondary: "Sulge oma konto ja lahuta oma seadmed lahti.",
        enterPass: "Sisestage oma parool",
        discard: "Tühista muudatused",
      },
      activeLanguage: "Aktiivne keel",
      defaultLanguage: "Süsteemi keel",
      changeDefaultLanguage: "Muuda vaikimisi keelt",
      privacyPolicy: {
        text: "Privaatsuspoliitika",
      },
      settings: "Seaded",
      lang: {
        en: "Inglise",
        it: "Itaalia",
        de: "Saksa",
        esar: "Hispaania(Argentiina)",
        fr: "Prantsuse",
        ja: "Jaapani",
        ro: "Rumeenia",
        da: "Taani",
        nl: "Hollandi",
        ca: "Katalaani",
        lb: "Luksemburgi",
        no: "Norra",
        ptbr: "Portugali(Brasiilia)",
        zhcn: "Hiina",
        ms: "Malai",
        ta: "Tamili",
        bs: "Bosnia",
        bg: "Bulgaaria",
        hr: "Horvaadi",
        tr: "Türgi",
        cs: "Tšehhi",
        et: "Eesti",
        fi: "Soome",
        el: "Kreeka",
        hu: "Ungari",
        ga: "Iiri",
        is: "Islandi",
        lv: "Läti",
        lt: "Leedu",
        mt: "Malta",
        pl: "Poola",
        pt: "Portugali(Portugal)",
        ru: "Vene",
        sr: "Serbia",
        sk: "Slovaki",
        sl: "Sloveeni",
        sv: "Rootsi",
        mi: "Maoori",
        ar: "Araabia",
        af: "Afrikaani",
        zhtw: "Hiina(Traditsiooniline)",
        es: "Hispaania(Hispaania)",
        ko: "Korea",
        enus: "English (United States)",
        deat: "German (Austria)",
        dech: "German (Switzerland)",
        deli: "German (Liechtenstein)",
        dede: "German (Deutschland)",
        enca: "English (Canada)",
        engb: "English",
        enie: "English (Ireland)",
        ennz: "English (New Zealand)",
        enza: "English (South Africa)",
        esmx: "Spanish (Mexico)",
        frca: "French (Canada)",
        frch: "French (Switzerland)",
        itch: "Italian (Switzerland)",
        jajp: "Japanese (Japan)",
        kokr: "Korean (South Korea)",
        nb: "Norwegian (Bokmål)",
        nn: "Norwegian (Nynorsk)",
        zhch: "Chinese (PRC)",
      },
      language: {
        primary: "Keel",
      },
    },
    show: {
      more: " Näita rohkem",
      less: " Näita vähem",
    },
    showPage: {
      seasons: "Hooaega",
      cast: "Osatäitjad",
      producers: "Produtsendid",
      genre: "Žanr",
      directedBy: "Režissöör",
      season: "Hooaeg",
    },
    accountDetails: {
      firstName: "Eesnimi",
      lastName: "Perekonnanimi",
      email: "E-post",
      password: "Parool",
      changePassword: "Muuda salasõna",
      forgotPassword: "Unustatud salasõna",
    },
    childPinPopup: {
      title: "Lastelukk",
      subTitle: "Loo (uuenda) PIN-kood lasteluku jaoks",
      pinUpdated: "PIN-kood edukalt uuendatud",
      pinIsIncorrect: "PIN-kood on vale",
    },
    deactivateAccount: {
      title: "Kas oled kindel, et soovid oma konto desaktiveerida?",
      description:
        "Pärast seda logid end kõikidest teenustest välja ega saa enam oma autos sisu vaadata.",
      deactivateButton: "Desaktiveeri konto",
      discard: "Discard Changes",
    },
    servicesPage: {
      add: "Lisa teenustele",
      remove: "Eemalda teenustest",
      overflow: {
        title: "Valitud paketid",
        subtitle: "Vali oma teenused",
        description: "Lisa iga kasutatav teenus oma juhendisse.",
      },
    },
    manageProfile: {
      text: "Profiilide haldamine",
    },
    watchlist: {
      title:
        "Siin on kõik filmid ja seriaalid, mille oled lisanud oma vaatamisnimekirja.",
      noTitles: "Praegu ei ole ühtegi pealkirja  vaatamisnimekirjas",
      addText:
        "Lisamiseks klõpsa + Lisa vaatamisnimekirja mis tahes saatel, mida soovid hiljem vaadata.",
      subtext: {
        start: "Lisamiseks klõpsa palun  ",
        toWatchList: "Lisa vaatamisnimekirja",
        end: "või ükskõik millisel saatel, mida tahad hiljem vaadata.",
      },
    },
    streamingService: {
      title: "Vali voogesitusteenused",
      subTitle: "Lisage valitud teenused, et need ilmuksid teie TV Guide''is",
      streamingServices: "Voogesitusteenused",
    },
    profileUpdate: {
      backToSettings: "Tagasi seadete juurde",
      updatedSuccess: "Kasutaja on edukalt uuendatud",
      apply: "Rakenda",
      wrongPassword: "Vale parool",
    },
    changePasswordPage: {
      backToSettings: "Tagasi seadete juurde",
      currentPass: "Praegune parool",
      newPass: "Uus salasõna",
      confirmPass: "Kinnita paroool",
      change: "Muuda",
      changeUserInfo: "Muuda kasutaja andmeid",
      passwordDoNotMatch: "Paroolid ei ole õiged või on kehtetud",
      passwordUpdated: "Salasõna edukalt uuendatud",
    },
    noServices: {
      title: "Su telekavas pole praegu ühtegi teenust",
      text: "ScreenHits TV-st maksimumi saamiseks pead lisama teenuseid. Kõpsa siin, et lisada juba tellitud voogesitajad ilma lisatasuta. Ja kui sa pole praegu ühtegi voogedastusteenust tellinud, lisa meie TASUTA pakett ja hakka suurepärast sisu avastama.",
      button: "Lisa teenuseid",
    },
    deleteProfile: {
      cantDeleteProfile: "Sa ei saa oma praegust profiili kustutada",
      areYouSure: "Kas oled kindel, et soovid selle profiili kustutada?",
      delete: "Kustuta profiil",
    },
    notFoundPage: {
      title: "Midagi läks valesti",
      text: "Ärge siiski muretsege, kõik on korras. Tuleme selle lehega tagasi.",
      home: "Koduleht",
    },
    noInternet: {
      title: "Internetiühendus puudub",
      description:
        "Ära muretse, kõik on korras. Me tuleme tagasi selle lehega.",
      button: "Laadi uuesti",
    },
  },
};

export default ET_RESOURCE;
