const NL_RESOURCE = {
  translation: {
    loading: "Laden",
    search: {
      cancel: "Annuleren",
      noResults:
        "Geen zoekresultaten gevonden, maar onderstaande zijn zeker de moeite van het bekijken waard.",
    },
    menu: {
      switchProfiles: "Profielen wisselen",
      settings: "Instellingen",
      close: "Sluiten",
      logOut: "Afmelden",
    },
    button: {
      apply: "Toepassen",
      continue: "Doorgaan",
      cancel: "Annuleren",
      confirm: "Bevestigen",
      play: "Afspelen",
      addToWatchList: "Toevoegen aan Watchlist",
      removeFromWatchList: "Verwijderen uit Watchlist",
      moreInfo: "Meer informatie",
      resume: "Hervatten",
      close: "Sluiten",
      discard: "Weggooien",
      save: "Opslaan",
    },
    login: {
      header: "Aanmelden bij account",
      title: "Welkom bij",
      subTitle:
        "Haal het meeste uit uw streamingabonnementen met Disney, Amazon en meer.",
      text: "Alles wat u wilt zien op één plek.",
      button: "Aanmelden",
      placeholder: {
        username: "Gebruikersnaam",
        password: "Wachtwoord",
      },
      errors: {
        email: {
          noValid: "E-mail moet een geldig e-mailadres zijn",
          required: "E-mail is een verplicht veld",
        },
        password: {
          required: "Wachtwoord is een verplicht veld",
        },
        wrongPass: {
          first:
            "Oeps, dat was niet het juiste wachtwoord. Probeer het opnieuw of klik op de ",
          second: '"Wachtwoord vergeten" ',
          third: "knop hieronder.",
        },
      },
    },
    forgotPassword: {
      message:
        'Maakt u zich geen zorgen. Uw wachtwoord opnieuw instellen is eenvoudig. Vul hieronder uw e-mailadres in en klik op "VERZENDEN".',
      button: "VERZENDEN",
      placeholder: {
        username: "Gebruikersnaam",
      },
      errors: {
        email: {
          required: "E-mail is een verplicht veld",
        },
        emailNotRegister: "E-mailadres is niet geregistreerd!",
        emailSent: "Reset wachtwoord e-mail verzonden!",
      },
    },
    profilesPage: {
      title: "Wie kijkt er?",
      subTitle:
        "Iedereen in uw auto kan een gepersonaliseerde TV-ervaring hebben.",
      newProfile: "+ Nieuw profiel toevoegen",
    },
    newProfile: {
      title: "Nieuw profiel",
      description: "Voeg een profiel toe voor een andere persoon die ScreenHits TV kijkt",
      isKid: "Kind?",
      loading: "Laden",
      profileCreated: "Profiel succesvol aangemaakt!",
      profileSelection: "Profielselectie",
    },
    settings: {
      accountDetails: {
        primary: "Accountgegevens",
        secondary: "Naam en e-mailadres van accounthouder wijzigen.",
      },
      manageProfiles: {
        primary: "Beheer profielen",
        secondary: "Profielgegevens bewerken",
        edit: "Profiel bewerken",
      },
      parentControl: {
        primary: "Ouderlijk toezicht",
        secondary: "PIN aanmaken / PIN wijzigen",
        instruction: {
          primary: "Maak (update) PIN voor ouderlijk toezicht",
          secondary: "(Voer 4 cijfers in terwijl het toetsenbord open is)",
        },
        recoverPin: "PIN herstellen",
        pinSentTo: "PIN verzonden naar",
        unlock: "Ontgrendel",
        pinUndefined: "PIN naar e-mailadres gestuurd"
      },
      membershipStatus: {
        primary: "Lidmaatschapsstatus",
        secondary: "Actief",
      },
      deactivateAccount: {
        primary: "Account deactiveren",
        secondary: "Sluit uw account en koppel uw apparaten los.",
        enterPass: "Voer uw wachtwoord in",
        discard: "Wijzigingen negeren",
      },
      activeLanguage: "Actieve taal",
      defaultLanguage: "Systeemtaal",
      changeDefaultLanguage: "Wijzig standaardtaal",
      privacyPolicy: {
        text: "Privacybeleid",
      },
      settings: "Instellingen",
      lang: {
        en: "Engels",
        it: "Italiaans",
        de: "Duits",
        esar: "Spaans(Argentinië)",
        fr: "Frans",
        ja: "Japans",
        ro: "Roemeens",
        da: "Deens",
        nl: "Nederlands",
        ca: "Catalaans",
        lb: "Luxemburgs",
        no: "Noors",
        ptbr: "Portugees (BR)",
        zhcn: "Chinees",
        ms: "Maleisisch",
        ta: "Tamil",
        bs: "Bosnisch",
        bg: "Bulgaars",
        hr: "Kroatisch",
        tr: "Turks",
        cs: "Tsjechisch",
        et: "Ests",
        fi: "Fins",
        el: "Grieks",
        hu: "Hongaars",
        ga: "Lets",
        is: "IJslands",
        lv: "Letse",
        lt: "Litouws",
        mt: "Maltees",
        pl: "Pools",
        pt: "Portugees (PT)",
        ru: "Russisch",
        sr: "Servisch",
        sk: "Slowaaks",
        sl: "Sloveens",
        sv: "Zweedse",
        mi: "Maori",
        ar: "Arabisch",
        af: "Afrikaan",
        zhtw: "Chinees(Traditioneel)",
        es: "Spaans(Spanje)",
        ko: "Koreaans",
        enus: "Engels (Verenigde Staten)",
        deat: "Duits (Oostenrijk)",
        dech: "Duits (Zwitserland)",
        deli: "Duits (Liechtenstein)",
        dede: "Duits (Duitsland)",
        enca: "Engels (Canada)",
        engb: "Engels",
        enie: "Engels (Ierland)",
        ennz: "Engels (Nieuw-Zeeland)",
        enza: "Engels (Zuid-Afrika)",
        esmx: "Spaans (Mexico)",
        frca: "Frans (Canada)",
        frch: "Frans (Zwitserland)",
        itch: "Italiaans (Zwitserland)",
        jajp: "Japans (Japan)",
        kokr: "Koreaans (Zuid-Korea)",
        nb: "Noors (Bokmål)",
        nn: "Noors (Nynorsk)",
        zhch: "Chinees (Volksrepubliek China)",
      },
      language: {
        primary: "Taal",
      },
    },
    show: {
      more: " Meer tonen",
      less: " Minder tonen",
    },
    showPage: {
      seasons: "Seizoenen",
      cast: "Cast",
      producers: "Producenten",
      genre: "Genre",
      directedBy: "Geregisseerd door",
      season: "Seizoen",
    },
    accountDetails: {
      firstName: "Voornaam",
      lastName: "Achternaam",
      email: "E-mail",
      password: "Wachtwoord",
      changePassword: "Wachtwoord wijzigen",
      forgotPassword: "Wachtwoord vergeten",
    },
    childPinPopup: {
      title: "Kinderbeveiliging",
      subTitle: "PIN aanmaken (bijwerken) voor kinderbeveiliging",
      pinUpdated: "PIN succesvol bijgewerkt",
      pinIsIncorrect: "PIN is onjuist",
    },
    deactivateAccount: {
      title: "Weet u zeker dat u uw account wilt deactiveren?",
      description:
        "Als u dat doet, wordt u uitgelogd bij alle diensten en kunt u de inhoud in uw auto niet meer bekijken.",
      deactivateButton: "Account deactiveren",
    },
    servicesPage: {
      add: "Toevoegen aan diensten",
      remove: "Verwijderen uit diensten",
      overflow: {
        title: "Geselecteerde pakketten",
        subtitle: "Selecteer uw diensten",
        description: "Voeg elke dienst die u gebruikt toe aan uw gids.",
      },
    },
    manageProfile: {
      text: "Profielen beheren",
    },
    watchlist: {
      title:
        "Hier zijn alle films & shows die u aan uw Watchlist hebt toegevoegd",
      noTitles: "U hebt momenteel geen titels in uw WatchList.",
      addText:
        "Om iets toe te voegen, klikt u op + Toevoegen aan Watchlist bij elke show die u later wilt bekijken.",
      subtext: {
        start: "Om toe te voegen, klik op ",
        toWatchList: "Toevoegen aan Watchlist",
        end: "voor elke show die u later nog eens wilt bekijken.",
      },
    },
    streamingService: {
      title: "Streamingdiensten kiezen",
      subTitle:
        "Uw geselecteerde diensten toevoegen om in uw tv-gids te verschijnen",
      streamingServices: "Streamingdiensten",
    },
    profileUpdate: {
      backToSettings: "Terug naar instellingen",
      updatedSuccess: "Gebruiker is succesvol bijgewerkt",
      apply: "Toepassen",
      wrongPassword: "Verkeerd wachtwoord",
    },
    changePasswordPage: {
      backToSettings: "Terug naar instellingen",
      currentPass: "Huidig wachtwoord",
      newPass: "Nieuw wachtwoord",
      confirmPass: "Wachtwoord bevestigen",
      change: "Wijzigen",
      changeUserInfo: "Gebruikersinformatie wijzigen",
      passwordDoNotMatch: "Wachtwoorden komen niet overeen of zijn ongeldig",
      passwordUpdated: "Wachtwoord succesvol bijgewerkt",
    },
    noServices: {
      title: "U hebt momenteel geen services in je tv-gids",
      text: "Om het meeste uit ScreenHits TV te halen, moet u diensten toevoegen. Klik hier om de streamingdiensten waarop u al geabonneerd bent zonder extra kosten toe te voegen. En als u momenteel geen abonnement hebt op een streamingdienst, voeg dan onze GRATIS bundel toe en start met het ontdekken van geweldige inhoud van BBC, ITV, My5, Channel 4, UKTV en STV.",
      button: "Diensten toevoegen",
    },
    deleteProfile: {
      cantDeleteProfile: "U kunt uw huidige profiel niet verwijderen",
      areYouSure: "Weet u zeker dat u dit profiel wilt verwijderen?",
      delete: "Profiel verwijderen",
    },
    notFoundPage: {
      title: "Er is iets fout gegaan",
      text: "Maak je echter geen zorgen, alles is in orde. We komen terug met deze pagina.",
      home: "Startpagina",
    },
    noInternet: {
      title: "Geen internetverbinding",
      description:
        "Maak je geen zorgen, alles is in orde. We komen terug met deze pagina.",
      button: "Herladen",
    },
  },
};

export default NL_RESOURCE;
