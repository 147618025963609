const RU_RESOURCE = {
  translation: {
    loading: "Загрузка",
    search: {
      cancel: "Отмена",
      noResults:
        "Результаты поиска не найдены, но приведенные ниже определенно заслуживают внимания.",
    },
    menu: {
      switchProfiles: "Изменить профиль",
      settings: "Настройки",
      close: "Закрыть",
      logOut: "Выйти из учетной записи",
    },
    button: {
      apply: "Применить",
      continue: "Продолжить",
      cancel: "Отмена",
      confirm: "Подтвердить",
      play: "Играть",
      addToWatchList: "Добавить в список для просмотра",
      removeFromWatchList: "Удалить из списка для просмотра",
      moreInfo: "Дополнительная информация",
      resume: "Резюме",
      close: "Закрыть",
      discard: "Отменить",
      save: "Сохранить",
    },
    login: {
      header: "Вход в учетную запись",
      title: "Добро пожаловать в",
      subTitle:
        "Получите максимальную отдачу от подписки на потоковое вещание Netflix, Disney, Amazon и др.",
      text: "Все, что вы хотите посмотреть, в одном месте.",
      button: "Войти",
      placeholder: {
        username: "Имя пользователя",
        password: "Пароль",
      },
      errors: {
        email: {
          noValid: "Электронная почта должна быть действительной",
          required: "Электронная почта является обязательным полем",
        },
        password: {
          required: "Пароль является обязательным полем",
        },
        wrongPass: {
          first:
            "Ой! Это был неправильный пароль. Пожалуйста, попробуйте еще раз или нажмите на кнопку",
          second: "“Забыли пароль” ",
          third: "ниже.",
        },
      },
    },
    forgotPassword: {
      message:
        'Не волнуйтесь. Сбросить пароль очень легко. Просто введите свой адрес электронной почты и нажмите "ОТПРАВИТЬ".',
      button: "ОТПРАВИТЬ",
      placeholder: {
        username: "Имя пользователя",
      },
      errors: {
        email: {
          required: "Электронная почта является обязательным полем",
        },
        emailNotRegister: "Адрес электронной почты не зарегистрирован!",
        emailSent: "Отправлено электронное письмо для сброса пароля!",
      },
    },
    profilesPage: {
      title: "Кто смотрит?",
      subTitle:
        "Каждый в вашем автомобиле может иметь персонализированный телевизионный опыт.",
      newProfile: "+ Добавить новый профиль",
    },
    newProfile: {
      title: "Новый профиль",
      description: "Добавить профиль для другого человека, смотрящего ScreenHits TV",
      isKid: "Ребёнок?",
      loading: "Загрузка",
      profileCreated: "Профиль успешно создан!",
      profileSelection: "Выбор профиля",
    },
    settings: {
      accountDetails: {
        primary: "Выбор профиля",
        secondary:
          "Редактировать имя пользователя учетной записи и электронную почту.",
      },
      manageProfiles: {
        primary: "Управление профилями",
        secondary: "Редактировать детали профиля",
        edit: "Редактировать профиль",
      },
      parentControl: {
        primary: "Родительский контроль",
        secondary: "Создать PIN / Изменить PIN",
        instruction: {
          primary: "Создать (обновить) PIN-код для родительского контроля",
          secondary: "(Пожалуйста, введите 4 цифры, пока клавиатура открыта)",
        },
        recoverPin: "Сбросить PIN",
        pinSentTo: "PIN отправлен на",
        unlock: "Разблокировать",
        pinUndefined: "PIN отправлен на адрес электронной почты"
      },
      membershipStatus: {
        primary: "Статус членства",
        secondary: "Активный",
      },
      deactivateAccount: {
        primary: "Деактивировать учетную запись",
        secondary: "Закрыть учетную запись и отсоединить устройства.",
        enterPass: "Введите пароль",
        discard: "Отменить изменения",
      },
      activeLanguage: "Активный язык",
      defaultLanguage: "Язык системы",
      changeDefaultLanguage: "Изменить язык по умолчанию",
      privacyPolicy: {
        text: "Политика конфиденциальности",
      },
      settings: "Настройки",
      lang: {
        en: "Английский",
        it: "Итальянский",
        de: "Немецкий",
        esar: "Испанский (Аргентина)",
        fr: "Французский",
        ja: "Японский",
        ro: "Румынский",
        da: "Датский",
        nl: "Голландский",
        ca: "Каталонский",
        lb: "Люксембургский",
        no: "Норвежский",
        ptbr: "Португальский (Бразилия)",
        zhcn: "Китайский",
        ms: "Малайский",
        ta: "Тамильский",
        bs: "Боснийский",
        bg: "Болгарский",
        hr: "Хорватский",
        tr: "Турецкий",
        cs: "Чешский",
        et: "Эстонский",
        fi: "Финский",
        el: "Греческий",
        hu: "Венгерский",
        ga: "Ирландский",
        is: "Исландский",
        lv: "Латвийский",
        lt: "Литовский",
        mt: "Мальтийский",
        pl: "Польский",
        pt: "Португальский (Португалия)",
        ru: "Русский",
        sr: "Сербский",
        sk: "Словацкий",
        sl: "Словенский",
        sv: "Шведский",
        mi: "Маори",
        ar: "Арабский",
        af: "Африкаанс",
        zhtw: "Китайский (традиционный)",
        es: "Испанский (Испания)",
        ko: "Корейский",
        enus: "Английский (Соединенные Штаты)",
        deat: "Немецкий (Австрия)",
        dech: "Немецкий (Швейцария)",
        deli: "Немецкий (Лихтенштейн)",
        dede: "Немецкий (Германия)",
        enca: "Английский (Канада)",
        engb: "Английский",
        enie: "Английский (Ирландия)",
        ennz: "Английский (Новая Зеландия)",
        enza: "Английский (Южная Африка)",
        esmx: "Испанский (Мексика)",
        frca: "Французский (Канада)",
        frch: "Французский (Швейцария)",
        itch: "Итальянский (Швейцария)",
        jajp: "Японский (Япония)",
        kokr: "Корейский (Южная Корея)",
        nb: "Норвежский (Букмол)",
        nn: "Норвежский (Нюнорск)",
        zhch: "Китайский (КНР)",
      },
      language: {
        primary: "Язык",
      },
    },
    show: {
      more: " Показать больше",
      less: " Показать меньше",
    },
    showPage: {
      seasons: "Сезоны",
      cast: "Актерский состав",
      producers: "Продюсеры",
      genre: "Жанр",
      directedBy: "Режиссер",
      season: "Сезон",
    },
    accountDetails: {
      firstName: "Имя",
      lastName: "Фамилия",
      email: "Электронная почта",
      password: "Пароль",
      changePassword: "Изменить пароль",
      forgotPassword: "Забыли пароль",
    },
    childPinPopup: {
      title: "Защита детей",
      subTitle: "Создание (обновление) PIN-кода для защиты детей",
      pinUpdated: "PIN-код успешно обновлен",
      pinIsIncorrect: "PIN-код неверный",
    },
    deactivateAccount: {
      title: "Вы уверены, что хотите деактивировать свою учетную запись?",
      description:
        "После этого вы выйдете из всех служб и больше не сможете просматривать контент в своем автомобиле",
      deactivateButton: "Деактивировать учетную запись",
    },
    servicesPage: {
      add: "Добавить к службам",
      remove: "Удалить из служб",
      overflow: {
        title: "Выбранные пакеты",
        subtitle: "Выберите свои службы",
        description: "Добавьте все используемые вами службы в свой справочник.",
      },
    },
    manageProfile: {
      text: "Управление профилями",
    },
    watchlist: {
      title:
        "Вот все фильмы и передачи, которые вы добавили в свой Список для просмотра",
      noTitles: "В настоящее время у вас нет названий в Списке для просмотра",
      addText:
        "Чтобы добавить, пожалуйста, нажмите на + Добавить в Список для просмотра на любой передаче, которую вы хотели бы посмотреть позже.",
      subtext: {
        start: "Чтобы добавить, пожалуйста, нажмите на ",
        toWatchList: "Добавить в Список для просмотра",
        end: "для любой передачи, которую вы хотели бы посмотреть позже.",
      },
    },
    streamingService: {
      title: "Выберите службы потокового вещания",
      subTitle:
        "Добавьте выбранные службы, чтобы они отображались в вашем Телегиде",
      streamingServices: "Службы потокового вещания",
    },
    profileUpdate: {
      backToSettings: "Назад к настройкам",
      updatedSuccess: "Пользователь был успешно обновлен",
      apply: "Применить",
      wrongPassword: "Неправильный пароль",
    },
    changePasswordPage: {
      backToSettings: "Назад к настройкам",
      currentPass: "Текущий пароль",
      newPass: "Новый пароль",
      confirmPass: "Подтверждение пароля",
      change: "Изменить",
      changeUserInfo: "Изменить информацию о пользователе",
      passwordDoNotMatch: "Пароли не совпадают или недействительны",
      passwordUpdated: "Пароль успешно обновлен",
    },
    noServices: {
      title: "В настоящее время у вас нет услуг в Телегиде",
      text: "Чтобы получить максимальную отдачу от ScreenHits TV, вам необходимо добавить службы. Нажмите здесь, чтобы бесплатно добавить службы потокового вещания, на которые вы уже подписаны. И если вы в настоящее время не подписаны ни на одну службу потокового вещания, добавьте наш БЕСПЛАТНЫЙ пакет и начните открывать для себя отличный контент BBC, ITV, My5, Channel 4, UKTV и STV.",
      button: "Добавить службы",
    },
    deleteProfile: {
      cantDeleteProfile: "Вы не можете удалить свой текущий профиль",
      areYouSure: "Вы уверены, что хотите удалить этот профиль?",
      delete: "Удалить профиль",
    },
    notFoundPage: {
      title: "Что-то пошло не так",
      text: "Однако не волнуйтесь, все в порядке. Мы вернемся с этой страницей.",
      home: "Домашняя страница",
    },
    noInternet: {
      title: "Нет подключения к интернету",
      description:
        "Не волнуйтесь, всё в порядке. Мы вернемся с этой страницей.",
      button: "Перезагрузить",
    },
  },
};

export default RU_RESOURCE;
