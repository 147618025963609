const ES_RESOURCE = {
  translation: {
    loading: "Cargando",
    search: {
      cancel: "Cancelar",
      noResults:
        "No se encontraron resultados de búsqueda, pero definitivamente vale la pena revisar los siguientes.",
    },
    menu: {
      switchProfiles: "Cambiar el perfil",
      settings: "Ajustes",
      close: "Cerrar",
      logOut: "Cerrar la sesión",
    },
    button: {
      apply: "Aplicar",
      continue: "Continuar",
      cancel: "Cancelar",
      confirm: "Confirmar",
      play: "Reproducir",
      addToWatchList: "Añadir a la lista de seguimiento",
      removeFromWatchList: "Eliminar de la lista de seguimiento",
      moreInfo: "Más información",
      resume: "Reanudar",
      close: "Cerrar",
      discard: "Descartar",
      save: "Guardar",
    },
    login: {
      header: "Acceso a la cuenta",
      title: "Bienvenido a",
      subTitle:
        "Aprovecha al máximo tus suscripciones de streaming con Disney, Amazon y más.",
      text: "Todo lo que quieres ver en un solo lugar.",
      button: "Entrar",
      placeholder: {
        username: "Nombre de usuario",
        password: "Contraseña",
      },
      errors: {
        email: {
          noValid: "El correo electrónico debe ser válido",
          required: "El correo electrónico es un campo obligatorio",
        },
        password: {
          required: "La contraseña es un campo obligatorio",
        },
        wrongPass: {
          first:
            "Vaya, esa no era la contraseña correcta. Por favor, inténtalo de nuevo o haz clic en ",
          second: "“He olvidado mi contraseña” ",
          third: "botón a continuación.",
        },
      },
    },
    forgotPassword: {
      message:
        'No te preocupes. Restablecer tu contraseña es fácil. Solo tienes que introducir tu dirección de correo electrónico a continuación y hacer clic en "ENVIAR"',
      button: "ENVIAR",
      placeholder: {
        username: "Nombre de usuario",
      },
      errors: {
        email: {
          required: "El correo electrónico es un campo obligatorio",
        },
        emailNotRegister: "Dirección de correo electrónico no registrada!",
        emailSent:
          "Se ha enviado el correo electrónico para restablecerla contraseña!",
      },
    },
    profilesPage: {
      title: "Elige el perfil",
      subTitle:
        "Todos en tu coche pueden tener una experiencia de TV personalizada.",
      newProfile: "+ Añadir nuevo perfil",
    },
    newProfile: {
      title: "Nuevo perfil",
      description: "Agregar un perfil para otra persona que esté viendo ScreenHits TV",
      isKid: "¿Niño?",
      loading: "Cargando",
      profileCreated: "El perfil se ha creado correctamente",
      profileSelection: "Selección de perfiles",
    },
    settings: {
      accountDetails: {
        primary: "Detalles de la cuenta",
        secondary:
          "Editar el nombre del titular de la cuenta y el correo electrónico.",
      },
      manageProfiles: {
        primary: "Gestionar perfiles",
        secondary: "Editar detalles del perfil",
        edit: "Editar perfil",
      },
      parentControl: {
        primary: "Control Parental",
        secondary: "Crear PIN / Cambiar PIN",
        instruction: {
          primary: "Crear (actualizar) PIN para Control Parental",
          secondary:
            "(Por favor, introduzca 4 dígitos mientras el teclado está abierto)",
        },
        recoverPin: "Restablecer PIN",
        pinSentTo: "PIN enviado a",
        unlock: "Desbloquear",
        pinUndefined: "PIN enviado a la dirección de correo electrónico"
      },
      membershipStatus: {
        primary: "Estado de la suscripción",
        secondary: "Activo",
      },
      deactivateAccount: {
        primary: "Desactivar cuenta",
        secondary: "Cierra tu cuenta y desconecta tus dispositivos.",
        enterPass: "Ingresa tu contraseña",
        discard: "Descartar cambios",
      },
      activeLanguage: "Lenguaje activo",
      defaultLanguage: "Lenguaje del sistema",
      changeDefaultLanguage: "Cambiar idioma predeterminado",
      privacyPolicy: {
        text: "Política de privacidad",
      },
      settings: "Ajustes",
      lang: {
        en: "Inglés",
        it: "Italiano",
        de: "Alemana",
        esar: "Español (Argentina)",
        fr: "Francés",
        ja: "Japonés",
        ro: "Rumano",
        da: "Danés",
        nl: "Neerlandés",
        ca: "Catalán",
        lb: "Luxemburgués",
        no: "Noruego",
        ptbr: "Portugués(Brasil)",
        zhcn: "Chino",
        ms: "Malayo",
        ta: "Tamil",
        bs: "Bosnio",
        bg: "Búlgaro",
        hr: "Croata",
        tr: "Turco",
        cs: "Checo",
        et: "Estonio",
        fi: "Finlandés",
        el: "Griego",
        hu: "Húngaro",
        ga: "Irlandés",
        is: "Islandés",
        lv: "Letón",
        lt: "Lituano",
        mt: "Maltés",
        pl: "Polaco",
        pt: "Portugués(Portugal)",
        ru: "Ruso",
        sr: "Serbio",
        sk: "Eslovaco",
        sl: "Esloveno",
        sv: "Sueco",
        mi: "Maorí",
        ar: "Árabe",
        af: "Afrikáans",
        zhtw: "Chino(Tradicional)",
        es: "Español(España)",
        ko: "Coreano",
        enus: "Inglés (Estados Unidos)",
        deat: "Alemán (Austria)",
        dech: "Alemán (Suiza)",
        deli: "Alemán (Liechtenstein)",
        dede: "Alemán (Alemania)",
        enca: "Inglés (Canadá)",
        engb: "Inglés",
        enie: "Inglés (Irlanda)",
        ennz: "Inglés (Nueva Zelanda)",
        enza: "Inglés (Sudáfrica)",
        esmx: "Español (México)",
        frca: "Francés (Canadá)",
        frch: "Francés (Suiza)",
        itch: "Italiano (Suiza)",
        jajp: "Japonés (Japón)",
        kokr: "Coreano (Corea del Sur)",
        nb: "Noruego (Bokmål)",
        nn: "Noruego (Nynorsk)",
        zhch: "Chino (RPC)",
      },
      language: {
        primary: "Idioma",
      },
    },
    show: {
      more: " Mostrar más",
      less: " Mostrar menos",
    },
    showPage: {
      seasons: "Temporadas",
      cast: "Reparto",
      producers: "Productores",
      genre: "Género",
      directedBy: "Dirigido por",
      season: "Temporada",
    },
    accountDetails: {
      firstName: "Nombre",
      lastName: "Apellido",
      email: "Correo electrónico",
      password: "Contraseña",
      changePassword: "Cambiar la contraseña",
      forgotPassword: "He olvidado mi contraseña",
    },
    childPinPopup: {
      title: "Control parental",
      subTitle: "Crear (actualizar) el PIN para la Protección de la Infancia",
      pinUpdated: "PIN actualizado con éxito",
      pinIsIncorrect: "El PIN es incorrecto",
    },
    deactivateAccount: {
      title: "¿Estás seguro de que quieres desactivar tu cuenta?",
      description:
        "Una vez que lo hagas, se cerrará tu sesión de todos los servicios y ya no podrás ver el contenido en tu coche",
      deactivateButton: "Desactivar la cuenta",
    },
    servicesPage: {
      add: "Añadir a los servicios",
      remove: "Eliminar de los servicios",
      overflow: {
        title: "Paquetes seleccionados",
        subtitle: "Selecciona tus servicios",
        description: "Añade a tu guía cada uno de los servicios que utilices.",
      },
    },
    manageProfile: {
      text: "Gestionar perfiles",
    },
    watchlist: {
      title:
        "Aquí están todas las películas y programas que has añadido a tu lista de seguimiento",
      noTitles:
        "Actualmente no tienes ningún título en tu lista de seguimiento",
      addText:
        "Para añadir, haz clic en + Añadir a la lista de seguimiento en cualquier programa que te gustaría volver a ver en una fecha posterior.",
      subtext: {
        start: "Para añadir, haz clic en  ",
        toWatchList: "Añadir a la lista de seguimiento",
        end: "o cualquier programa que quieras volver a ver en otro momento.",
      },
    },
    streamingService: {
      title: "Elige los servicios de streaming",
      subTitle:
        "Añade los servicios seleccionados para que aparezcan en tu Guía de TV",
      streamingServices: "Servicios de transmisión",
    },
    profileUpdate: {
      backToSettings: "Volver a los ajustes",
      updatedSuccess: "El usuario se ha actualizado con éxito",
      apply: "Aplicar",
      wrongPassword: "Contraseña incorrecta",
    },
    changePasswordPage: {
      backToSettings: "Volver a la configuración",
      currentPass: "Contraseña actual",
      newPass: "Nueva contraseña",
      confirmPass: "Confirmar contraseña",
      change: "Cambiar",
      changeUserInfo: "Cambiar la información del usuario",
      passwordDoNotMatch: "Las contraseñas no coinciden o no son válidas",
      passwordUpdated: "Contraseña actualizada con éxito",
    },
    noServices: {
      title: "Actualmente no tienes ningún servicio en tu Guía de TV",
      text: "Para aprovechar al máximo ScreenHits TV, debes añadir servicios. Haz clic aquí para añadir las plataformas de streaming a las que ya estás suscrito sin costes adicionales. Y si todavía no estás suscrito a ningún servicio de streaming, añade nuestro paquete GRATUITO y disfruta ya de contenido excelente.",
      button: "Añadir servicios",
    },
    deleteProfile: {
      cantDeleteProfile: "No puedes eliminar tu perfil actual",
      areYouSure: "¿Estás seguro de que deseas eliminar este perfil?",
      delete: "Eliminar perfil",
    },
    notFoundPage: {
      title: "Algo salió mal",
      text: "Pero no te preocupes, todo está bien. Volveremos con esta página.",
      home: "Página de inicio",
    },
    noInternet: {
      title: "Sin conexión a Internet",
      description:
        "No te preocupes, todo está bien. Volveremos con esta página.",
      button: "Recargar",
    },
  },
};

export default ES_RESOURCE;
