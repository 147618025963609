const NN_RESOURCE = {
  translation: {
    loading: "Laster",
    search: {
      cancel: "Avbryt",
      noResults:
        "Ingen søkeresultater funnet, men det er absolutt verdt å sjekke ut de nedenfor.",
    },
    menu: {
      switchProfiles: "Bytt profiler",
      settings: "Innstillinger",
      close: "Lukk",
      logOut: "Logg ut",
    },
    button: {
      apply: "Bruk",
      continue: "Fortsette",
      cancel: "Avbryt",
      confirm: "Bekreft",
      play: "Spill",
      addToWatchList: "Legg til seerlisten",
      removeFromWatchList: "Fjern fra seerlisten",
      moreInfo: "Mer informasjon",
      resume: "Gjenoppta",
      close: "Lukk",
      discard: "Kaste",
      save: "Lagre",
    },
    login: {
      header: "Kontopålogging",
      title: "Velkommen til",
      subTitle:
        "Få mest mulig ut av strømmeabonnementene dine med Netflix, Disney, Amazon og flere.",
      text: "Alt du har lyst å se på, på ett sted.",
      button: "Logg Inn",
      placeholder: {
        username: "Brukernavn",
        password: "Passord",
      },
      errors: {
        email: {
          noValid: "E-postadressen må være en gyldig e-postadresse",
          required: "E-postadresse er et obligatorisk felt",
        },
        password: {
          required: "Passord er et obligatorisk felt",
        },
        wrongPass: {
          first:
            "Oops, det var ikke riktig passord. Vennligst prøv igjen eller klikk på ",
          second: '"Glemt passord" ',
          third: "knappen nedenfor.",
        },
      },
    },
    forgotPassword: {
      message:
        'Ikke bekymre deg. Det er enkelt å tilbakestille passordet ditt. Det eneste du trenger å gjøre er å skrive inn e-postadressen din nedenfor og klikke på "SEND"',
      button: "SEND",
      placeholder: {
        username: "Brukernavn",
      },
      errors: {
        email: {
          required: "E-postadresse er et obligatorisk felt",
        },
        emailNotRegister: "E-postadressen er ikke registrert!",
        emailSent: "E-post for tilbakestilling av passordet er blitt sendt!",
      },
    },
    profilesPage: {
      title: "Velg profil",
      subTitle: "Alle i bilen din kan ha ein personleg TV-oppleving.",
      newProfile: "+ Legg til ny profil",
    },
    newProfile: {
      title: "Ny profil",
      description: "Legg til ein profil for ein annan person som ser på ScreenHits TV",
      isKid: "Barn?",
      loading: "Laster",
      profileCreated: "Profilen er opprettet!",
      profileSelection: "Profilvalg",
    },
    settings: {
      accountDetails: {
        primary: "Kontoinformasjon",
        secondary: "Endre navn på kontoinnehaver og e-post.",
      },
      manageProfiles: {
        primary: "Administrer profiler",
        secondary: "Rediger profilinformasjon",
        edit: "Rediger profil",
      },
      parentControl: {
        primary: "Foreldrekontroll",
        secondary: "Opprett PIN / Endre PIN",
        instruction: {
          primary: "Opprett (oppdater) PIN-kode for Foreldrekontroll",
          secondary: "(Vennligst skriv inn 4 siffer mens tastaturet er opent)",
        },
        recoverPin: "Tilbakestill PIN",
        pinSentTo: "PIN sendt til",
        unlock: "Lås opp",
        pinUndefined: "PIN sendt til e-postadressa"
      },
      membershipStatus: {
        primary: "Medlemsstatus",
        secondary: "Aktiv",
      },
      deactivateAccount: {
        primary: "Deaktiver konto",
        secondary: "Lukk kontoen din og koble fra enhetene dine.",
        enterPass: "Skriv inn passordet ditt",
        discard: "Forkast endringer",
      },
      activeLanguage: "Aktivt språk",
      defaultLanguage: "Systemspråk",
      changeDefaultLanguage: "Endre standardspråk",
      privacyPolicy: {
        text: "Personvernerklæring",
      },
      settings: "Innstillinger",
      lang: {
        en: "Engelsk",
        it: "Italiensk",
        de: "Tysk",
        esar: "Spansk(Argentina)",
        fr: "Fransk",
        ja: "Japansk",
        ro: "Rumensk",
        da: "Dansk",
        nl: "Nederlandsk",
        ca: "Katalansk",
        lb: "Louxembourgsk",
        no: "Norsk",
        ptbr: "Portugisisk(BR)",
        zhcn: "Kinesisk",
        ms: "Malayisk",
        ta: "Tamilsk",
        bs: "Bosnisk",
        bg: "Bulgarsk",
        hr: "Kroatisk",
        tr: "Tyrkisk",
        cs: "Tsjekkisk",
        et: "Estisk",
        fi: "Finsk",
        el: "Gresk",
        hu: "Ungarsk",
        ga: "Irsk",
        is: "Islandsk",
        lv: "Latvisk",
        lt: "Litauisk",
        mt: "Maltesisk",
        pl: "Polsk",
        pt: "Portugisisk(PT)",
        ru: "Russisk",
        sr: "Serbisk",
        sk: "Slovakisk",
        sl: "Slovensk",
        sv: "Svensk",
        mi: "Māori",
        ar: "Arabisk",
        af: "Afrikaans",
        zhtw: "Kinesisk(Tradisjonell)",
        es: "Spansk (Spania)",
        ko: "Koreansk",
        enus: "Engelsk (USA)",
        deat: "Tysk (Østerrike)",
        dech: "Tysk (Sveits)",
        deli: "Tysk (Liechtenstein)",
        dede: "Tysk (Tyskland)",
        enca: "Engelsk (Canada)",
        engb: "Engelsk",
        enie: "Engelsk (Irland)",
        ennz: "Engelsk (New Zealand)",
        enza: "Engelsk (Sør-Afrika)",
        esmx: "Spansk (Mexico)",
        frca: "Fransk (Canada)",
        frch: "Fransk (Sveits)",
        itch: "Italiensk (Sveits)",
        jajp: "Japansk (Japan)",
        kokr: "Koreansk (Sør-Korea)",
        nb: "Norsk (Bokmål)",
        nn: "Norsk (Nynorsk)",
        zhch: "Kinesisk (Kina)",
      },
      language: {
        primary: "Språk",
      },
    },
    show: {
      more: " Vis mer",
      less: " Vis mindre",
    },
    showPage: {
      seasons: "Sesonger",
      cast: "Cast",
      producers: "Produsenter",
      genre: "Sjanger",
      directedBy: "Regissert av",
      season: "Sesong",
    },
    accountDetails: {
      firstName: "Fornavn",
      lastName: "Etternavn",
      email: "E-post",
      password: "Passord",
      changePassword: "Endre passord",
      forgotPassword: "Glemt passord",
    },
    childPinPopup: {
      title: "Barnesikring",
      subTitle: "Opprett (oppdater) PIN-kode for barnesikring",
      pinUpdated: "PIN-koden ble oppdatert",
      pinIsIncorrect: "PIN-koden er feil",
    },
    deactivateAccount: {
      title: "Er du sikker på at du ønsker å deaktivere kontoen din?",
      description:
        "Hvis du gjør det, vil du bli logget ut av alle tjenestene og du vil ikke lenger kunne se på innhold i bilen din",
      deactivateButton: "Deaktiver konto",
    },
    servicesPage: {
      add: "Legg til tjenester",
      remove: "Fjern fra tjenester",
      overflow: {
        title: "Utvalgte pakker",
        subtitle: "Velg dine tjenester",
        description: "Legg til hver tjeneste du bruker i guiden din.",
      },
    },
    manageProfile: {
      text: "Administrer profiler",
    },
    watchlist: {
      title:
        "Her er alle filmene og programmene du har lagt til i seerlisten din",
      noTitles: "Du har for øyeblikket ingen titler i seerlisten din",
      addText:
        "For å legge til, klikk på «+ Legg til seerliste» på et hvilket som helst program du ønsker å gå tilbake til og se på et senere tidspunkt.",
      subtext: {
        start: "For å legge til, klikk på ",
        toWatchList: "Legg til seerliste",
        end: "for et hvilket som helst program du ønsker å gå tilbake til og se på et senere tidspunkt.",
      },
    },
    streamingService: {
      title: "Velg strømmetjenester",
      subTitle: "Legg til de valgte tjenestene, slik at de vises i TV-guiden",
      streamingServices: "Strømmetjenester",
    },
    profileUpdate: {
      backToSettings: "Tilbake til innstillinger",
      updatedSuccess: "Brukeren har blitt oppdatert",
      apply: "Bruk",
      wrongPassword: "Feil passord",
    },
    changePasswordPage: {
      backToSettings: "Tilbake til innstillinger",
      currentPass: "Nåværende passord",
      newPass: "Nytt passord",
      confirmPass: "Bekreft passord",
      change: "Endre",
      changeUserInfo: "Endre brukerinformasjon",
      passwordDoNotMatch: "Passordene stemmer ikke overens eller er ugyldige",
      passwordUpdated: "Passordet har blitt oppdatert",
    },
    noServices: {
      title: "Du har for øyeblikket ingen tjenester i TV-guiden",
      text: "For å få mest mulig ut av ScreenHits TV, må du legge til tjenester. Klikk her for å legge til strømmetjenester som du allerede abonnerer på uten ekstra kostnad. Hvis du for øyeblikket ikke abonnerer på noen strømmetjenester, kan du legge til vår GRATIS-pakke og begynne å oppdage flott innhold.",
      button: "Legg til tjenester",
    },
    deleteProfile: {
      cantDeleteProfile: "Du kan ikke slette din nåværende profil",
      areYouSure: "Er du sikker på at du ønsker å slette denne profilen?",
      delete: "Fjern profil",
    },
    notFoundPage: {
      title: "Noe gikk galt",
      text: "Men ikke bekymre deg, alt er ok. Vi kommer tilbake med denne siden.",
      home: "Hjemmeside",
    },
    noInternet: {
      title: "Ingen internettforbindelse",
      description:
        "Ikke bekymre deg, alt er i orden. Vi kommer tilbake med denne siden.",
      button: "Last inn på nytt",
    },
  },
};

export default NN_RESOURCE;
