const initUserData = {
  username: '',
  name: '',
  role: '',
  startDate: '',
  language: '',
  message: '',
  token: '',
};

const initSelectedMedia = {
  id: 0,
  mediaId: 0,
  title: '',
  thumbnailLandscape: '',
  thumbnailSquare: '',
  logline: '',
  synopsis: '',
  rating: '',
  cast: '',
  directors: '',
  viewProgress: 0,
  firstAired: '',
  runtime: 0,
  info: '',
  isSeries: false,
  trailer: '',
  isFavourite: false,
  landscapeKeyArt: '',
  seasons: [],
  sources: [],
  logo: '',
  titleTreatment: '',
  displayGenres: '',
  channelId: 0,
  logoColored: '',
};

const initSelectedProfile = {
  id: 0,
  name: '',
  userId: '',
  isKid: false,
  isProtected: false,
  isPin: false,
  thumbnail: '',
  type: 0,
};

const initBackground = {
  url: '',
  type: '',
  page: '',
};

export { initUserData, initSelectedMedia, initSelectedProfile, initBackground };
