import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

const AlertIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38" {...props}>
      <path
        d="M19 38A19 19 0 0 1 5.565 5.566a19 19 0 0 1 26.872 26.87A18.882 18.882 0 0 1 19 38zm0-12.977a1.42 1.42 0 0 0-1.421 1.416 1.419 1.419 0 1 0 2.838 0A1.419 1.419 0 0 0 19 25.023zm-.312-14.888-.006.008c-.015.012-.022.024-.031.024a2.207 2.207 0 0 0-1.823 2.562c.061.52.1 1.06.146 1.615l.051.659.125 1.511.131 1.574q.146 1.764.3 3.53v.017l.03.377c.029.362.061.769.115 1.159A1.287 1.287 0 0 0 19 24.243a1.149 1.149 0 0 0 .326-.048 1.342 1.342 0 0 0 .995-1.283c.042-.6.094-1.206.138-1.743v-.009l.077-.92.334-3.928q.042-.494.082-.989l.041-.493c.055-.688.116-1.422.182-2.14a2.261 2.261 0 0 0-1.247-2.352 2.839 2.839 0 0 0-.492-.154l-.061-.017c-.044-.009-.087-.021-.128-.032z"
        style={{
          fill: "#fff",
        }}
      />
    </SvgIcon>
  );
};

export default AlertIcon;
