const GA_RESOURCE = {
  translation: {
    loading: "Ag lódáil",
    search: {
      cancel: "Cealaigh",
      noResults:
        "Níor aimsíodh aon torthaí cuardaigh, ach is cinnte gur fiú na cinn thíos a sheiceáil amach.",
    },
    menu: {
      switchProfiles: "Próifíl a Athrú",
      settings: "Socruithe",
      close: "Dún",
      logOut: "Logáil Amach",
    },
    button: {
      apply: "Cuir i bhfeidhm",
      continue: "Lean ort",
      cancel: "Cealaigh",
      confirm: "Deimhnigh",
      play: "Seinn",
      addToWatchList: "Cuir leis an Liosta Faire",
      removeFromWatchList: "Bain den Liosta Faire",
      moreInfo: "Tuilleadh eolais",
      resume: "Atosaigh",
      close: "Dún",
      discard: "Caith",
      save: "Sábháil",
    },
    login: {
      header: "Logáil Isteach Cuntais",
      title: "Fáilte go",
      subTitle:
        "Bain an tairbhe is fearr as do shíntiúis sruthaithe le Netflix, Disney, Amazon agus go leor eile.",
      text: "Gach rud is mian leat féachaint air in aon áit amháin.",
      button: "Logáil isteach",
      placeholder: {
        username: "Ainm úsáideora",
        password: "Pasfhocal",
      },
      errors: {
        email: {
          noValid:
            "Caithfidh an ríomhphost a bheith ina sheoladh ríomhphoist bailí",
          required: "Is réimse riachtanach é ríomhphost",
        },
        password: {
          required: "Is réimse riachtanach é pasfhocal",
        },
        wrongPass: {
          first:
            "Úps, níorbh é sin an pasfhocal ceart. Bain triail eile as nó cliceáil ar ",
          second: "“Pasfhocal Dhearmadta” ",
          third: "cnaipe thíos.",
        },
      },
    },
    forgotPassword: {
      message:
        'Ná bí buartha. Tá sé éasca do phasfhocal a athshocrú. Níl le déanamh ach do sheoladh ríomhphoist a chur isteach thíos agus cliceáil "SEOL"',
      button: "SEOL",
      placeholder: {
        username: "Ainm úsáideora",
      },
      errors: {
        email: {
          required: "Is réimse riachtanach é ríomhphost",
        },
        emailNotRegister: "Níl an seoladh ríomhphoist cláraithe!",
        emailSent: "Athshocraigh pasfhocal ríomhphost seolta!",
      },
    },
    profilesPage: {
      title: "Cé atá ag féachaint?",
      subTitle:
        "Is féidir le gach duine in do charr taithí teilifíse pearsanta a bheith acu.",
      newProfile: "+ Cuir próifíl nua leis",
    },
    newProfile: {
      title: "Proifil Nua",
      description: "Cuir próifíl le haghaidh duine eile ag breathnú ar ScreenHits TV",
      isKid: "Páiste?",
      loading: "Ag lódáil",
      profileCreated: "Cruthaíodh an Phróifíl!",
      profileSelection: "Roghnú Próifíl",
    },
    settings: {
      accountDetails: {
        primary: "Sonraí cuntais",
        secondary: "Cuir ainm & ríomhphost shealbhóir an chuntais in eagar.",
      },
      manageProfiles: {
        primary: "Bainistigh próifílí",
        secondary: "Cuir sonraí próifíl in eagar",
        edit: "Cuir in eagar próifíl",
      },
      parentControl: {
        primary: "Rialú Tuismitheora",
        secondary: "Cruthaigh PIN / Athraigh PIN",
        instruction: {
          primary:
            "Cruthaigh (nuashonraigh) PIN do Bhainistíocht Tuismitheoirí",
          secondary:
            "(Cuir isteach 4 uimhir ghiniúna nuair atá an cláirseach ar oscailt)",
        },
        recoverPin: "Athshocraigh PIN",
        pinSentTo: "Seoladh PIN chuig",
        unlock: "Díchill",
        pinUndefined: "PIN seolta chuig an seoladh ríomhphoist"
      },
      membershipStatus: {
        primary: "Stádas Ballraíochta",
        secondary: "Gníomhach",
      },
      deactivateAccount: {
        primary: "Dícheanglaigh cuntas",
        secondary: "Dún do chuntas agus scaoil do chumais.",
        enterPass: "Cuir isteach do phasfhocal",
        discard: "Caith leis na hathruithe",
      },
      activeLanguage: "Teanga Ghníomhach",
      defaultLanguage: "Teanga an Chórais",
      changeDefaultLanguage: "Athraigh an gnáth-theanga",
      privacyPolicy: {
        text: "Beartas Príobháideachais",
      },
      settings: "Socruithe",
      lang: {
        en: "Béarla",
        it: "Iodálach",
        de: "Gearmáinis",
        esar: "Spáinnis(An Airgintín)",
        fr: "Fraincís",
        ja: "Seapánach",
        ro: "Rómáinis",
        da: "Danmhairgis",
        nl: "Ollainnis",
        ca: "Chatalóinis",
        lb: "Lucsambuirgis",
        no: "Ioruais",
        ptbr: "Portaingéilis(An Bhrasaíl)",
        zhcn: "Sínise",
        ms: "Malaeis",
        ta: "Tamailis",
        bs: "Boisnis",
        bg: "Bulgáiris",
        hr: "Cróitis",
        tr: "Tuircis",
        cs: "Seicis",
        et: "Eastóinis",
        fi: "Fionlainnis",
        el: "Gréigis",
        hu: "Ungáiris",
        ga: "Gaeilge",
        is: "Íoslainnis",
        lv: "Laitvis",
        lt: "Liotuáinise",
        mt: "Máltais",
        pl: "Polainnis",
        pt: "Portaingéilis(an Phortaingéil)",
        ru: "Rúisis",
        sr: "Seirbis",
        sk: "Slóvaicis",
        sl: "Slóivéinis",
        sv: "Sualainnis",
        mi: "Maorais ",
        ar: "Araibis",
        af: "Afracáinis ",
        zhtw: "Sínis (Traidisiúnta)",
        es: "Spáinnis (An Spáinn)",
        ko: "Cóiréis",
        enus: "English (United States)",
        deat: "German (Austria)",
        dech: "German (Switzerland)",
        deli: "German (Liechtenstein)",
        dede: "German (Deutschland)",
        enca: "English (Canada)",
        engb: "English",
        enie: "English (Ireland)",
        ennz: "English (New Zealand)",
        enza: "English (South Africa)",
        esmx: "Spanish (Mexico)",
        frca: "French (Canada)",
        frch: "French (Switzerland)",
        itch: "Italian (Switzerland)",
        jajp: "Japanese (Japan)",
        kokr: "Korean (South Korea)",
        nb: "Norwegian (Bokmål)",
        nn: "Norwegian (Nynorsk)",
        zhch: "Chinese (PRC)",
      },

      language: {
        primary: "Teanga",
      },
    },
    show: {
      more: " Taispeáin níos mó",
      less: " Taispeáin níos lú",
    },
    showPage: {
      seasons: "Séasúir",
      cast: "Cliar",
      producers: "Táirgeoirí",
      genre: "Seánra",
      directedBy: "Faoi stiúir",
      season: "Séasúr",
    },
    accountDetails: {
      firstName: "Ainm",
      lastName: "Sloinne",
      email: "Ríomhphost",
      password: "Pasfhocal",
      changePassword: "Athraigh do phasfhocal",
      forgotPassword: "Pasfhocal Dhearmadta",
    },
    childPinPopup: {
      title: "Cosaint Leanaí",
      subTitle: "Cruthaigh (nuashonraigh) PIN do Chosaint Leanaí",
      pinUpdated: "Nuashonraíodh an PIN",
      pinIsIncorrect: "Tá an PIN mícheart",
    },
    deactivateAccount: {
      title:
        "An bhfuil tú cinnte gur mhaith leat do chuntas a dhíghníomhachtú?",
      description:
        "Nuair a dhéanfaidh tú amhlaidh, logáilfear amach as na seirbhísí go léir thú agus ní bheidh tú in ann féachaint ar ábhar i do charr a thuilleadh",
      deactivateButton: "Díghníomhachtaigh cuntas",
    },
    servicesPage: {
      add: "Cuir le seirbhísí",
      remove: "Bain as seirbhísí",
      overflow: {
        title: "Pacáistí Roghnaithe",
        subtitle: "Roghnaigh do Sheirbhísí",
        description: "Cuir gach seirbhís a úsáideann tú le do threoir.",
      },
    },
    manageProfile: {
      text: "Bainistigh Próifílí",
    },
    watchlist: {
      title:
        "Seo na scannáin agus seónna go léir a chuir tú le do Liosta Faire",
      noTitles: "Níl aon teideal agat i do Liosta Faire faoi láthair",
      addText:
        "Chun cur leis, cliceáil le do thoil ar + Cuir leis an Liosta Faire ar aon seó ar mhaith leat filleadh air agus féachaint air níos déanaí.",
      subtext: {
        start: "Chun cur leis, cliceáil le do thoil ar ",
        toWatchList: "Cuir leis an liosta faire",
        end: "d’aon seó ar mhaith leat filleadh air agus féachaint air níos déanaí.",
      },
    },
    streamingService: {
      title: "Roghnaigh Seirbhísí Sruthaithe",
      subTitle:
        "Cuir do sheirbhísí roghnaithe leis le go mbeidh siad le feiceáil i do Threoir Teilifíse",
      streamingServices: "Seirbhísí Sruthaithe",
    },
    profileUpdate: {
      backToSettings: "Ar ais go Socruithe",
      updatedSuccess: "Nuashonraíodh an t-úsáideoir",
      apply: "Cuir i bhfeidhm",
      wrongPassword: "Pasfhocal mícheart",
    },
    changePasswordPage: {
      backToSettings: "Ar ais go Socruithe",
      currentPass: "Pasfhocal reatha",
      newPass: "Pasfhocal Nua",
      confirmPass: "Deimhnigh Pasfhocal",
      change: "Athraigh",
      changeUserInfo: "Athraigh Eolas Úsáideora",
      passwordDoNotMatch: "Ní mheaitseálann pasfhocail nó tá siad neamhbhailí",
      passwordUpdated: "Nuashonraíodh an pasfhocal!",
    },
    noServices: {
      title: "Níl aon seirbhísí i do TV Guide agat faoi láthair",
      text: "Chun an leas is mó a bhaint as ScreenHits TV, ní mór duit seirbhísí a chur leis. Cliceáil anseo le do thoil chun na sruthóirí a bhfuil tú ag liostáil leo cheana féin a chur leis gan aon chostas breise. Agus mura bhfuil tú ag liostáil le haon seirbhís sruthaithe faoi láthair, cuir ár mbeart SAOR IN AISCE leis agus cuir tús le hábhar iontach a fheiceáil.",
      button: "Cuir Seirbhísí leis",
    },
    deleteProfile: {
      cantDeleteProfile: "Ní féidir leat do phróifíl reatha a scriosadh",
      areYouSure:
        "An bhfuil tú cinnte gur mhaith leat an phróifíl seo a scriosadh?",
      delete: "Scrios próifíl",
    },
    notFoundPage: {
      title: "Theip ar rud éigin",
      text: "Ná bí buartha, áfach, tá gach rud ceart go leor. Tiocfaimid ar ais leis an leathanach seo.",
      home: "Baile",
    },
    noInternet: {
      title: "Gan Nasc Idirlíon",
      description:
        "Ná bí buartha, tá gach rud ceart go leor. Tiocfaimid ar ais leis an leathanach seo.",
      button: "Athlódáil",
    },
  },
};

export default GA_RESOURCE;
