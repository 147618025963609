const SL_RESOURCE = {
  translation: {
    loading: "Nalaganje",
    search: {
      cancel: "Prekliči",
      noResults:
        "Ni rezultatov iskanja, vendar so spodnji vsekakor vredni ogleda.",
    },
    menu: {
      switchProfiles: "Preklopi med profili",
      settings: "Nastavitve",
      close: "Zapri",
      logOut: "Odjava",
    },
    button: {
      apply: "Uporabi",
      continue: "Nadaljuj",
      cancel: "Prekliči",
      confirm: "Potrdi",
      play: "Predvajaj",
      addToWatchList: "Dodaj na seznam za ogled",
      removeFromWatchList: "Odstrani s seznama za ogled",
      moreInfo: "Več informacij",
      resume: "Nadaljuj",
      close: "Zapri",
      discard: "Zavrzi",
      save: "Shrani",
    },
    login: {
      header: "Prijava v račun",
      title: "Dobrodošli v",
      subTitle:
        "Kar najbolje izkoristite naročnine na pretočne vsebine z Disneyjem, Amazonom in drugimi storitvami.",
      text: "Vse, kar želite gledati, na enem mestu.",
      button: "Prijava",
      placeholder: {
        username: "Uporabniško ime",
        password: "Geslo",
      },
      errors: {
        email: {
          noValid: "E-pošta mora biti veljavni e-poštni naslov",
          required: "E-pošta je obvezno polje",
        },
        password: {
          required: "Geslo je obvezno polje",
        },
        wrongPass: {
          first:
            "Ups, to ni bilo pravo geslo. Poskusite znova ali kliknite na spodnji gumb ",
          second: "“Pozabljeno geslo” ",
          third: "",
        },
      },
    },
    forgotPassword: {
      message:
        'Ne skrbite. Ponastavitev gesla je preprosta. Samo vnesite svoj e-poštni naslov spodaj in kliknite "POŠLJI".',
      button: "POŠLJI",
      placeholder: {
        username: "Uporabniško ime",
      },
      errors: {
        email: {
          required: "E-pošta je obvezno polje",
        },
        emailNotRegister: "E-poštni naslov ni registriran!",
        emailSent: "Poslano e-poštno sporočilo za ponastavitev gesla!",
      },
    },
    profilesPage: {
      title: "Kdo gleda?",
      subTitle:
        "Vsak v vašem avtomobilu lahko doživi personalizirano TV izkušnjo.",
      newProfile: "+ Dodaj nov profil",
    },
    newProfile: {
      title: "Nov profil",
      description: "Dodaj profil za drugo osebo, ki gleda ScreenHits TV",
      isKid: "Otrok?",
      loading: "Nalaganje",
      profileCreated: "Profil uspešno ustvarjen!",
      profileSelection: "Izbira profila",
    },
    settings: {
      accountDetails: {
        primary: "Podatki o računu",
        secondary: "Urejanje imena imetnika računa in e-pošte.",
      },
      manageProfiles: {
        primary: "Upravljanje profilov",
        secondary: "Uredi podrobnosti profila",
        edit: "Uredi profil",
      },
      parentControl: {
        primary: "Starševski nadzor",
        secondary: "Ustvari PIN / Spremeni PIN",
        instruction: {
          primary: "Ustvari (posodobi) PIN za starševski nadzor",
          secondary:
            "(Prosimo, vnesite 4 številke medtem ko je tipkovnica odprta)",
        },
        recoverPin: "Obnovi PIN",
        pinSentTo: "PIN poslan na",
        unlock: "Odkleni",
        pinUndefined: "PIN poslan na e-poštni naslov"
      },
      membershipStatus: {
        primary: "Status članstva",
        secondary: "Aktivno",
      },
      deactivateAccount: {
        primary: "Deaktiviraj račun",
        secondary: "Zaprite račun in odklopite povezave med napravami.",
        enterPass: "Vnesite geslo",
        discard: "Zavrzi spremembe",
      },
      activeLanguage: "Aktivni jezik",
      defaultLanguage: "Sistemski jezik",
      changeDefaultLanguage: "Spremeni privzeti jezik",
      privacyPolicy: {
        text: "Pravilnik o zasebnosti",
      },
      settings: "Nastavitve",
      lang: {
        en: "Angleščina",
        it: "Italijanščina",
        de: "Nemščina",
        esar: "Španščina(Argentina)",
        fr: "Francoščina",
        ja: "Japonščina",
        ro: "Romunščina",
        da: "Danščina",
        nl: "Nizozemščina",
        ca: "Katalonščina",
        lb: "Luksemburščina",
        no: "Norveščina",
        ptbr: "Portugalščina(Brazilija)",
        zhcn: "Kitajščina",
        ms: "Malajščina",
        ta: "Tamilščina",
        bs: "Bosanščina",
        bg: "Bolgarščina",
        hr: "Hrvaščina",
        tr: "Turščina",
        cs: "Češčina",
        et: "Estonščina",
        fi: "Finščina",
        el: "Grščina",
        hu: "Madžarščina",
        ga: "Irščina",
        is: "Islandščina",
        lv: "Latvijščina",
        lt: "Litovščina",
        mt: "Malteščina",
        pl: "Poljščina",
        pt: "Portugalščina(Portugalska)",
        ru: "Ruščina",
        sr: "Srbščina",
        sk: "Slovaščina",
        sl: "Slovenščina",
        sv: "Švedščina",
        mi: "Maorščina",
        ar: "Arabščina",
        af: "Afrikanščina",
        zhtw: "Kitajščina(Tradicionalna)",
        es: "Španščina(Španija)",
        ko: "Korejščina",
        enus: "Angleščina (Združene države)",
        deat: "Nemščina (Avstrija)",
        dech: "Nemščina (Švica)",
        deli: "Nemščina (Lihtenštajn)",
        dede: "Nemščina (Nemčija)",
        enca: "Angleščina (Kanada)",
        engb: "Angleščina",
        enie: "Angleščina (Irska)",
        ennz: "Angleščina (Nova Zelandija)",
        enza: "Angleščina (Južna Afrika)",
        esmx: "Španščina (Mehika)",
        frca: "Francoščina (Kanada)",
        frch: "Francoščina (Švica)",
        itch: "Italijanščina (Švica)",
        jajp: "Japonščina (Japonska)",
        kokr: "Korejščina (Južna Koreja)",
        nb: "Norveščina (Bokmål)",
        nn: "Norveščina (Nynorsk)",
        zhch: "Kitajščina (NR Kitajska)",
      },
      language: {
        primary: "Jezik",
      },
    },
    show: {
      more: " Pokaži več",
      less: " Pokaži manj",
    },
    showPage: {
      seasons: "Sezone",
      cast: "Zasedba",
      producers: "Producenti",
      genre: "Žanr",
      directedBy: "Režija:",
      season: "Sezona",
    },
    accountDetails: {
      firstName: "Ime",
      lastName: "Priimek",
      email: "E-pošta",
      password: "Geslo",
      changePassword: "Spremeni geslo",
      forgotPassword: "Pozabljeno geslo",
    },
    childPinPopup: {
      title: "Zaščita otrok",
      subTitle: "Ustvari (posodobi) kodo PIN za zaščito otrok",
      pinUpdated: "PIN je bil uspešno posodobljen.",
      pinIsIncorrect: "PIN je napačen",
    },
    deactivateAccount: {
      title: "Ste prepričani, da želite deaktivirati svoj račun?",
      description:
        "Ko to storite, boste odjavljeni iz vseh storitev in ne boste mogli več pregledovati vsebine v svojem avtomobilu.",
      deactivateButton: "Deaktiviraj račun",
      discard: "Discard Changes",
    },
    servicesPage: {
      add: "Dodaj v storitve",
      remove: "Odstrani iz storitev",
      overflow: {
        title: "Izbrani paketi",
        subtitle: "Izberite svoje storitve",
        description: "Dodajte vse storitve, ki jih uporabljate, v svoj vodnik.",
      },
    },
    manageProfile: {
      text: "Upravljanje profilov",
    },
    watchlist: {
      title:
        "Tukaj so vsi filmi in oddaje, ki ste jih dodali na svoj seznam za ogled",
      noTitles: "Trenutno nimate nobenega naslova na seznamu za ogled",
      addText:
        "Če želite dodati, kliknite + Dodaj na seznam za ogled oddaje, ki si jo želite ogledati pozneje.",
      subtext: {
        start: "Če želite dodati, kliknite na ",
        toWatchList: "Dodajte na seznam za ogled",
        end: "katero koli oddajo, h kateri bi se radi vrnili in si jo ogledali pozneje.",
      },
    },
    streamingService: {
      title: "Izberite storitve pretakanja",
      subTitle: "Dodajte izbrane storitve, ki bodo prikazane v TV vodniku",
      streamingServices: "Storitve pretakanja",
    },
    profileUpdate: {
      backToSettings: "Nazaj v nastavitve",
      updatedSuccess: "Uporabnik je bil uspešno posodobljen",
      apply: "Uporabi",
      wrongPassword: "Napačno geslo",
    },
    changePasswordPage: {
      backToSettings: "Nazaj v nastavitve",
      currentPass: "Trenutno geslo",
      newPass: "Novo geslo",
      confirmPass: "Potrdi geslo",
      change: "Spremeni",
      changeUserInfo: "Spremeni informacije o uporabniku",
      passwordDoNotMatch: "Gesla se ne ujemajo ali so neveljavna",
      passwordUpdated: "Geslo je bilo uspešno posodobljeno",
    },
    noServices: {
      title: "Trenutno nimate nobene storitve v TV vodniku",
      text: "Če želite kar najbolje izkoristiti ScreenHits TV, morate dodati storitve. Kliknite tukaj, če želite brez dodatnih stroškov dodati pretakalce, na katere ste že naročeni. In če trenutno niste naročeni na nobeno storitev pretakanja, dodajte naš BREZPLAČNI paket in začnite odkrivati odlično vsebino BBC, ITV, My5, Channel 4, UKTV in STV.",
      button: "Dodaj storitve",
    },
    deleteProfile: {
      cantDeleteProfile: "Trenutnega profila ne morete izbrisati",
      areYouSure: "Ali ste prepričani, da želite izbrisati ta profil?",
      delete: "Odstrani profil",
    },
    notFoundPage: {
      title: "Nekaj je šlo narobe",
      text: "Brez skrbi, vse je v redu. S to stranjo se bomo vrnili.",
      home: "Domača stran",
    },
    noInternet: {
      title: "Brez internetne povezave",
      description: "Ne skrbite, vse je v redu. Vrnili se bomo s to stranjo.",
      button: "Ponovno naloži",
    },
  },
};

export default SL_RESOURCE;
