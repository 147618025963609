import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// German languages
import DE_RESOURCE from "./locales/de";
import DE_AT_RESOURCE from "./locales/de-at";
import DE_CH_RESOURCE from "./locales/de-ch";
import DE_LI_RESOURCE from "./locales/de-li";

// English languages
import EN_RESOURCE from "./locales/en";
import EN_CA_RESOURCE from "./locales/en-ca";
import EN_GB_RESOURCE from "./locales/en-gb";
import EN_IE_RESOURCE from "./locales/en-ie";
import EN_NZ_RESOURCE from "./locales/en-nz";
import EN_US_RESOURCE from "./locales/en-us";
import EN_ZA_RESOURCE from "./locales/en-za";

// French languages
import FR_RESOURCE from "./locales/fr";
import FR_CA_RESOURCE from "./locales/fr-ca";
import FR_CH_RESOURCE from "./locales/fr-ch";

// Italian languages
import IT_RESOURCE from "./locales/it";
import IT_CH_RESOURCE from "./locales/it-ch";

import JA_RESOURCE from "./locales/ja";
import JA_JP_RESOURCE from "./locales/ja-jp";
import RO_RESOURCE from "./locales/ro";
import PT_BR_RESOURCE from "./locales/pt-br";
import NL_RESOURCE from "./locales/nl";
import ZH_CN_RESOURCE from "./locales/zh-cn";
import DA_RESOURCE from "./locales/da";
import CA_RESOURCE from "./locales/ca";
import LB_RESOURCE from "./locales/lb";

// Norwegian languages
import NO_RESOURCE from "./locales/no";
import NN_RESOURCE from "./locales/nn";
import NB_RESOURCE from "./locales/nb";

import MS_RESOURCE from "./locales/ms";
import TA_RESOURCE from "./locales/ta";
import BS_RESOURCE from "./locales/bs";
import BG_RESOURCE from "./locales/bg";
import HR_RESOURCE from "./locales/hr";
import TR_RESOURCE from "./locales/tr";
import CS_RESOURCE from "./locales/cs";
import ET_RESOURCE from "./locales/et";
import FI_RESOURCE from "./locales/fi";
import EL_RESOURCE from "./locales/el";
import HU_RESOURCE from "./locales/hu";
import GA_RESOURCE from "./locales/ga";
import IS_RESOURCE from "./locales/is";
import LV_RESOURCE from "./locales/lv";
import LT_RESOURCE from "./locales/lt";
import MT_RESOURCE from "./locales/mt";
import PL_RESOURCE from "./locales/pl";
import PT_RESOURCE from "./locales/pt";
import ZH_TW_RESOURCE from "./locales/zh-tw";
import RU_RESOURCE from "./locales/ru";
import SR_RESOURCE from "./locales/sr";
import SL_RESOURCE from "./locales/sl";
import SV_RESOURCE from "./locales/sv";
import MI_RESOURCE from "./locales/mi";
import AR_RESOURCE from "./locales/ar";
import AF_RESOURCE from "./locales/af";
import ES_RESOURCE from "./locales/es";
import SK_RESOURCE from "./locales/sk";
import ES_AR_RESOURCE from "./locales/es-ar";
import KO_RESOURCE from "./locales/ko";
import KO_KR_RESOURCE from "./locales/ko-kr";
import ES_MX_RESOURCE from "./locales/es-mx";

i18n.use(initReactI18next).init({
  resources: {
    en: EN_RESOURCE,
    enca: EN_CA_RESOURCE,
    engb: EN_GB_RESOURCE,
    enie: EN_IE_RESOURCE,
    ennz: EN_NZ_RESOURCE,
    enus: EN_US_RESOURCE,
    enza: EN_ZA_RESOURCE,
    de: DE_RESOURCE,
    dede: DE_RESOURCE,
    deat: DE_AT_RESOURCE,
    dech: DE_CH_RESOURCE,
    deli: DE_LI_RESOURCE,
    ja: JA_RESOURCE,
    jajp: JA_JP_RESOURCE,
    it: IT_RESOURCE,
    itch: IT_CH_RESOURCE,
    ro: RO_RESOURCE,
    fr: FR_RESOURCE,
    frca: FR_CA_RESOURCE,
    frch: FR_CH_RESOURCE,
    es: ES_RESOURCE,
    esmx: ES_MX_RESOURCE,
    ptbr: PT_BR_RESOURCE,
    nl: NL_RESOURCE,
    zhcn: ZH_CN_RESOURCE,
    da: DA_RESOURCE,
    ca: CA_RESOURCE,
    lb: LB_RESOURCE,
    no: NO_RESOURCE,
    nn: NN_RESOURCE,
    nb: NB_RESOURCE,
    ms: MS_RESOURCE,
    ta: TA_RESOURCE,
    bs: BS_RESOURCE,
    bg: BG_RESOURCE,
    hr: HR_RESOURCE,
    tr: TR_RESOURCE,
    cs: CS_RESOURCE,
    et: ET_RESOURCE,
    fi: FI_RESOURCE,
    el: EL_RESOURCE,
    hu: HU_RESOURCE,
    ga: GA_RESOURCE,
    is: IS_RESOURCE,
    lv: LV_RESOURCE,
    lt: LT_RESOURCE,
    mt: MT_RESOURCE,
    pl: PL_RESOURCE,
    pt: PT_RESOURCE,
    zhtw: ZH_TW_RESOURCE,
    ru: RU_RESOURCE,
    sr: SR_RESOURCE,
    sl: SL_RESOURCE,
    sv: SV_RESOURCE,
    mi: MI_RESOURCE,
    ar: AR_RESOURCE,
    af: AF_RESOURCE,
    sk: SK_RESOURCE,
    esar: ES_AR_RESOURCE,
    ko: KO_RESOURCE,
    kokr: KO_KR_RESOURCE,
  },
  // fallbackLng: "en",
  load: "all",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
