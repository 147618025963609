const TR_RESOURCE = {
  translation: {
    loading: "Yükleniyor",
    search: {
      cancel: "İptal",
      noResults:
        "Hiçbir arama sonucu bulunamadı, ancak aşağıdakilere göz atılması kesinlikle faydalı olacaktır.",
    },
    menu: {
      switchProfiles: "Profiller Arasında Geçiş Yap",
      settings: "Ayarlar",
      close: "Kapat",
      logOut: "Oturumu Kapat",
    },
    button: {
      apply: "Uygula",
      continue: "Devam Et",
      cancel: "İptal",
      confirm: "Onayla",
      play: "Oynat",
      addToWatchList: "İzleme listesine ekle",
      removeFromWatchList: "İzleme Listesinden çıkar",
      moreInfo: "Daha fazla bilgi",
      resume: "Devam Et",
      close: "Kapat",
      discard: "At",
      save: "Kaydet",
    },
    login: {
      header: "Hesapta Oturum Aç",
      title: "Hoş geldiniz:",
      subTitle:
        "Disney, Amazon ve daha fazlası ile yayın aboneliklerinizden en iyi şekilde faydalanın.",
      text: "Tek bir yerde izlemek istediğiniz her şey.",
      button: "Oturum Aç",
      placeholder: {
        username: "Kullanıcı Adı",
        password: "Parola",
      },
      errors: {
        email: {
          noValid: "E-posta adresi geçerli bir adres olmalıdır",
          required: "E-posta gerekli bir alandır",
        },
        password: {
          required: "Parola gerekli bir alandır",
        },
        wrongPass: {
          first:
            "Hay aksi, bu doğru parola değildi. Lütfen tekrar deneyin veya aşağıdaki ",
          second: "“Parolamı Unuttum” ",
          third: "düğmesine tıklayın.",
        },
      },
    },
    forgotPassword: {
      message:
        'Endişelenmeyin. Parolanızı sıfırlamak çok kolaydır. Sadece aşağıya e-posta adresinizi girin ve "GÖNDER" seçeneğine tıklayın',
      button: "GÖNDER",
      placeholder: {
        username: "Kullanıcı Adı",
      },
      errors: {
        email: {
          required: "E-posta gerekli bir alandır",
        },
        emailNotRegister: "E-posta adresi kayıtlı değil!",
        emailSent: "Parola sıfırlama e-postası gönderildi!",
      },
    },
    profilesPage: {
      title: "Kim İzliyor?",
      subTitle:
        "Arabanızdaki herkes kişiselleştirilmiş bir TV deneyimi yaşayabilir.",
      newProfile: "+ Yeni profil ekle",
    },
    newProfile: {
      title: "Yeni Profil",
      description: "ScreenHits TV izleyen başka bir kişi için profil ekleyin",
      isKid: "Çocuk?",
      loading: "Yükleniyor",
      profileCreated: "Profil başarıyla oluşturuldu!",
      profileSelection: "Profil Seçimi",
    },
    settings: {
      accountDetails: {
        primary: "Hesap bilgileri",
        secondary: "Hesap sahibinin adını ve e-postasını düzenleyin.",
      },
      manageProfiles: {
        primary: "Profilleri Yönet",
        secondary: "Profil Detaylarını Düzenle",
        edit: "Profil Düzenle",
      },
      parentControl: {
        primary: "Ebeveyn Kontrolü",
        secondary: "PIN Oluştur / PIN Değiştir",
        instruction: {
          primary: "Ebeveyn Kontrolü için PIN oluştur (güncelle)",
          secondary: "(Lütfen klavye açıkken 4 hane girin)",
        },
        recoverPin: "PIN'i Sıfırla",
        pinSentTo: "PIN gönderildiği adres:",
        unlock: "Kilidi Aç",
        pinUndefined: "PIN e-posta adresine gönderildi"
      },
      membershipStatus: {
        primary: "Üyelik Durumu",
        secondary: "Etkin",
      },
      deactivateAccount: {
        primary: "Hesabı devre dışı bırak",
        secondary:
          "Hesabınızı kapatın ve cihazlarınızın bağlantısını kaldırın.",
        enterPass: "Parolanızı Girin",
        discard: "Değişiklikleri iptal et",
      },
      activeLanguage: "Etkin Dil",
      defaultLanguage: "Sistem Dili",
      changeDefaultLanguage: "Varsayılan dil değiştir",
      privacyPolicy: {
        text: "Gizlilik Politikası",
      },
      settings: "Ayarlar",
      lang: {
        en: "İngilizce",
        it: "İngilizce",
        de: "Almanca",
        esar: "İspanyolca(Arjantin)",
        fr: "Fransızca",
        ja: "Japonca",
        ro: "Romence",
        da: "Danca",
        nl: "Flemenkçe",
        ca: "Katalanca",
        lb: "Lüksemburgca",
        no: "Norveççe",
        ptbr: "Portekizce(Brezilya)",
        zhcn: "Çince",
        ms: "Malay Dili",
        ta: "Tamil Dili",
        bs: "Boşnakça",
        bg: "Bulgarca",
        hr: "Hırvatça",
        tr: "Türkçe",
        cs: "Çekçe",
        et: "Estonca",
        fi: "Fince",
        el: "Yunanca",
        hu: "Macarca",
        ga: "İrlandaca",
        is: "İzlandaca",
        lv: "Letonca",
        lt: "Litvanyaca",
        mt: "Malta Dili",
        pl: "Lehçe",
        pt: "Portekizce (Portekiz)",
        ru: "Rusça",
        sr: "Sırpça",
        sk: "Slovakça",
        sl: "Slovence",
        sv: "İsveççe",
        mi: "Māori Dili",
        ar: "Arapça",
        af: "Afrikanca",
        zhtw: "Çince (Geleneksel)",
        es: "İspanyolca (İspanya)",
        ko: "Korece",
        enus: "İngilizce (Amerika Birleşik Devletleri)",
        deat: "Almanca (Avusturya)",
        dech: "Almanca (İsviçre)",
        deli: "Almanca (Lihtenştayn)",
        dede: "Almanca (Almanya)",
        enca: "İngilizce (Kanada)",
        engb: "İngilizce",
        enie: "İngilizce (İrlanda)",
        ennz: "İngilizce (Yeni Zelanda)",
        enza: "İngilizce (Güney Afrika)",
        esmx: "İspanyolca (Meksika)",
        frca: "Fransızca (Kanada)",
        frch: "Fransızca (İsviçre)",
        itch: "İtalyanca (İsviçre)",
        jajp: "Japonca (Japonya)",
        kokr: "Korece (Güney Kore)",
        nb: "Norveççe (Bokmål)",
        nn: "Norveççe (Nynorsk)",
        zhch: "Çince (Halk Cumhuriyeti Çin)",
      },
      language: {
        primary: "Dil",
      },
    },
    show: {
      more: " Daha fazla göster",
      less: " Daha az göster",
    },
    showPage: {
      seasons: "Sezonlar",
      cast: "Oyuncular",
      producers: "Yapımcılar",
      genre: "Tür",
      directedBy: "Yönetmen",
      season: "Sezon",
    },
    accountDetails: {
      firstName: "Adı",
      lastName: "Soyadı",
      email: "E-posta",
      password: "Parola",
      changePassword: "Parolayı Değiştir",
      forgotPassword: "Parolamı Unuttum",
    },
    childPinPopup: {
      title: "Çocuk Koruması",
      subTitle: "Çocuk Koruması için PIN oluştur (güncelle)",
      pinUpdated: "PIN başarıyla güncellendi",
      pinIsIncorrect: "PIN yanlış",
    },
    deactivateAccount: {
      title: "Hesabınızı devre dışı bırakmak istediğinizden emin misiniz?",
      description:
        "Bunu yaptığınız zaman, tüm hizmetlerdeki oturumunuz kapatılacak ve artık aracınızda içerik görüntüleyemeyeceksiniz",
      deactivateButton: "Hesabı devre dışı bırak",
    },
    servicesPage: {
      add: "Hizmetlere ekle",
      remove: "Hizmetlerden kaldır",
      overflow: {
        title: "Seçilmiş Paketler",
        subtitle: "Hizmetlerinizi Seçin",
        description: "Kullandığınız her hizmeti rehberinize ekleyin.",
      },
    },
    manageProfile: {
      text: "Profilleri Yönet",
    },
    watchlist: {
      title: "İzleme Listenize eklediğiniz tüm filmler ve diziler buradadır",
      noTitles: "Şu anda İzleme Listenizde hiçbir yayın yok",
      addText:
        "Eklemek için, lütfen daha sonraki bir tarihte geri dönüp izlemek istediğiniz herhangi bir programda + İzleme Listesine Ekle'ye tıklayın.",
      subtext: {
        start: "Eklemek için lütfen şuna tıklayın:  ",
        toWatchList: "İzleme listesine ekle",
        end: "(daha sonraki bir tarihte geri dönüp izlemek istediğiniz herhangi bir dizi için).",
      },
    },
    streamingService: {
      title: "Yayın Hizmetlerini Seç",
      subTitle: "TV Rehberinizde görünmesiniistediğiniz yayınları ekleyin",
      streamingServices: "Yayın Hizmetleri",
    },
    profileUpdate: {
      backToSettings: "Ayarlara Geri Dön",
      updatedSuccess: "Kullanıcı başarıyla güncellendi",
      apply: "Uygula",
      wrongPassword: "Yanlış Parola",
    },
    changePasswordPage: {
      backToSettings: "Ayarlara Geri Dön",
      currentPass: "Geçerli Parola",
      newPass: "Yeni Parola",
      confirmPass: "Parolayı Onayla",
      change: "Değiştir",
      changeUserInfo: "Kullanıcı Bilgilerini Değiştir",
      passwordDoNotMatch: "Parolalar eşleşmiyor veya geçersiz",
      passwordUpdated: "Parola başarıyla güncellendi",
    },
    noServices: {
      title: "Şu anda TV Rehberinizde herhangi bir yayın yok",
      text: "ScreenHits TV'den en iyi şekilde faydalanmak için yayınlar eklemeniz gerekmektedir. Halihazırda abone olduğunuz yayıncıları ek ücret ödemeden eklemek için lütfen buraya tıklayın. Ayrıca şu anda herhangi bir yayın hizmetine abone değilseniz, ÜCRETSİZ paketimizi buraya ekleyin ve BBC, ITV, My5, Channel 4, UKTV ve STV'den harika içerikleri keşfetmeye başlayın.",
      button: "Hizmet Ekle",
    },
    deleteProfile: {
      cantDeleteProfile: "Mevcut profilinizi silemezsiniz",
      areYouSure: "Bu profili silmek istediğinizden emin misiniz?",
      delete: "Profili Kaldır",
    },
    notFoundPage: {
      title: "Bir şeyler ters gitti",
      text: "Yine de merak etmeyin, her şey yolunda. Bu sayfa ile geri döneceğiz.",
      home: "Ana Sayfa",
    },
    noInternet: {
      title: "İnternet Bağlantısı Yok",
      description:
        "Endişelenmeyin, her şey yolunda. Bu sayfa ile geri döneceğiz.",
      button: "Yeniden Yükle",
    },
  },
};

export default TR_RESOURCE;
