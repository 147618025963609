const MT_RESOURCE = {
  translation: {
    loading: "Qed jillowdja",
    search: {
      cancel: "Ikkanċella",
      noResults:
        "Ma nstab l-ebda riżultat tat-tiftix, iżda dawk ta’ hawn taħt żgur għandek tiċċekkjahom.",
    },
    menu: {
      switchProfiles: "Aqleb il-Profil",
      settings: "Settings",
      close: "Agħlaq",
      logOut: "Oħroġ",
    },
    button: {
      apply: "Applika",
      continue: "Kompli",
      cancel: "Ikkanċella",
      confirm: "Ikkonferma",
      play: "Play",
      addToWatchList: "Żid mal-watchlist",
      removeFromWatchList: "Neħħi mill-Watchlist",
      moreInfo: "Aktar informazzjoni",
      resume: "Kompli",
      close: "Agħlaq",
      discard: "Neħħi",
      save: "Savja",
    },
    login: {
      header: "Login tal-Kont",
      title: "Merħba f’",
      subTitle:
        "Ibbenefika bl-aħjar mod mill-abbonamenti tal-istreaming tiegħek ma’ Netflix, Disney, Amazon u aktar.",
      text: "Dak kollu li tixtieq tara f’post wieħed.",
      button: "Idħol",
      placeholder: {
        username: "Isem l-utent",
        password: "Password",
      },
      errors: {
        email: {
          noValid: "L-email irid tkun indirizz elettroniku validu",
          required: "L-email huwa qasam meħtieġ",
        },
        password: {
          required: "Il-password hija qasam meħtieġ",
        },
        wrongPass: {
          first:
            "Upps, dik ma kinitx il-password it-tajba. Jekk jogħġbok erġa’ pprova jew ikklikkja fuq il-buttuna ",
          second: "“Insejt il-password” ",
          third: "hawn taħt.",
        },
      },
    },
    forgotPassword: {
      message:
        'Tinkwetax. Huwa faċli li tirrisettja l-password tiegħek. Daħħal l-indirizz elettroniku tiegħek hawn taħt u kklikkja "IBGĦAT"',
      button: "IBGĦAT",
      placeholder: {
        username: "Isem l-utent",
      },
      errors: {
        email: {
          required: "L-email huwa qasam meħtieġ",
        },
        emailNotRegister: "L-indirizz elettroniku mhux irreġistrat!",
        emailSent: "Intbagħtet l-email biex tirrisettja l-password!",
      },
    },
    profilesPage: {
      title: "Min qed jara?",
      subTitle:
        "Kull persuna fil-karozza tiegħek tista' għandha esperjenza tal-TV personalizzata.",
      newProfile: "+ Żid profil ġdid",
    },
    newProfile: {
      title: "Profil Ġdid",
      description: "Żid profill għal persuna oħra li qed tara ScreenHits TV",
      isKid: "Tifel?",
      loading: "Qed jillowdja",
      profileCreated: "Irnexxielek toħloq profil!",
      profileSelection: "Selezzjoni tal-Profil",
    },
    settings: {
      accountDetails: {
        primary: "Dettalji tal-kont",
        secondary: "Editja l-isem tad-detentur tal-kont u l-email.",
      },
      manageProfiles: {
        primary: "Amministra Profili",
        secondary: "Editja tal-dettalji tal-profil",
        edit: "Editja Profil",
      },
      parentControl: {
        primary: "Kontroll tal-ġenituri",
        secondary: "Oħloq PIN / Ibiddel il-PIN",
        instruction: {
          primary: "Oħloq (aġġorna) il-PIN għal Kontroll Patern",
          secondary:
            "(Jekk jogħġbok idħol 4 ċifri waqt li l-keyboard huwa miftuħ)",
        },
        recoverPin: "Irrikupera l-PIN",
        pinSentTo: "PIN mitqiegħed għal",
        unlock: "Iftħ",
        pinUndefined: "PIN mibgħut lill-indirizz tal-email"
      },
      membershipStatus: {
        primary: "Status tal-Isħubija",
        secondary: "Attiva",
      },
      deactivateAccount: {
        primary: "Deattiva l-kont",
        secondary: "Ċaħħid l-kont tiegħek u tneħħi l-apparat tiegħek.",
        enterPass: "Daħħal il-password tiegħek",
        discard: "Neħħi t-tibdil",
      },
      activeLanguage: "Lingwa Attiva",
      defaultLanguage: "Lingwa tas-Sistema",
      changeDefaultLanguage: "Ibdel il-lingwa awtomatika",
      privacyPolicy: {
        text: "Politika tal-Privatezza",
      },
      settings: "Settings",
      lang: {
        en: "Ingliż",
        it: "Taljan",
        de: "Ġermaniż",
        esar: "Spanjol(l-Arġentina)",
        fr: "Franċiż",
        ja: "Ġappuniż",
        ro: "Rumen",
        da: "Daniż",
        nl: "Olandiż",
        ca: "Katalan",
        lb: "Lussemburgiż",
        no: "Norveġiż",
        ptbr: "Portugiż(il-Brażil)",
        zhcn: "Ċiniż",
        ms: "Malajan",
        ta: "Tamil",
        bs: "Bożnijaku",
        bg: "Bulgaru",
        hr: "Kroat",
        tr: "Tork",
        cs: "Ċek",
        et: "Estonjan",
        fi: "Finlandiż",
        el: "Grieg",
        hu: "Ungeriż",
        ga: "Irlandiż",
        is: "Iżlandiż",
        lv: "Latvjan",
        lt: "Litwen",
        mt: "Malti",
        pl: "Pollakk",
        pt: "Portugiż(il-Portugall)",
        ru: "Russu",
        sr: "Serb",
        sk: "Slovakk",
        sl: "Sloven",
        sv: "Żvediż",
        mi: "Māori",
        ar: "Għarbi",
        af: "Afrikans",
        zhtw: "Ċiniż (Tradizzjonali)",
        es: "Spanjol(Spanja)",
        ko: "Korean",
        enus: "Ingliz (Stati Uniti)",
        deat: "Ġermaniż (Awstrija)",
        dech: "Ġermaniż (Svizzera)",
        deli: "Ġermaniż (Liechtenstein)",
        dede: "Ġermaniż (Ġermanja)",
        enca: "Ingliz (Kanada)",
        engb: "Ingliz",
        enie: "Ingliz (Irlanda)",
        ennz: "Ingliz (New Zealand)",
        enza: "Ingliz (Afrika t'Isfel)",
        esmx: "Spanjol (Messiku)",
        frca: "Franċiż (Kanada)",
        frch: "Franċiż (Svizzera)",
        itch: "Taljan (Svizzera)",
        jajp: "Ġappuniż (Gappun)",
        kokr: "Korean (Korea tan-Nofsinhar)",
        nb: "Norveġjan (Bokmål)",
        nn: "Norveġjan (Nynorsk)",
        zhch: "Ċiniż (RPC)",
      },
      language: {
        primary: "Lingwa",
      },
    },
    show: {
      more: " Uri aktar",
      less: " Uri inqas",
    },
    showPage: {
      seasons: "Staġuni",
      cast: "Cast",
      producers: "Produtturi",
      genre: "Ġeneru",
      directedBy: "Dirett minn",
      season: "Staġun",
    },
    accountDetails: {
      firstName: "Isem",
      lastName: "Kunjom",
      email: "Email",
      password: "Password",
      changePassword: "Ibdel il-Password",
      forgotPassword: "Insejt il-Password",
    },
    childPinPopup: {
      title: "Protezzjoni tat-Tfal",
      subTitle: "Oħloq (aġġorna) l-PIN għall-Protezzjoni tat-Tfal",
      pinUpdated: "Il-PIN ġie aġġornat b’suċċess",
      pinIsIncorrect: "Il-PIN mhux tajjeb",
    },
    deactivateAccount: {
      title: "Int żgur li tixtieq tiddiżattiva l-kont tiegħek?",
      description:
        "Ladarba tagħmel dan, int se tinħareġ mis-servizzi kollha u ma tkunx tista’ tara aktar il-kontenut fil-karozza tiegħek",
      deactivateButton: "Iddiżattiva l-kont",
      discard: "Discard Changes",
    },
    servicesPage: {
      add: "Żid mas-servizzi",
      remove: "Neħħi mis-servizzi",
      overflow: {
        title: "Pakketti Magħżula",
        subtitle: "Agħżel is-Servizzi Tiegħek",
        description: "Żid kull servizz li tuża mal-gwida tiegħek.",
      },
    },
    manageProfile: {
      text: "Immaniġġja l-Profili",
    },
    watchlist: {
      title:
        "Hawn huma l-films u l-ispettakli kollha li żidt mal-Watchlist tiegħek",
      noTitles: "Bħalissa m’għandek l-ebda titlu fil-Watchlist tiegħek",
      addText:
        "Biex iżżid, ikklikkja fuq + Żid mal-Watchlist fuq kwalunkwe programm li tixtieq terġa’ lura għalih biex tarah aktar tard.",
      subtext: {
        start: "Biex iżżid, ikklikkja fuq ",
        toWatchList: "Żid mal-Watchlist",
        end: "għal kwalunkwe programm li tixtieq terġa’ lura għalih biex tarah aktar tard.",
      },
    },
    streamingService: {
      title: "Agħżel is-Servizzi tal-Istreaming",
      subTitle:
        "Żid is-servizzi magħżula tiegħek biex jidhru fil-Gwida tat-TV tiegħek",
      streamingServices: "Servizzi tal-Istreaming",
    },
    profileUpdate: {
      backToSettings: "Lura għas-Settings",
      updatedSuccess: "L-utent ġie aġġornat b’suċċess",
      apply: "Applika",
      wrongPassword: "Password Ħażina",
    },
    changePasswordPage: {
      backToSettings: "Lura għas-Settings",
      currentPass: "Password Attwali",
      newPass: "Password Ġdida",
      confirmPass: "Ikkonferma l-Password",
      change: "Ibdel",
      changeUserInfo: "Ibdel l-Informazzjoni tal-Utent",
      passwordDoNotMatch: "Il-passwords ma jaqblux jew huma invalidi",
      passwordUpdated: "Il-password ġiet aġġornata b’suċċess",
    },
    noServices: {
      title: "Bħalissa m’għandek l-ebda servizz fil-Gwida tat-TV tiegħek",
      text: "Biex tibbenefika bl-aħjar mod minn ScreenHits TV, teħtieġ li żżid is-servizzi. Ikklikkja hawn biex iżżid l-istreamers li diġà int abbonat għalihom mingħajr ebda spiża addizzjonali. U jekk bħalissa m’intix abbonat għal ebda servizzi tal-istreaming, żid il-bundle BLA ĦLAS tagħna hawnhekk u ibda skopri kontenut mill-aqwa mill-BBC, ITV, My5, Channel 4, UKTV u STV.",
      button: "Żid Servizzi",
    },
    deleteProfile: {
      cantDeleteProfile: "Ma tistax tħassar il-profil attwali tiegħek",
      areYouSure: "Żgur li tixtieq tħassar dan il-profil?",
      delete: "Neħħi l-profil",
    },
    notFoundPage: {
      title: "Ġrat xi ħaġa ħażina",
      text: "Tinkwetax madankollu, kollox ok. Ser nerġgħu lura b'din il-paġna.",
      home: "Paġna Ewlenija",
    },
    noInternet: {
      title: "L-ebda Kunnessjoni tal-Internet",
      description:
        "La tħassibx, kollox tajjeb. Ser nirritornaw b'din il-paġna.",
      button: "Rikarika",
    },
  },
};

export default MT_RESOURCE;
