const EL_RESOURCE = {
  translation: {
    loading: "Φόρτωση",
    search: {
      cancel: "Ακύρωση",
      noResults:
        "Δεν βρέθηκαν αποτελέσματα αναζήτησης, αλλά τα παρακάτω αξίζει σίγουρα να τα δείτε.",
    },
    menu: {
      switchProfiles: "Αλλαγή προφίλ",
      settings: "Ρυθμίσεις",
      close: "Κλείσιμο",
      logOut: "Αποσύνδεση",
    },
    button: {
      apply: "Εφαρμογή",
      continue: "Συνέχεια",
      cancel: "Ακύρωση",
      confirm: "Επιβεβαίωση",
      play: "Προβολή",
      addToWatchList: "Προσθήκη στη λίστα παρακολούθησης",
      removeFromWatchList: "Αφαίρεση από τη λίστα παρακολούθησης",
      moreInfo: "Περισσότερες πληροφορίες",
      resume: "Συνέχεια",
      close: "Κλείσιμο",
      discard: "Απόρριψη",
      save: "Αποθήκευση",
    },
    login: {
      header: "Σύνδεση λογαριασμού",
      title: "Καλώς ήρθατε στην",
      subTitle:
        "Εκμεταλλευτείτε στο έπακρο τις συνδρομές streaming με Disney, Amazon και άλλα.",
      text: "Όλα όσα θέλετε να παρακολουθήσετε σε ένα μέρος.",
      button: "Σύνδεση",
      placeholder: {
        username: "Όνομα χρήστη",
        password: "Κωδικός πρόσβασης",
      },
      errors: {
        email: {
          noValid: "Το email πρέπει να είναι έγκυρο",
          required: "Το email είναι υποχρεωτικό πεδίο",
        },
        password: {
          required: "Ο κωδικός πρόσβασης είναι υποχρεωτικό πεδίο",
        },
        wrongPass: {
          first:
            "Λυπούμαστε, ο κωδικός πρόσβασης δεν είναι σωστός. Προσπαθήστε ξανά ή επιλέξτε το πλήκτρο ",
          second: "“Ξέχασα τον κωδικό πρόσβασης” ",
          third: "παρακάτω.",
        },
      },
    },
    forgotPassword: {
      message:
        'Μην ανησυχείτε. Η επαναφορά του κωδικού πρόσβασής σας είναι εύκολη. Απλά πληκτρολογήστε τη διεύθυνση email σας παρακάτω και στη συνέχεια πατήστε "ΑΠΟΣΤΟΛΗ"',
      button: "ΑΠΟΣΤΟΛΗ",
      placeholder: {
        username: "Όνομα χρήστη",
      },
      errors: {
        email: {
          required: "Το email είναι υποχρεωτικό πεδίο",
        },
        emailNotRegister: "Το email δεν έχει καταχωρηθεί!",
        emailSent: "Εστάλη mail επαναφοράς κωδικού πρόσβασης!",
      },
    },
    profilesPage: {
      title: "Επιλογή Προφίλ",
      subTitle:
        "Jeder in Ihrem Auto kann ein personalisiertes TV-Erlebnis haben.",
      newProfile: "+ Neues Profil hinzufügen",
    },
    newProfile: {
      title: "Νέο προφίλ",
      description: "Προσθέστε ένα προφίλ για άλλο άτομο που παρακολουθεί την τηλεόραση ScreenHits",
      isKid: "Kind?",
      loading: "Φόρτωση",
      profileCreated: "Το προφίλ δημιουργήθηκε επιτυχώς!",
      profileSelection: "Επιλογή προφίλ",
    },
    settings: {
      accountDetails: {
        primary: "Στοιχεία λογαριασμού",
        secondary: "Επεξεργασία στοιχείων",
      },
      manageProfiles: {
        primary: "Διαχείριση προφίλ",
        secondary: "Επεξεργασία λεπτομερειών προφίλ",
        edit: "Επεξεργασία προφίλ",
      },
      parentControl: {
        primary: "Γονικός έλεγχος",
        secondary: "Δημιουργία PIN / Αλλαγή PIN",
        instruction: {
          primary: "Δημιουργία (ενημέρωση) PIN για την προστασία των παιδιών",
          secondary:
            "(Παρακαλώ εισάγετε 4 ψηφία ενώ το πληκτρολόγιο είναι ανοιχτό)",
        },
        recoverPin: "Ανάκτηση PIN",
        pinSentTo: "Το PIN εστάλη στο",
        unlock: "Ξεκλείδωμα",
        pinUndefined: "PIN αποστάλθηκε στη διεύθυνση email"
      },
      membershipStatus: {
        primary: "Κατάσταση μέλους",
        secondary: "Ενεργό",
      },
      deactivateAccount: {
        primary: "Απενεργοποίηση λογαριασμού",
        secondary:
          "Κλείστε τον λογαριασμό σας και αποσυνδέστε τις συσκευές σας.",
        enterPass: "Εισαγάγετε τον κωδικό πρόσβασής σας",
        discard: "Απόρριψη αλλαγών",
      },
      activeLanguage: "Ενεργή γλώσσα",
      defaultLanguage: "Γλώσσα συστήματος",
      changeDefaultLanguage: "Αλλαγή προεπιλεγμένης γλώσσας",
      privacyPolicy: {
        text: "Πολιτική απορρήτου",
      },
      settings: "Ρυθμίσεις",
      lang: {
        en: "Αγγλικά",
        it: "Ιταλικά",
        de: "Γερμανικά",
        esar: "Ισπανικά(Αργεντινής)",
        fr: "Γαλλικά",
        ja: "Ιαπωνικά",
        ro: "Ρουμανικά",
        da: "Δανέζικα",
        nl: "Ολλανδικά",
        ca: "Καταλανικά",
        lb: "Λουξεμβουργιανά",
        no: "Νορβηγικά",
        ptbr: "Πορτογαλικά(Βραζιλίας)",
        zhcn: "Κινεζικά",
        ms: "Μαλαισιανά",
        ta: "Ταμίλ",
        bs: "Βοσνιακά",
        bg: "Βουλγαρικά",
        hr: "Κροάτικα",
        tr: "Τουρκικά",
        cs: "Τσεχικα",
        et: "Εσθονικά",
        fi: "Φινλανδικά",
        el: "Ελληνικά",
        hu: "Ουγγρικά",
        ga: "Ιρλανδικά",
        is: "Ισλανδικά",
        lv: "Λετονικά",
        lt: "Λιθουανικά",
        mt: "Μαλτέζικα",
        pl: "Πολωνικά",
        pt: "Πορτογαλικά(Πορτογαλίας)",
        ru: "Ρωσικά",
        sr: "Σερβικάή",
        sk: "Σλοβάκικα",
        sl: "Σλοβένικα",
        sv: "Σουηδικά",
        mi: "Μαορί",
        ar: "Αραβικά",
        af: "Αφρικάανς",
        zhtw: "Κινεζικά(Παραδοσιακά)",
        es: "Ισπανικά (Ισπανίας)",
        ko: "Κορεάτικα",
        enus: "Αγγλικά (Ηνωμένες Πολιτείες)",
        deat: "Γερμανικά (Αυστρία)",
        dech: "Γερμανικά (Ελβετία)",
        deli: "Γερμανικά (Λιχτενστάιν)",
        dede: "Γερμανικά (Γερμανία)",
        enca: "Αγγλικά (Καναδάς)",
        engb: "Αγγλικά",
        enie: "Αγγλικά (Ιρλανδία)",
        ennz: "Αγγλικά (Νέα Ζηλανδία)",
        enza: "Αγγλικά (Νότια Αφρική)",
        esmx: "Ισπανικά (Μεξικό)",
        frca: "Γαλλικά (Καναδάς)",
        frch: "Γαλλικά (Ελβετία)",
        itch: "Ιταλικά (Ελβετία)",
        jajp: "Ιαπωνικά (Ιαπωνία)",
        kokr: "Κορεατικά (Νότια Κορέα)",
        nb: "Νορβηγικά (Bokmål)",
        nn: "Νορβηγικά (Nynorsk)",
        zhch: "Κινεζικά (Λαϊκή Δημοκρατία της Κίνας)",
      },
      language: {
        primary: "Γλώσσα",
      },
    },
    show: {
      more: " Εμφάνιση περισσότερων",
      less: " Γλώσσα συστήματος",
    },
    showPage: {
      seasons: "Εποχές",
      cast: "λίστα καστ",
      producers: "Παραγωγοί",
      genre: "Είδος",
      directedBy: "Σκηνοθεσία",
      season: "Σεζόν",
    },
    accountDetails: {
      firstName: "Όνομα",
      lastName: "Επώνυμο",
      email: "email",
      password: "Κωδικός πρόσβασης",
      changePassword: "Αλλαγή κωδικού πρόσβασης",
      forgotPassword: "Ξέχασα τον κωδικό πρόσβασης",
    },
    childPinPopup: {
      title: "Προστασία των παιδιών",
      subTitle: "Δημιουργία (ενημέρωση) PIN για την προστασία των παιδιών",
      pinUpdated: "Το PIN ενημερώθηκε επιτυχώς",
      pinIsIncorrect: "Το PIN είναι λανθασμένο",
    },
    deactivateAccount: {
      title:
        "Είστε σίγουροι ότι θέλετε να απενεργοποιήσετε τον λογαριασμό σας;",
      description:
        "Μόλις το κάνετε, θα αποσυνδεθείτε από όλες τις υπηρεσίες και δεν θα μπορείτε πλέον να βλέπετε περιεχόμενο στο αυτοκίνητό σας.",
      deactivateButton: "Απενεργοποίηση λογαριασμού",
    },
    servicesPage: {
      add: "Προσθήκη στις υπηρεσίες",
      remove: "Αφαίρεση από τις υπηρεσίες",
      overflow: {
        title: "Επιλεγμένα πακέτα",
        subtitle: "Επιλέξτε τις Υπηρεσίες σας",
        description:
          "Προσθέστε κάθε υπηρεσία που χρησιμοποιείτε στον οδηγό σας.",
      },
    },
    manageProfile: {
      text: "Διαχείριση προφίλ",
    },
    watchlist: {
      title:
        "Εδώ είναι όλες οι ταινίες και οι σειρές που έχετε προσθέσει στη λίστα παρακολούθησής σας",
      noTitles: "Προς το παρόν δεν έχετε τίτλους στη Λίστα Παρακολούθησής σας",
      addText:
        "Για να κάνετε προσθήκη, επιλέξτε + Προσθήκη στη λίστα παρακολούθησης σε οποιαδήποτε εκπομπή επιθυμείτε να παρακολουθήσετε αργότερα.",
      subtext: {
        start: "Για προσθήκη, επιλέξτε ",
        toWatchList: "Προσθήκη στη λίστα παρακολούθησης",
        end: "ή οποιαδήποτε εκπομπή την οποία θα θέλατε να παρακολουθήσετε αργότερα.",
      },
    },
    streamingService: {
      title: "Επιλέξτε υπηρεσίες ροής",
      subTitle:
        "Προσθέστε τις επιλεγμένες υπηρεσίες σας για να εμφανίζονται στον τηλεοπτικό σας οδηγό",
      streamingServices: "Υπηρεσίες streaming",
    },
    profileUpdate: {
      backToSettings: "Πίσω στις Ρυθμίσεις",
      updatedSuccess: "Ο χρήστης ενημερώθηκε με επιτυχία",
      apply: "Εφαρμογή",
      wrongPassword: "Λανθασμένος κωδικός πρόσβασης",
    },
    changePasswordPage: {
      backToSettings: "Πίσω στις Ρυθμίσεις",
      currentPass: "Τρέχων κωδικός πρόσβασης",
      newPass: "Νέος κωδικός πρόσβασης",
      confirmPass: "Επιβεβαίωση κωδικού πρόσβασης",
      change: "Αλλαγή",
      changeUserInfo: "Αλλαγή στοιχείων χρήστη",
      passwordDoNotMatch: "Οι κωδικοί πρόσβασης δεν ταιριάζουν ή είναι άκυροι",
      passwordUpdated: "Ο κωδικός πρόσβασης ενημερώθηκε επιτυχώς",
    },
    noServices: {
      title: "Αυτήν τη στιγμή δεν έχετε υπηρεσίες στον τηλεοπτικό οδηγό σας",
      text: "Για να αξιοποιήσετε στο έπακρο το ScreenHits TV, πρέπει να προσθέσετε υπηρεσίες. Κάντε κλικ εδώ για να προσθέσετε τους streamers στους οποίους έχετε ήδη εγγραφεί χωρίς επιπλέον κόστος. Εάν δεν διαθέτετε ακόμα συνδρομή σε καμία υπηρεσία ροής, προσθέστε το ΔΩΡΕΑΝ πακέτο μας και ξεκινήστε να ανακαλύπτετε εξαιρετικό περιεχόμενο.",
      button: "Προσθήκη Υπηρεσιών",
    },
    deleteProfile: {
      cantDeleteProfile: "Δεν μπορείτε να διαγράψετε το τρέχον προφίλ σας",
      areYouSure: "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το προφίλ;",
      delete: "Διαγραφή προφίλ",
    },
    notFoundPage: {
      title: "Κάτι πήγε στραβά",
      text: "Μην ανησυχείς όμως, όλα είναι εντάξει. Θα επανέλθουμε με αυτή τη σελίδα.",
      home: "Αρχική σελίδα",
    },
    noInternet: {
      title: "Χωρίς σύνδεση στο Διαδίκτυο",
      description:
        "Μην ανησυχείτε, όλα είναι εντάξει. Θα επιστρέψουμε με αυτήν τη σελίδα.",
      button: "Επαναφόρτωση",
    },
  },
};

export default EL_RESOURCE;
