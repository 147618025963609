const HU_RESOURCE = {
  translation: {
    loading: "Betöltés",
    search: {
      cancel: "Törlés",
      noResults: "Nincs keresési találat, de az alábbiakba érdemes belenézni.",
    },
    menu: {
      switchProfiles: "Profil váltás",
      settings: "Beállítások",
      close: "Bezárás",
      logOut: "Kijelentkezés",
    },
    button: {
      apply: "Alkalmazás",
      continue: "Folytatás",
      cancel: "Törlés",
      confirm: "Megerősítés",
      play: "Lejátszás",
      addToWatchList: "Hozzáadás a Nézőlistához",
      removeFromWatchList: "Eltávolítás a Nézőlistáról",
      moreInfo: "További információ",
      resume: "Folytatás",
      close: "Bezárás",
      discard: "Elvet",
      save: "Ment",
    },
    login: {
      header: "Fiók bejelentkezés",
      title: "Üdvözöljük a",
      subTitle:
        "Hozza ki a lehető legtöbbet a Netflix, a Disney, az Amazon és más szolgáltatóknál meglévő streaming-előfizetéseiből.",
      text: "Kedvenc műsorait egy helyen nézheti.",
      button: "Bejelentkezés",
      placeholder: {
        username: "Felhasználónév",
        password: "Jelszó",
      },
      errors: {
        email: {
          noValid: "Az e-mail címnek érvényes e-mail címnek kell lennie",
          required: "Az e-mail mező kitöltése kötelező",
        },
        password: {
          required: "A jelszó mező kitöltése kötelező",
        },
        wrongPass: {
          first: "Hibás jelszó. Kérjük, próbálja meg újra, vagy kattintson az ",
          second: "“Elfelejtett jelszó” ",
          third: "gombra alul.",
        },
      },
    },
    forgotPassword: {
      message:
        "Ne aggódjon! A jelszó visszaállítása egyszerű. Egyszerűen írja be az alábbiakban az e-mail címét, és kattintson az „ELKÜLDÉS” gombra.",
      button: "ELKÜLDÉS",
      placeholder: {
        username: "Felhasználónév",
      },
      errors: {
        email: {
          required: "Az e-mail mező kitöltése kötelező",
        },
        emailNotRegister: "Ez az e-mail cím nincs regisztrálva!",
        emailSent: "A jelszó visszaállító e-mail elküldve!",
      },
    },
    profilesPage: {
      title: "Profil kiválasztása",
      subTitle: "Mindenki az autódban személyre szabott TV élményt élvezhet.",
      newProfile: "+ Új profil hozzáadása",
    },
    newProfile: {
      title: "Új profil",
      description: "Profil hozzáadása egy másik személy számára, aki nézi a ScreenHits TV-t",
      isKid: "Gyerek?",
      loading: "Betöltés",
      profileCreated: "Profil sikeresen létrehozva!",
      profileSelection: "Profil kiválasztása",
    },
    settings: {
      accountDetails: {
        primary: "Fiókadatok",
        secondary: "A fióktulajdonos nevének és e-mail címének szerkesztése.",
      },
      manageProfiles: {
        primary: "Profilok kezelése",
        secondary: "Profil részleteinek szerkesztése",
        edit: "Profil szerkesztése",
      },
      parentControl: {
        primary: "Szülői felügyelet",
        secondary: "PIN létrehozása / PIN módosítása",
        instruction: {
          primary: "Szülői felügyelet PIN készítése (frissítése)",
          secondary:
            "(Kérjük, adjon meg 4 számjegyet, amíg a billentyűzet nyitva van)",
        },
        recoverPin: "PIN visszaállítása",
        pinSentTo: "PIN elküldve ide:",
        unlock: "Otključaj",
        pinUndefined: "PIN poslan na e-mail adresu"
      },
      membershipStatus: {
        primary: "Tagsági státusz",
        secondary: "Aktív",
      },
      deactivateAccount: {
        primary: "Fiók inaktiválása",
        secondary: "Zárja be fiókját és válassza le eszközeit.",
        enterPass: "Adja meg jelszavát",
        discard: "Változtatások elvetése",
      },
      activeLanguage: "Aktív nyelv",
      defaultLanguage: "Rendszer nyelv",
      changeDefaultLanguage: "Alapértelmezett nyelv módosítása",
      privacyPolicy: {
        text: "Adatvédelmi szabályok",
      },
      settings: "Beállítások",
      lang: {
        en: "Angol",
        it: "Olasz",
        de: "Német",
        esar: "Spanyol(Argentína)",
        fr: "Francia",
        ja: "Japán",
        ro: "Román",
        da: "Dán",
        nl: "Holland",
        ca: "Katalán",
        lb: "Luxemburgi",
        no: "Norvég",
        ptbr: "Portugál(Brazil)",
        zhcn: "Kínai",
        ms: "Maláj",
        ta: "Tamil",
        bs: "Boszniai",
        bg: "Bolgár",
        hr: "Horvát",
        tr: "Török",
        cs: "Cseh",
        et: "Észt",
        fi: "Finn",
        el: "Görög",
        hu: "Magyar",
        ga: "Ír",
        is: "Izlandi",
        lv: "Lett",
        lt: "Litvánia",
        mt: "Máltai",
        pl: "Lengyel",
        pt: "Portugál(Portugália)",
        ru: "Orosz",
        sr: "Szerb",
        sk: "Szlovák",
        sl: "Szlovén",
        sv: "Svéd",
        mi: "Maori",
        ar: "Arab",
        af: "Afrikaans",
        zhtw: "Kínai(Hagyományos)",
        es: "Spanyol(Spanyolország)",
        ko: "Koreai",
        enus: "English (United States)",
        deat: "German (Austria)",
        dech: "German (Switzerland)",
        deli: "German (Liechtenstein)",
        dede: "German (Deutschland)",
        enca: "English (Canada)",
        engb: "English",
        enie: "English (Ireland)",
        ennz: "English (New Zealand)",
        enza: "English (South Africa)",
        esmx: "Spanish (Mexico)",
        frca: "French (Canada)",
        frch: "French (Switzerland)",
        itch: "Italian (Switzerland)",
        jajp: "Japanese (Japan)",
        kokr: "Korean (South Korea)",
        nb: "Norwegian (Bokmål)",
        nn: "Norwegian (Nynorsk)",
        zhch: "Chinese (PRC)",
      },
      language: {
        primary: "Nyelv",
      },
    },
    show: {
      more: " Több mutatása",
      less: " Kevesebb mutatása",
    },
    showPage: {
      seasons: "Évadok",
      cast: "Szereplők",
      producers: "Producerek",
      genre: "Műfaj",
      directedBy: "Rendező",
      season: "Évad",
    },
    accountDetails: {
      firstName: "Keresztnév",
      lastName: "Vezetéknév",
      email: "E-mail",
      password: "Jelszó",
      changePassword: "Jelszó módosítása",
      forgotPassword: "Elfelejtett jelszó",
    },
    childPinPopup: {
      title: "Gyermekvédelem",
      subTitle: "PIN-kód létrehozása (frissítése) a Gyermekvédelemhez",
      pinUpdated: "PIN-kód sikeresen frissítve",
      pinIsIncorrect: "Helytelen PIN-kód",
    },
    deactivateAccount: {
      title: "Biztosan deaktiválni kívánja a fiókját?",
      description:
        "Ebben az esetben minden szolgáltatásból ki fog jelentkezni, és nem fog tudni megnézni semmit az autójában.",
      deactivateButton: "Fiók deaktiválása",
      discard: "Discard Changes",
    },
    servicesPage: {
      add: "Hozzáadás a szolgáltatásokhoz",
      remove: "Eltávolítás a szolgáltatásokból",
      overflow: {
        title: "Kiválasztott csomagok",
        subtitle: "Válassza ki szolgáltatásait",
        description:
          "Adjon hozzá minden egyes Ön által használt szolgáltatást az útmutatójához.",
      },
    },
    manageProfile: {
      text: "Profilok kezelése",
    },
    watchlist: {
      title:
        "Itt van az összes film és műsor, amelyet a Nézőlistájához már hozzáadott",
      noTitles: "Nézőlistája jelenleg üres",
      addText:
        "A hozzáadáshoz kattintson a + Hozzáadás a Nézőlistához gombra azokon a műsorokon, amelyeket később meg szeretne nézni.",
      subtext: {
        start: "A hozzáadáshoz kattintson ide ",
        toWatchList: "Hozzáadás a Nézőlistához",
        end: "bármely olyan műsorhoz, amelyet később meg szeretne nézni.",
      },
    },
    streamingService: {
      title: "Válasszon streaming szolgáltatásokat",
      subTitle:
        "Adja hozzá a kiválasztott szolgáltatásait, hogy azok megjelenjenek a TV Guide-ban",
      streamingServices: "Streaming szolgáltatások",
    },
    profileUpdate: {
      backToSettings: "Vissza a Beállításokhoz",
      updatedSuccess: "A felhasználó frissítése sikeres volt",
      apply: "Alkalmazás",
      wrongPassword: "Hibás jelszó",
    },
    changePasswordPage: {
      backToSettings: "Vissza a Beállításokhoz",
      currentPass: "Jelenlegi jelszó",
      newPass: "Új jelszó",
      confirmPass: "Jelszó megerősítése",
      change: "Módosítás",
      changeUserInfo: "Felhasználói adatok módosítása",
      passwordDoNotMatch: "A jelszavak nem egyeznek vagy érvénytelenek",
      passwordUpdated: "Jelszó sikeresen frissítve",
    },
    noServices: {
      title: "Jelenleg nincs szolgáltatása a TV Guide-ban",
      text: "A ScreenHits TV-ből akkor tudja kihozni a legtöbbet, ha hozzáad  szolgáltatásokat. Kérjük, kattintson ide a már feliratkozott streamek ingyenes hozzáadásához. Ha pedig jelenleg nem fizet elő semmilyen streaming szolgáltatásra, adja hozzá INGYENES csomagunkat, és élvezze a BBC, ITV, My5, Channel 4, UKTV és STV fantasztikus műsorait.",
      button: "Szolgáltatások hozzáadása",
    },
    deleteProfile: {
      cantDeleteProfile: "Jelenlegi profilját nem törölheti",
      areYouSure: "Biztos, hogy törölni kívánja ezt a profilt?",
      delete: "Profil törlése",
    },
    notFoundPage: {
      title: "Valami nem sikerült",
      text: "De ne aggódj, minden rendben van. Vissza fogunk térni ezzel az oldallal.",
      home: "Kezdőlap",
    },
    noInternet: {
      title: "Nincs internetkapcsolat",
      description:
        "Ne aggódj, minden rendben van. Visszatérünk ezzel az oldallal.",
      button: "Újratöltés",
    },
  },
};

export default HU_RESOURCE;
