const MI_RESOURCE = {
  translation: {
    loading: "Uta ana",
    search: {
      cancel: "Whakakore",
      noResults:
        "Kāore i kitea he hua rapu, engari ko ngā mea o raro nei e tino pai ana te taki atu.",
    },
    menu: {
      switchProfiles: "Whakawhiti Kōtaha",
      settings: "Ngā Tautuhinga",
      close: "Katia",
      logOut: "Takiputa atu",
    },
    button: {
      apply: "Tonoa",
      continue: "Haere tonu",
      cancel: "Whakakore",
      confirm: "Whakaū",
      play: "Purei",
      addToWatchList: "Tāpirihia ki te Rārangi Mātakitaki",
      removeFromWatchList: "Tango mai i te Rarangi Maataki",
      moreInfo: "Ētahi atu korero",
      resume: "Anō",
      close: "Katia",
      discard: "Whakakore",
      save: "Tiaki",
    },
    login: {
      header: "Takiuru Pūkete",
      title: "Nau mai haere mai",
      subTitle:
        "Whakanuia o ohaurunga whakawhiti me Netflix, Disney, Amazon me etahi atu.",
      text: "Ko nga mea katoa e hiahia ana koe ki te maataki i te waahi kotahi.",
      button: "Takiuru",
      placeholder: {
        username: "Ingoa Kaiwhakamahi",
        password: "Kupuhipa",
      },
      errors: {
        email: {
          noValid: "Ko te imeera he imeera whaimana",
          required: "Ko te imeera he mara e hiahiatia ana",
        },
        password: {
          required: "Ko te kupuhipa he mara e hiahiatia ana",
        },
        wrongPass: {
          first:
            "Aue, ehara tena i te kupuhipa tika. Me ngana ano, pawhiria ranei ",
          second: "“Wareware Kupuhipa” ",
          third: "pātene i raro.",
        },
      },
    },
    forgotPassword: {
      message:
        'Kaua e manukanuka. He ngawari te tautuhi i to kupuhipa. Whakaurua noa to wahitau imeera ki raro ka paato "Tukua"',
      button: "Tukua",
      placeholder: {
        username: "Ingoa Kaiwhakamahi",
      },
      errors: {
        email: {
          required: "Ko te imeera he mara e hiahiatia ana",
        },
        emailNotRegister: "Ko te Wāhitau imeera kaore i rehitatia!",
        emailSent: "Tautuhi ano i te imeera kupuhipa kua tukuna!",
      },
    },
    profilesPage: {
      title: "Ko wai e tiro ana?",
      subTitle:
        "Ka taea e ngā tāngata katoa i roto i tō rori te whai wheako Pouaka Whakaata ā-irara.",
      newProfile: "+ Tāpiri atu he Pereti Hou",
    },
    newProfile: {
      title: "Profail Hōu",
      description: "Tāpiri atu he mōtika mō tētahi atu tangata e titiro ana ki te ScreenHits TV",
      isKid: "Tamariki?",
      loading: "Uta ana",
      profileCreated: "I tutuki pai te waihanga i te kōtaha!",
      profileSelection: "Tīpakonga Kōtaha",
    },
    settings: {
      accountDetails: {
        primary: "Nga korero mo te kaute",
        secondary: "Whakatikaina te ingoa o te kaipupuri putea me te imeera.",
      },
      manageProfiles: {
        primary: "Whakahaere Ngā Profilia",
        secondary: "Whakatika Ngā Taipitopito o te Profilia",
        edit: "Whakatika Profilia",
      },
      parentControl: {
        primary: "Whakahaere Matua",
        secondary: "Waihanga PIN / Panoni PIN",
        instruction: {
          primary: "Oħloq (aġġorna) il-PIN għal Kontroll Patern",
          secondary:
            "(Jekk jogħġbok idħol 4 ċifri waqt li l-keyboard huwa miftuħ)",
        },
        recoverPin: "Whakahokia te PIN",
        pinSentTo: "Kua tukuna te PIN ki",
        unlock: "Tārua",
        pinUndefined: "PIN i tukuna ki te wāhitau īmēra"
      },
      membershipStatus: {
        primary: "Tūnga Mema",
        secondary: "Hohe",
      },
      deactivateAccount: {
        primary: "Whakakore Kōnae",
        secondary: "Katia tou kaute me te whakakorea i ō rātou raihana.",
        enterPass: "Tāuruia ōu kupuhipa",
        discard: "Waiho ngā rerekēnga",
      },
      activeLanguage: "Reo kaha",
      defaultLanguage: "Te Reo Pūnaha",
      changeDefaultLanguage: "Hurihia te reo taunoa",
      privacyPolicy: {
        text: "Kaupapahere Tūmataiti",
      },
      settings: "Tautuhinga",
      lang: {
        en: "Ingarihi",
        it: "Itari",
        de: "Tiamana",
        esar: "Paniora(Argentina)",
        fr: "Wīwī",
        ja: "Hapanihi",
        ro: "Romanian",
        da: "Teniana",
        nl: "Tatimana",
        ca: "Katarana",
        lb: "Luxembourgish",
        no: "Norewai",
        ptbr: "Potiti(Brazil)",
        zhcn: "Hainamana",
        ms: "Malay",
        ta: "Tamil",
        bs: "Bosnia",
        bg: "Bulgarian",
        hr: "Koroatiana",
        tr: "Turiki",
        cs: "Czech",
        et: "Estonian",
        fi: "Finiana",
        el: "Kariki",
        hu: "Hungarian",
        ga: "Airihi",
        is: "Tiorangi",
        lv: "Latvian",
        lt: "Lithuanian",
        mt: "Marite",
        pl: "Porohia",
        pt: "Potiti(Portugal)",
        ru: "Ruhia",
        sr: "Serbian",
        sk: "Slovak",
        sl: "Slovenian",
        sv: "Huitene",
        mi: "Māori",
        ar: "Arapi",
        af: "Awherika",
        zhtw: "Hainamana(Tawhito)",
        es: "Paniora(Spain)",
        ko: "Korean",
        enus: "Ingarihi (Ihoreiana)",
        deat: "Tiamana (Ōterēria)",
        dech: "Tiamana (Huihuinga)",
        deli: "Tiamana (Riechtenstein)",
        dede: "Tiamana (Tiamana)",
        enca: "Ingarihi (Kānata)",
        engb: "Ingarihi",
        enie: "Ingarihi (Airangi)",
        ennz: "Ingarihi (Aotearoa)",
        enza: "Ingarihi (Afrika ki te Tonga)",
        esmx: "Hīspani (Mehiko)",
        frca: "Wīwī (Kānata)",
        frch: "Wīwī (Huihuinga)",
        itch: "Itārihi (Huihuinga)",
        jajp: "Hapanihi (Hapani)",
        kokr: "Kōreana (Kōrea ki te Tonga)",
        nb: "Nowe (Bokmål)",
        nn: "Nowe (Nynorsk)",
        zhch: "Hainamana (RPC)",
      },
      language: {
        primary: "Reo",
      },
    },
    show: {
      more: " Whakaaturia mai ano",
      less: " Whakaatuhia kia iti ake",
    },
    showPage: {
      seasons: "Wa",
      cast: "Maka",
      producers: "Kaihanga",
      genre: "Momo",
      directedBy: "Na",
      season: "Wa",
    },
    accountDetails: {
      firstName: "Ingoa Tuatahi",
      lastName: "Ingoa Whakamutunga",
      email: "Īmēra",
      password: "Kupuhipa",
      changePassword: "Huri Kupuhipa",
      forgotPassword: "Wareware Kupuhipa",
    },
    childPinPopup: {
      title: "Tiaki Tamariki",
      subTitle: "Waihanga (whakahou) PIN mo te Tiaki Tamariki",
      pinUpdated: "I tutuki pai te whakahōutanga o te PIN",
      pinIsIncorrect: "Kei te hē te PIN",
    },
    deactivateAccount: {
      title: "Kei te tino hiahia koe ki te whakakore i to putea?",
      description:
        "Ina mahi koe, ka puta koe ki waho o nga ratonga katoa ka kore e taea e koe te tiro i nga ihirangi kei roto i to motuka",
      deactivateButton: "Whakawetohia te kaute",
      discard: "Discard Changes",
    },
    servicesPage: {
      add: "Tāpirihia ki ngā ratonga",
      remove: "Tango mai i nga ratonga",
      overflow: {
        title: "Paanui kua tohua",
        subtitle: "Tīpakohia ō Ratonga",
        description:
          "Tāpirihia ia ratonga e whakamahia ana e koe ki to kaiarahi.",
      },
    },
    manageProfile: {
      text: "Whakahaere Profile",
    },
    watchlist: {
      title:
        "Anei nga kiriata me nga whakaaturanga katoa i taapirihia e koe ki to Raarangi Maataki",
      noTitles: "I tenei wa karekau he taitara kei roto i to Raarangi Maataki",
      addText:
        "Hei taapiri atu, paatohia te + Tāpiri ki te Rarangi Maataki i runga i tetahi whakaaturanga e hiahia ana koe ki te hoki mai ki te matakitaki a muri ake nei.",
      subtext: {
        start: "Hei taapiri, pawhiria koa ",
        toWatchList: "Tāpirihia ki te rārangi mātakitaki",
        end: "tetahi whakaaturanga ranei e hiahia ana koe ki te hoki mai ki te matakitaki a muri ake nei.",
      },
    },
    streamingService: {
      title: "Kōwhiria Ratonga Rere",
      subTitle: "Tāpirihia o ratonga kua tohua kia puta ki to Aratohu TV",
      streamingServices: "Ratonga Whakamarama",
    },
    profileUpdate: {
      backToSettings: "Hoki ki Tautuhinga",
      updatedSuccess: "I momoho te whakahou i te kaiwhakamahi",
      apply: "Tonoa",
      wrongPassword: "Kupuhipa he",
    },
    changePasswordPage: {
      backToSettings: "Hoki ki Tautuhinga",
      currentPass: "Kupuhipa o nāianei",
      newPass: "Kupuhipa Hou",
      confirmPass: "Whakaū Kupuhipa",
      change: "Hurihia",
      changeUserInfo: "Hurihia nga korero kaiwhakamahi",
      passwordDoNotMatch: "Karekau nga kupuhipa i te taurite, i te muhu ranei",
      passwordUpdated: "Kua whakahōutia te kupuhipa",
    },
    noServices: {
      title: "I tenei wa karekau he ratonga i roto i to Aratohu TV",
      text: "Hei whiwhi i te nuinga o te Hōtaka Pouaka Whakaata Mata, me tāpiri ratonga koe. Pāwhiri koa ki konei hei tāpiri i ngā roma e ohauru kē ana koe ki te kore utu tāpiri. Ā, ki te kore koe e ohauru i tēnei wā ki tētahi ratonga roma, tāpirihia tā mātou pūkohu FREE ki konei, ā, ka tīmata ki te kite i ngā ihirangi nunui mai i TE AO, ITV, My5, Channel 4, UKTV me STV.",
      button: "Tāpiri Ratonga",
    },
    deleteProfile: {
      cantDeleteProfile: "Kaore e taea e koe te muku i to kōtaha o nāianei",
      areYouSure: "Kei te tino hiahia koe ki te muku i tenei kōtaha?",
      delete: "Tango Tuhinga",
    },
    notFoundPage: {
      title: "Kua pā tētahi raru.",
      text: "Kaua e manukanuka, he pai nga mea katoa. Ka hoki mai ano matou me tenei wharangi.",
      home: "Kāinga",
    },
    noInternet: {
      title: "Kore Hononga ki te Internet",
      description:
        "Kaua e whakamāhaki, kei te whaiwhai katoa. Ka hoki mai tātou ki tēnei whārangi.",
      button: "Tāmata Anō",
    },
  },
};

export default MI_RESOURCE;
